<template>
  <div id="RuleSystem" class="data-manager-wrap container">
    <div class="RuleTop">
      <div class="zdy">
        <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
        基本信息
      </div>
      <div class="RuleInput">
        <div class="RuleInputLeft">
          <div class="inpL">
            <div class="label">
              <span v-if="AC">异常点名称<i style="color: red"> *</i></span>
              <span v-else>风险点名称<i style="color: red"> *</i></span>
            </div>
            <div class="inp">
              <el-input
                size="medium"
                v-model="name"
                maxlength="20"
                show-word-limit
                type="text"
                placeholder="请输入内容"
              ></el-input>
            </div>
          </div>
          <div class="inpL mt-3">
            <div class="label" style="padding-right: 10px">行业范围</div>
            <div class="inp ca">
              <el-popover placement="bottom-start" trigger="click">
                <el-button
                  type="text"
                  @click="clear"
                  style="color: red"
                  size="mini"
                >
                  清空选择
                </el-button>
                <el-cascader-panel
                  v-model="optionsGbList"
                  :props="props"
                  :options="optionsGb"
                ></el-cascader-panel>
                <a class="cabtn" slot="reference">{{ gbText }}</a>
              </el-popover>
            </div>
          </div>
          <div class="inpL mt-3">
            <div class="label" style="padding-right: 10px">风险等级</div>
            <div class="inp">
              <el-select
                size="medium"
                v-model="risk"
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option label="提示信息" value="0"></el-option>
                <el-option label="中低风险" value="1"></el-option>
                <el-option label="高危风险" value="2"></el-option>
              </el-select>
            </div>
          </div>
          <div class="inpL mt-3">
            <div class="label" style="padding-right: 10px">适用范围</div>
            <div class="inp mt-1">
              <el-radio v-model="radio" label="1"
                >全部公司（上市和非上市）</el-radio
              >
              <el-radio v-model="radio" label="2">非上市公司</el-radio>
            </div>
          </div>
        </div>
        <div class="RuleInputRight">
          <div class="inpL">
            <div class="label">
              <span v-if="AC">异常点描述<i style="color: red"> *</i></span>
              <span v-else>风险点描述<i style="color: red"> *</i></span>
            </div>
            <div class="inp">
              <el-input
                v-model="description"
                maxlength="200"
                :autosize="{ minRows: 3 }"
                show-word-limit
                type="textarea"
                placeholder="请输入内容"
              ></el-input>
            </div>
          </div>
          <div class="inpL mt-3" v-show="AC">
            <div class="label" style="padding-right: 10px">异常点说明</div>
            <div class="inp">
              <el-input
                v-model="detail"
                maxlength="200"
                show-word-limit
                :autosize="{ minRows: 3 }"
                type="textarea"
                placeholder="请输入内容"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="RuleCont">
      <div class="zdy">
        <img src="@/assets/img/head/icon.png" style="margin-bottom: 5px" />
        公式编辑
      </div>
      <div class="inpL mt-2">
        <div class="label ml-2">类型</div>
        <div class="inp">
          <el-cascader
            :options="options"
            @change="companyChange"
            v-model="org_list"
          >
          </el-cascader>
        </div>
      </div>
      <div class="RuleContent">
        <div class="RuleContentLeft">
          <div class="RuleContentBox" v-if="org_list[0] == 'kemu'">
            <a
              class="a_btn_e"
              :title="item.row_name"
              v-for="item in kemu"
              :key="item.name"
              @click="btnClick(item)"
              :style="
                item.row_name == radioBtn
                  ? { color: '#fff', background: '#3a85fd' }
                  : ''
              "
              >{{ item.row_name }}</a
            >
          </div>
          <div class="RuleContentBox" v-else>
            <a
              class="a_btn_e"
              :title="item.row_name"
              v-for="item in zhibiao"
              :key="item.name"
              @click="btnClick(item)"
              :style="
                item.row_name == radioBtn
                  ? { color: '#fff', background: '#3a85fd' }
                  : ''
              "
              >{{ item.row_name }}</a
            >
          </div>
          <div class="el_in_btn">
            <div class="le">时期选择</div>
            <div>
              <el-select
                size="medium"
                v-model="company_time"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in company_timeList"
                  :key="item.label"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </div>
            <div class="le ml-4" v-show="show">金额选择</div>
            <div v-show="show" class="mr-1">
              <el-select
                size="medium"
                v-model="company_Total"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in company_TotalList"
                  :key="item.label"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </div>
            <el-button
              type="primary"
              style="width: 90px; height: 34px; line-height: 0px"
              size="medium"
              class="ml-4"
              @click="riskappend"
              >添加</el-button
            >
          </div>
        </div>
        <div class="RuleContentRight">
          <div style="width: 55%">
            <div class="row justify-content-between">
              <div style="font-size: 16px; color: #333">公式</div>
              <a @click="empty" style="color: #3a85fd"
                ><i
                  style="transform: rotate(180deg)"
                  class="el-icon-s-open"
                ></i>
                一键清空</a
              >
            </div>

            <el-input
              class="mt-2"
              type="textarea"
              disabled
              placeholder="公式内容"
              v-model="textarea"
              resize="none"
              maxlength="200"
              :autosize="{ minRows: 14 }"
              show-word-limit
            >
            </el-input>
            <div style="font-size: 16px; color: #333" class="mt-2">
              <i style="color: #3a85fd" class="el-icon-info"></i>
              当上述判断成立时，页面上会进行相关规则及规则说明的展示
            </div>
          </div>
          <div style="width: 45%; padding: 0px 15px" class="ml-2">
            <div>
              <div style="font-size: 16px; color: #333">
                手工输入
                <!-- <el-tooltip class="item" effect="dark" placement="top-start">
                                <div slot="content">
                                    存在行业值的财务指标有：<br />
                                    资产负债率(%)、速动比率(%)、现金流动负债比率(%)、带息负债比率(%)、<br />
                                    存货周转率(次)、流动资产周转率(次)、应收账款周转率(次)、总资产周转率(次)、<br />
                                    净资产收益率(%)、总资产报酬率(%)、盈余现金保障倍数、成本费用利润率(%)、<br />
                                    资本收益率(%)、销售（营业）利润率(%)、成本费用占主营业务收入比重(%)、<br />
                                    总资产增长率(%)、销售(营业)增长率(%)、销售(营业)利润增长率(%)、<br />
                                    资本积累率(%)、已获利息倍数、<br />
                                </div>
                                <i style="color:#3A85FD" class="el-icon-warning-outline"></i>
                            </el-tooltip> -->
              </div>
              <!-- <div class="mt-2 row  justify-content-between align-items-center"> -->
              <el-input
                class="mt-2 mr-2"
                type="number"
                size="medium"
                style="width: 54%"
                placeholder="请输入内容"
                v-model="num"
              ></el-input>
              <el-button
                class="ml-4 mt-2"
                type="primary"
                style="width: 90px; height: 34px; line-height: 0px"
                @click="numPush"
                >添加</el-button
              >
              <!-- </div> -->
            </div>

            <div class="mt-2" style="width: 85%">
              <div style="font-size: 16px; color: #333">行业值</div>
              <div class="row mt-2 justify-content-between">
                <el-button
                  style="
                    width: 90px;
                    height: 32px;
                    line-height: 0px;
                    padding: 0px;
                    background: #3a85fd;
                    color: #fff;
                  "
                  type="primary"
                  @click="dialogClick('优秀值')"
                  >优秀值</el-button
                >
                <el-button
                  style="
                    width: 90px;
                    height: 32px;
                    line-height: 0px;
                    padding: 0px;
                    background: #3a85fd;
                    color: #fff;
                  "
                  type="primary"
                  @click="dialogClick('平均值')"
                  >平均值</el-button
                >
                <el-button
                  style="
                    width: 90px;
                    height: 32px;
                    line-height: 0px;
                    padding: 0px;
                    background: #3a85fd;
                    color: #fff;
                  "
                  type="primary"
                  @click="dialogClick('较差值')"
                  >较差值</el-button
                >
              </div>
            </div>
            <div style="font-size: 16px; color: #333" class="mt-2">符号</div>
            <div class="row mt-1">
              <a
                class="a_Btn"
                v-for="item in btn"
                :key="item"
                @click="fuhao(item)"
              >
                {{ item }}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3 text-center">
        <el-button
          size="medium"
          style="width: 120px; color: #333"
          plain
          @click="infoEsc"
          >取 消</el-button
        >
        <el-button
          size="medium"
          style="width: 120px"
          @click="saveClick"
          type="primary"
          >保 存</el-button
        >
      </div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialog"
      width="40%"
      :before-close="handleClose"
    >
      <div class="row">
        <a
          class="a_btn_d"
          v-for="item in h_List"
          :key="item"
          @click="h_btnClick(item)"
          :style="
            item == h_value ? { color: '#fff', background: '#3a85fd' } : ''
          "
          >{{ item }}</a
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button style="width: 115px" size="medium" @click="dialog = false"
          >取 消</el-button
        >
        <el-button
          style="width: 115px"
          size="medium"
          type="primary"
          @click="h_append"
          >添 加</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as sesstion from "@/store/localStorage";
import {
  GlIndustryLinkage,
  SwIndustryLinkage,
  GbIndustryLinkage,
  subject,
  quota,
  addRule, //创建
  fxaddRule,
  putRule, //更新
  fxputRule, //风险
} from "@/api.js";
export default {
  components: {
    // selectTree: require('@/components/selectTree/selectTree.vue').default,
  },
  data() {
    return {
      name: "", //名称
      risk: "0", //风险等级
      optionsGbList: [
        ["gbA", "gbA01", "gbA011", "gbA0111"],
        ["gbA", "gbA01", "gbA011", "gbA0112"],
        ["gbA", "gbA01", "gbA011", "gbA0113"],
        ["gbA", "gbA01", "gbA011", "gbA0119"],
        ["gbA", "gbA01", "gbA012", "gbA0121"],
        ["gbA", "gbA01", "gbA012", "gbA0122"],
        ["gbA", "gbA01", "gbA012", "gbA0123"],
        ["gbA", "gbA01", "gbA013", "gbA0131"],
        ["gbA", "gbA01", "gbA013", "gbA0132"],
        ["gbA", "gbA01", "gbA013", "gbA0133"],
        ["gbA", "gbA01", "gbA013", "gbA0134"],
        ["gbA", "gbA01", "gbA014", "gbA0141"],
        ["gbA", "gbA01", "gbA014", "gbA0142"],
        ["gbA", "gbA01", "gbA014", "gbA0143"],
        ["gbA", "gbA01", "gbA014", "gbA0149"],
        ["gbA", "gbA01", "gbA015", "gbA0151"],
        ["gbA", "gbA01", "gbA015", "gbA0152"],
        ["gbA", "gbA01", "gbA015", "gbA0153"],
        ["gbA", "gbA01", "gbA015", "gbA0154"],
        ["gbA", "gbA01", "gbA015", "gbA0159"],
        ["gbA", "gbA01", "gbA016", "gbA0161"],
        ["gbA", "gbA01", "gbA016", "gbA0162"],
        ["gbA", "gbA01", "gbA016", "gbA0163"],
        ["gbA", "gbA01", "gbA016", "gbA0164"],
        ["gbA", "gbA01", "gbA016", "gbA0169"],
        ["gbA", "gbA01", "gbA017", "gbA0171"],
        ["gbA", "gbA01", "gbA017", "gbA0179"],
        ["gbA", "gbA01", "gbA018", "gbA0181"],
        ["gbA", "gbA01", "gbA018", "gbA0182"],
        ["gbA", "gbA01", "gbA019", "gbA0190"],
        ["gbA", "gbA02", "gbA021", "gbA0211"],
        ["gbA", "gbA02", "gbA021", "gbA0212"],
        ["gbA", "gbA02", "gbA022", "gbA0220"],
        ["gbA", "gbA02", "gbA023", "gbA0231"],
        ["gbA", "gbA02", "gbA023", "gbA0232"],
        ["gbA", "gbA02", "gbA024", "gbA0241"],
        ["gbA", "gbA02", "gbA024", "gbA0242"],
        ["gbA", "gbA02", "gbA025", "gbA0251"],
        ["gbA", "gbA02", "gbA025", "gbA0252"],
        ["gbA", "gbA03", "gbA031", "gbA0311"],
        ["gbA", "gbA03", "gbA031", "gbA0312"],
        ["gbA", "gbA03", "gbA031", "gbA0313"],
        ["gbA", "gbA03", "gbA031", "gbA0314"],
        ["gbA", "gbA03", "gbA031", "gbA0315"],
        ["gbA", "gbA03", "gbA031", "gbA0319"],
        ["gbA", "gbA03", "gbA032", "gbA0321"],
        ["gbA", "gbA03", "gbA032", "gbA0322"],
        ["gbA", "gbA03", "gbA032", "gbA0323"],
        ["gbA", "gbA03", "gbA032", "gbA0329"],
        ["gbA", "gbA03", "gbA033", "gbA0330"],
        ["gbA", "gbA03", "gbA039", "gbA0391"],
        ["gbA", "gbA03", "gbA039", "gbA0392"],
        ["gbA", "gbA03", "gbA039", "gbA0399"],
        ["gbA", "gbA04", "gbA041", "gbA0411"],
        ["gbA", "gbA04", "gbA041", "gbA0412"],
        ["gbA", "gbA04", "gbA042", "gbA0421"],
        ["gbA", "gbA04", "gbA042", "gbA0422"],
        ["gbA", "gbA05", "gbA051", "gbA0511"],
        ["gbA", "gbA05", "gbA051", "gbA0512"],
        ["gbA", "gbA05", "gbA051", "gbA0513"],
        ["gbA", "gbA05", "gbA051", "gbA0514"],
        ["gbA", "gbA05", "gbA051", "gbA0515"],
        ["gbA", "gbA05", "gbA051", "gbA0519"],
        ["gbA", "gbA05", "gbA052", "gbA0521"],
        ["gbA", "gbA05", "gbA052", "gbA0522"],
        ["gbA", "gbA05", "gbA052", "gbA0523"],
        ["gbA", "gbA05", "gbA052", "gbA0529"],
        ["gbA", "gbA05", "gbA053", "gbA0531"],
        ["gbA", "gbA05", "gbA053", "gbA0532"],
        ["gbA", "gbA05", "gbA053", "gbA0539"],
        ["gbA", "gbA05", "gbA054", "gbA0541"],
        ["gbA", "gbA05", "gbA054", "gbA0549"],
        ["gbB", "gbB06", "gbB061", "gbB0610"],
        ["gbB", "gbB06", "gbB062", "gbB0620"],
        ["gbB", "gbB06", "gbB069", "gbB0690"],
        ["gbB", "gbB07", "gbB071", "gbB0711"],
        ["gbB", "gbB07", "gbB071", "gbB0712"],
        ["gbB", "gbB07", "gbB072", "gbB0721"],
        ["gbB", "gbB07", "gbB072", "gbB0722"],
        ["gbB", "gbB08", "gbB081", "gbB0810"],
        ["gbB", "gbB08", "gbB082", "gbB0820"],
        ["gbB", "gbB08", "gbB089", "gbB0890"],
        ["gbB", "gbB09", "gbB091", "gbB0911"],
        ["gbB", "gbB09", "gbB091", "gbB0912"],
        ["gbB", "gbB09", "gbB091", "gbB0913"],
        ["gbB", "gbB09", "gbB091", "gbB0914"],
        ["gbB", "gbB09", "gbB091", "gbB0915"],
        ["gbB", "gbB09", "gbB091", "gbB0916"],
        ["gbB", "gbB09", "gbB091", "gbB0917"],
        ["gbB", "gbB09", "gbB091", "gbB0919"],
        ["gbB", "gbB09", "gbB092", "gbB0921"],
        ["gbB", "gbB09", "gbB092", "gbB0922"],
        ["gbB", "gbB09", "gbB092", "gbB0929"],
        ["gbB", "gbB09", "gbB093", "gbB0931"],
        ["gbB", "gbB09", "gbB093", "gbB0932"],
        ["gbB", "gbB09", "gbB093", "gbB0933"],
        ["gbB", "gbB09", "gbB093", "gbB0939"],
        ["gbB", "gbB10", "gbB101", "gbB1011"],
        ["gbB", "gbB10", "gbB101", "gbB1012"],
        ["gbB", "gbB10", "gbB101", "gbB1013"],
        ["gbB", "gbB10", "gbB101", "gbB1019"],
        ["gbB", "gbB10", "gbB102", "gbB1020"],
        ["gbB", "gbB10", "gbB103", "gbB1030"],
        ["gbB", "gbB10", "gbB109", "gbB1091"],
        ["gbB", "gbB10", "gbB109", "gbB1092"],
        ["gbB", "gbB10", "gbB109", "gbB1093"],
        ["gbB", "gbB10", "gbB109", "gbB1099"],
        ["gbB", "gbB11", "gbB111", "gbB1110"],
        ["gbB", "gbB11", "gbB112", "gbB1120"],
        ["gbB", "gbB11", "gbB119", "gbB1190"],
        ["gbB", "gbB12", "gbB120", "gbB1200"],
        ["gbC", "gbC13", "gbC131", "gbC1311"],
        ["gbC", "gbC13", "gbC131", "gbC1312"],
        ["gbC", "gbC13", "gbC131", "gbC1313"],
        ["gbC", "gbC13", "gbC131", "gbC1314"],
        ["gbC", "gbC13", "gbC131", "gbC1319"],
        ["gbC", "gbC13", "gbC132", "gbC1321"],
        ["gbC", "gbC13", "gbC132", "gbC1329"],
        ["gbC", "gbC13", "gbC133", "gbC1331"],
        ["gbC", "gbC13", "gbC133", "gbC1332"],
        ["gbC", "gbC13", "gbC134", "gbC1340"],
        ["gbC", "gbC13", "gbC135", "gbC1351"],
        ["gbC", "gbC13", "gbC135", "gbC1352"],
        ["gbC", "gbC13", "gbC135", "gbC1353"],
        ["gbC", "gbC13", "gbC136", "gbC1361"],
        ["gbC", "gbC13", "gbC136", "gbC1362"],
        ["gbC", "gbC13", "gbC136", "gbC1363"],
        ["gbC", "gbC13", "gbC136", "gbC1369"],
        ["gbC", "gbC13", "gbC137", "gbC1371"],
        ["gbC", "gbC13", "gbC137", "gbC1372"],
        ["gbC", "gbC13", "gbC137", "gbC1373"],
        ["gbC", "gbC13", "gbC139", "gbC1391"],
        ["gbC", "gbC13", "gbC139", "gbC1392"],
        ["gbC", "gbC13", "gbC139", "gbC1393"],
        ["gbC", "gbC13", "gbC139", "gbC1399"],
        ["gbC", "gbC14", "gbC141", "gbC1411"],
        ["gbC", "gbC14", "gbC141", "gbC1419"],
        ["gbC", "gbC14", "gbC142", "gbC1421"],
        ["gbC", "gbC14", "gbC142", "gbC1422"],
        ["gbC", "gbC14", "gbC143", "gbC1431"],
        ["gbC", "gbC14", "gbC143", "gbC1432"],
        ["gbC", "gbC14", "gbC143", "gbC1433"],
        ["gbC", "gbC14", "gbC143", "gbC1439"],
        ["gbC", "gbC14", "gbC144", "gbC1441"],
        ["gbC", "gbC14", "gbC144", "gbC1442"],
        ["gbC", "gbC14", "gbC144", "gbC1449"],
        ["gbC", "gbC14", "gbC145", "gbC1451"],
        ["gbC", "gbC14", "gbC145", "gbC1452"],
        ["gbC", "gbC14", "gbC145", "gbC1453"],
        ["gbC", "gbC14", "gbC145", "gbC1459"],
        ["gbC", "gbC14", "gbC146", "gbC1461"],
        ["gbC", "gbC14", "gbC146", "gbC1462"],
        ["gbC", "gbC14", "gbC146", "gbC1469"],
        ["gbC", "gbC14", "gbC149", "gbC1491"],
        ["gbC", "gbC14", "gbC149", "gbC1492"],
        ["gbC", "gbC14", "gbC149", "gbC1493"],
        ["gbC", "gbC14", "gbC149", "gbC1494"],
        ["gbC", "gbC14", "gbC149", "gbC1495"],
        ["gbC", "gbC14", "gbC149", "gbC1499"],
        ["gbC", "gbC15", "gbC151", "gbC1511"],
        ["gbC", "gbC15", "gbC151", "gbC1512"],
        ["gbC", "gbC15", "gbC151", "gbC1513"],
        ["gbC", "gbC15", "gbC151", "gbC1514"],
        ["gbC", "gbC15", "gbC151", "gbC1515"],
        ["gbC", "gbC15", "gbC151", "gbC1519"],
        ["gbC", "gbC15", "gbC152", "gbC1521"],
        ["gbC", "gbC15", "gbC152", "gbC1522"],
        ["gbC", "gbC15", "gbC152", "gbC1523"],
        ["gbC", "gbC15", "gbC152", "gbC1524"],
        ["gbC", "gbC15", "gbC152", "gbC1525"],
        ["gbC", "gbC15", "gbC152", "gbC1529"],
        ["gbC", "gbC15", "gbC153", "gbC1530"],
        ["gbC", "gbC16", "gbC161", "gbC1610"],
        ["gbC", "gbC16", "gbC162", "gbC1620"],
        ["gbC", "gbC16", "gbC169", "gbC1690"],
        ["gbC", "gbC17", "gbC171", "gbC1711"],
        ["gbC", "gbC17", "gbC171", "gbC1712"],
        ["gbC", "gbC17", "gbC171", "gbC1713"],
        ["gbC", "gbC17", "gbC172", "gbC1721"],
        ["gbC", "gbC17", "gbC172", "gbC1722"],
        ["gbC", "gbC17", "gbC172", "gbC1723"],
        ["gbC", "gbC17", "gbC173", "gbC1731"],
        ["gbC", "gbC17", "gbC173", "gbC1732"],
        ["gbC", "gbC17", "gbC173", "gbC1733"],
        ["gbC", "gbC17", "gbC174", "gbC1741"],
        ["gbC", "gbC17", "gbC174", "gbC1742"],
        ["gbC", "gbC17", "gbC174", "gbC1743"],
        ["gbC", "gbC17", "gbC175", "gbC1751"],
        ["gbC", "gbC17", "gbC175", "gbC1752"],
        ["gbC", "gbC17", "gbC176", "gbC1761"],
        ["gbC", "gbC17", "gbC176", "gbC1762"],
        ["gbC", "gbC17", "gbC176", "gbC1763"],
        ["gbC", "gbC17", "gbC177", "gbC1771"],
        ["gbC", "gbC17", "gbC177", "gbC1772"],
        ["gbC", "gbC17", "gbC177", "gbC1773"],
        ["gbC", "gbC17", "gbC177", "gbC1779"],
        ["gbC", "gbC17", "gbC178", "gbC1781"],
        ["gbC", "gbC17", "gbC178", "gbC1782"],
        ["gbC", "gbC17", "gbC178", "gbC1783"],
        ["gbC", "gbC17", "gbC178", "gbC1784"],
        ["gbC", "gbC17", "gbC178", "gbC1789"],
        ["gbC", "gbC18", "gbC181", "gbC1811"],
        ["gbC", "gbC18", "gbC181", "gbC1819"],
        ["gbC", "gbC18", "gbC182", "gbC1821"],
        ["gbC", "gbC18", "gbC182", "gbC1829"],
        ["gbC", "gbC18", "gbC183", "gbC1830"],
        ["gbC", "gbC19", "gbC191", "gbC1910"],
        ["gbC", "gbC19", "gbC192", "gbC1921"],
        ["gbC", "gbC19", "gbC192", "gbC1922"],
        ["gbC", "gbC19", "gbC192", "gbC1923"],
        ["gbC", "gbC19", "gbC192", "gbC1929"],
        ["gbC", "gbC19", "gbC193", "gbC1931"],
        ["gbC", "gbC19", "gbC193", "gbC1932"],
        ["gbC", "gbC19", "gbC193", "gbC1939"],
        ["gbC", "gbC19", "gbC194", "gbC1941"],
        ["gbC", "gbC19", "gbC194", "gbC1942"],
        ["gbC", "gbC19", "gbC195", "gbC1951"],
        ["gbC", "gbC19", "gbC195", "gbC1952"],
        ["gbC", "gbC19", "gbC195", "gbC1953"],
        ["gbC", "gbC19", "gbC195", "gbC1954"],
        ["gbC", "gbC19", "gbC195", "gbC1959"],
        ["gbC", "gbC20", "gbC201", "gbC2011"],
        ["gbC", "gbC20", "gbC201", "gbC2012"],
        ["gbC", "gbC20", "gbC201", "gbC2013"],
        ["gbC", "gbC20", "gbC201", "gbC2019"],
        ["gbC", "gbC20", "gbC202", "gbC2021"],
        ["gbC", "gbC20", "gbC202", "gbC2022"],
        ["gbC", "gbC20", "gbC202", "gbC2023"],
        ["gbC", "gbC20", "gbC202", "gbC2029"],
        ["gbC", "gbC20", "gbC203", "gbC2031"],
        ["gbC", "gbC20", "gbC203", "gbC2032"],
        ["gbC", "gbC20", "gbC203", "gbC2033"],
        ["gbC", "gbC20", "gbC203", "gbC2034"],
        ["gbC", "gbC20", "gbC203", "gbC2035"],
        ["gbC", "gbC20", "gbC203", "gbC2039"],
        ["gbC", "gbC20", "gbC204", "gbC2041"],
        ["gbC", "gbC20", "gbC204", "gbC2042"],
        ["gbC", "gbC20", "gbC204", "gbC2043"],
        ["gbC", "gbC20", "gbC204", "gbC2049"],
        ["gbC", "gbC21", "gbC211", "gbC2110"],
        ["gbC", "gbC21", "gbC212", "gbC2120"],
        ["gbC", "gbC21", "gbC213", "gbC2130"],
        ["gbC", "gbC21", "gbC214", "gbC2140"],
        ["gbC", "gbC21", "gbC219", "gbC2190"],
        ["gbC", "gbC22", "gbC221", "gbC2211"],
        ["gbC", "gbC22", "gbC221", "gbC2212"],
        ["gbC", "gbC22", "gbC222", "gbC2221"],
        ["gbC", "gbC22", "gbC222", "gbC2222"],
        ["gbC", "gbC22", "gbC222", "gbC2223"],
        ["gbC", "gbC22", "gbC223", "gbC2231"],
        ["gbC", "gbC22", "gbC223", "gbC2239"],
        ["gbC", "gbC23", "gbC231", "gbC2311"],
        ["gbC", "gbC23", "gbC231", "gbC2312"],
        ["gbC", "gbC23", "gbC231", "gbC2319"],
        ["gbC", "gbC23", "gbC232", "gbC2320"],
        ["gbC", "gbC23", "gbC233", "gbC2330"],
        ["gbC", "gbC24", "gbC241", "gbC2411"],
        ["gbC", "gbC24", "gbC241", "gbC2412"],
        ["gbC", "gbC24", "gbC241", "gbC2413"],
        ["gbC", "gbC24", "gbC241", "gbC2414"],
        ["gbC", "gbC24", "gbC241", "gbC2419"],
        ["gbC", "gbC24", "gbC242", "gbC2421"],
        ["gbC", "gbC24", "gbC242", "gbC2422"],
        ["gbC", "gbC24", "gbC242", "gbC2423"],
        ["gbC", "gbC24", "gbC242", "gbC2429"],
        ["gbC", "gbC24", "gbC243", "gbC2431"],
        ["gbC", "gbC24", "gbC243", "gbC2432"],
        ["gbC", "gbC24", "gbC243", "gbC2433"],
        ["gbC", "gbC24", "gbC243", "gbC2434"],
        ["gbC", "gbC24", "gbC243", "gbC2435"],
        ["gbC", "gbC24", "gbC243", "gbC2436"],
        ["gbC", "gbC24", "gbC243", "gbC2437"],
        ["gbC", "gbC24", "gbC243", "gbC2438"],
        ["gbC", "gbC24", "gbC243", "gbC2439"],
        ["gbC", "gbC24", "gbC244", "gbC2441"],
        ["gbC", "gbC24", "gbC244", "gbC2442"],
        ["gbC", "gbC24", "gbC244", "gbC2443"],
        ["gbC", "gbC24", "gbC244", "gbC2444"],
        ["gbC", "gbC24", "gbC244", "gbC2449"],
        ["gbC", "gbC24", "gbC245", "gbC2451"],
        ["gbC", "gbC24", "gbC245", "gbC2452"],
        ["gbC", "gbC24", "gbC245", "gbC2453"],
        ["gbC", "gbC24", "gbC245", "gbC2454"],
        ["gbC", "gbC24", "gbC245", "gbC2455"],
        ["gbC", "gbC24", "gbC245", "gbC2456"],
        ["gbC", "gbC24", "gbC245", "gbC2459"],
        ["gbC", "gbC24", "gbC246", "gbC2461"],
        ["gbC", "gbC24", "gbC246", "gbC2462"],
        ["gbC", "gbC24", "gbC246", "gbC2469"],
        ["gbC", "gbC25", "gbC251", "gbC2511"],
        ["gbC", "gbC25", "gbC251", "gbC2519"],
        ["gbC", "gbC25", "gbC252", "gbC2521"],
        ["gbC", "gbC25", "gbC252", "gbC2522"],
        ["gbC", "gbC25", "gbC252", "gbC2523"],
        ["gbC", "gbC25", "gbC252", "gbC2524"],
        ["gbC", "gbC25", "gbC252", "gbC2529"],
        ["gbC", "gbC25", "gbC253", "gbC2530"],
        ["gbC", "gbC25", "gbC254", "gbC2541"],
        ["gbC", "gbC25", "gbC254", "gbC2542"],
        ["gbC", "gbC26", "gbC261", "gbC2611"],
        ["gbC", "gbC26", "gbC261", "gbC2612"],
        ["gbC", "gbC26", "gbC261", "gbC2613"],
        ["gbC", "gbC26", "gbC261", "gbC2614"],
        ["gbC", "gbC26", "gbC261", "gbC2619"],
        ["gbC", "gbC26", "gbC262", "gbC2621"],
        ["gbC", "gbC26", "gbC262", "gbC2622"],
        ["gbC", "gbC26", "gbC262", "gbC2623"],
        ["gbC", "gbC26", "gbC262", "gbC2624"],
        ["gbC", "gbC26", "gbC262", "gbC2625"],
        ["gbC", "gbC26", "gbC262", "gbC2629"],
        ["gbC", "gbC26", "gbC263", "gbC2631"],
        ["gbC", "gbC26", "gbC263", "gbC2632"],
        ["gbC", "gbC26", "gbC264", "gbC2641"],
        ["gbC", "gbC26", "gbC264", "gbC2642"],
        ["gbC", "gbC26", "gbC264", "gbC2643"],
        ["gbC", "gbC26", "gbC264", "gbC2644"],
        ["gbC", "gbC26", "gbC264", "gbC2645"],
        ["gbC", "gbC26", "gbC264", "gbC2646"],
        ["gbC", "gbC26", "gbC265", "gbC2651"],
        ["gbC", "gbC26", "gbC265", "gbC2652"],
        ["gbC", "gbC26", "gbC265", "gbC2653"],
        ["gbC", "gbC26", "gbC265", "gbC2659"],
        ["gbC", "gbC26", "gbC266", "gbC2661"],
        ["gbC", "gbC26", "gbC266", "gbC2662"],
        ["gbC", "gbC26", "gbC266", "gbC2663"],
        ["gbC", "gbC26", "gbC266", "gbC2664"],
        ["gbC", "gbC26", "gbC266", "gbC2665"],
        ["gbC", "gbC26", "gbC266", "gbC2666"],
        ["gbC", "gbC26", "gbC266", "gbC2667"],
        ["gbC", "gbC26", "gbC266", "gbC2669"],
        ["gbC", "gbC26", "gbC267", "gbC2671"],
        ["gbC", "gbC26", "gbC267", "gbC2672"],
        ["gbC", "gbC26", "gbC268", "gbC2681"],
        ["gbC", "gbC26", "gbC268", "gbC2682"],
        ["gbC", "gbC26", "gbC268", "gbC2683"],
        ["gbC", "gbC26", "gbC268", "gbC2684"],
        ["gbC", "gbC26", "gbC268", "gbC2689"],
        ["gbC", "gbC27", "gbC271", "gbC2710"],
        ["gbC", "gbC27", "gbC272", "gbC2720"],
        ["gbC", "gbC27", "gbC273", "gbC2730"],
        ["gbC", "gbC27", "gbC274", "gbC2740"],
        ["gbC", "gbC27", "gbC275", "gbC2750"],
        ["gbC", "gbC27", "gbC276", "gbC2761"],
        ["gbC", "gbC27", "gbC276", "gbC2762"],
        ["gbC", "gbC27", "gbC277", "gbC2770"],
        ["gbC", "gbC27", "gbC278", "gbC2780"],
        ["gbC", "gbC28", "gbC281", "gbC2811"],
        ["gbC", "gbC28", "gbC281", "gbC2812"],
        ["gbC", "gbC28", "gbC282", "gbC2821"],
        ["gbC", "gbC28", "gbC282", "gbC2822"],
        ["gbC", "gbC28", "gbC282", "gbC2823"],
        ["gbC", "gbC28", "gbC282", "gbC2824"],
        ["gbC", "gbC28", "gbC282", "gbC2825"],
        ["gbC", "gbC28", "gbC282", "gbC2826"],
        ["gbC", "gbC28", "gbC282", "gbC2829"],
        ["gbC", "gbC28", "gbC283", "gbC2831"],
        ["gbC", "gbC28", "gbC283", "gbC2832"],
        ["gbC", "gbC29", "gbC291", "gbC2911"],
        ["gbC", "gbC29", "gbC291", "gbC2912"],
        ["gbC", "gbC29", "gbC291", "gbC2913"],
        ["gbC", "gbC29", "gbC291", "gbC2914"],
        ["gbC", "gbC29", "gbC291", "gbC2915"],
        ["gbC", "gbC29", "gbC291", "gbC2916"],
        ["gbC", "gbC29", "gbC291", "gbC2919"],
        ["gbC", "gbC29", "gbC292", "gbC2921"],
        ["gbC", "gbC29", "gbC292", "gbC2922"],
        ["gbC", "gbC29", "gbC292", "gbC2923"],
        ["gbC", "gbC29", "gbC292", "gbC2924"],
        ["gbC", "gbC29", "gbC292", "gbC2925"],
        ["gbC", "gbC29", "gbC292", "gbC2926"],
        ["gbC", "gbC29", "gbC292", "gbC2927"],
        ["gbC", "gbC29", "gbC292", "gbC2928"],
        ["gbC", "gbC29", "gbC292", "gbC2929"],
        ["gbC", "gbC30", "gbC301", "gbC3011"],
        ["gbC", "gbC30", "gbC301", "gbC3012"],
        ["gbC", "gbC30", "gbC302", "gbC3021"],
        ["gbC", "gbC30", "gbC302", "gbC3022"],
        ["gbC", "gbC30", "gbC302", "gbC3023"],
        ["gbC", "gbC30", "gbC302", "gbC3024"],
        ["gbC", "gbC30", "gbC302", "gbC3029"],
        ["gbC", "gbC30", "gbC303", "gbC3031"],
        ["gbC", "gbC30", "gbC303", "gbC3032"],
        ["gbC", "gbC30", "gbC303", "gbC3033"],
        ["gbC", "gbC30", "gbC303", "gbC3034"],
        ["gbC", "gbC30", "gbC303", "gbC3039"],
        ["gbC", "gbC30", "gbC304", "gbC3041"],
        ["gbC", "gbC30", "gbC304", "gbC3042"],
        ["gbC", "gbC30", "gbC304", "gbC3049"],
        ["gbC", "gbC30", "gbC305", "gbC3051"],
        ["gbC", "gbC30", "gbC305", "gbC3052"],
        ["gbC", "gbC30", "gbC305", "gbC3053"],
        ["gbC", "gbC30", "gbC305", "gbC3054"],
        ["gbC", "gbC30", "gbC305", "gbC3055"],
        ["gbC", "gbC30", "gbC305", "gbC3056"],
        ["gbC", "gbC30", "gbC305", "gbC3057"],
        ["gbC", "gbC30", "gbC305", "gbC3059"],
        ["gbC", "gbC30", "gbC306", "gbC3061"],
        ["gbC", "gbC30", "gbC306", "gbC3062"],
        ["gbC", "gbC30", "gbC307", "gbC3071"],
        ["gbC", "gbC30", "gbC307", "gbC3072"],
        ["gbC", "gbC30", "gbC307", "gbC3073"],
        ["gbC", "gbC30", "gbC307", "gbC3074"],
        ["gbC", "gbC30", "gbC307", "gbC3075"],
        ["gbC", "gbC30", "gbC307", "gbC3076"],
        ["gbC", "gbC30", "gbC307", "gbC3079"],
        ["gbC", "gbC30", "gbC308", "gbC3081"],
        ["gbC", "gbC30", "gbC308", "gbC3082"],
        ["gbC", "gbC30", "gbC308", "gbC3089"],
        ["gbC", "gbC30", "gbC309", "gbC3091"],
        ["gbC", "gbC30", "gbC309", "gbC3099"],
        ["gbC", "gbC31", "gbC311", "gbC3110"],
        ["gbC", "gbC31", "gbC312", "gbC3120"],
        ["gbC", "gbC31", "gbC313", "gbC3130"],
        ["gbC", "gbC31", "gbC314", "gbC3140"],
        ["gbC", "gbC32", "gbC321", "gbC3211"],
        ["gbC", "gbC32", "gbC321", "gbC3212"],
        ["gbC", "gbC32", "gbC321", "gbC3213"],
        ["gbC", "gbC32", "gbC321", "gbC3214"],
        ["gbC", "gbC32", "gbC321", "gbC3215"],
        ["gbC", "gbC32", "gbC321", "gbC3216"],
        ["gbC", "gbC32", "gbC321", "gbC3217"],
        ["gbC", "gbC32", "gbC321", "gbC3218"],
        ["gbC", "gbC32", "gbC321", "gbC3219"],
        ["gbC", "gbC32", "gbC322", "gbC3221"],
        ["gbC", "gbC32", "gbC322", "gbC3222"],
        ["gbC", "gbC32", "gbC322", "gbC3229"],
        ["gbC", "gbC32", "gbC323", "gbC3231"],
        ["gbC", "gbC32", "gbC323", "gbC3232"],
        ["gbC", "gbC32", "gbC323", "gbC3239"],
        ["gbC", "gbC32", "gbC324", "gbC3240"],
        ["gbC", "gbC32", "gbC325", "gbC3251"],
        ["gbC", "gbC32", "gbC325", "gbC3252"],
        ["gbC", "gbC32", "gbC325", "gbC3253"],
        ["gbC", "gbC32", "gbC325", "gbC3254"],
        ["gbC", "gbC32", "gbC325", "gbC3259"],
        ["gbC", "gbC33", "gbC331", "gbC3311"],
        ["gbC", "gbC33", "gbC331", "gbC3312"],
        ["gbC", "gbC33", "gbC332", "gbC3321"],
        ["gbC", "gbC33", "gbC332", "gbC3322"],
        ["gbC", "gbC33", "gbC332", "gbC3323"],
        ["gbC", "gbC33", "gbC332", "gbC3324"],
        ["gbC", "gbC33", "gbC332", "gbC3329"],
        ["gbC", "gbC33", "gbC333", "gbC3331"],
        ["gbC", "gbC33", "gbC333", "gbC3332"],
        ["gbC", "gbC33", "gbC333", "gbC3333"],
        ["gbC", "gbC33", "gbC334", "gbC3340"],
        ["gbC", "gbC33", "gbC335", "gbC3351"],
        ["gbC", "gbC33", "gbC335", "gbC3352"],
        ["gbC", "gbC33", "gbC335", "gbC3353"],
        ["gbC", "gbC33", "gbC335", "gbC3359"],
        ["gbC", "gbC33", "gbC336", "gbC3360"],
        ["gbC", "gbC33", "gbC337", "gbC3371"],
        ["gbC", "gbC33", "gbC337", "gbC3372"],
        ["gbC", "gbC33", "gbC337", "gbC3373"],
        ["gbC", "gbC33", "gbC337", "gbC3379"],
        ["gbC", "gbC33", "gbC338", "gbC3381"],
        ["gbC", "gbC33", "gbC338", "gbC3382"],
        ["gbC", "gbC33", "gbC338", "gbC3383"],
        ["gbC", "gbC33", "gbC338", "gbC3389"],
        ["gbC", "gbC33", "gbC339", "gbC3391"],
        ["gbC", "gbC33", "gbC339", "gbC3392"],
        ["gbC", "gbC33", "gbC339", "gbC3393"],
        ["gbC", "gbC33", "gbC339", "gbC3394"],
        ["gbC", "gbC33", "gbC339", "gbC3399"],
        ["gbC", "gbC34", "gbC341", "gbC3411"],
        ["gbC", "gbC34", "gbC341", "gbC3412"],
        ["gbC", "gbC34", "gbC341", "gbC3413"],
        ["gbC", "gbC34", "gbC341", "gbC3414"],
        ["gbC", "gbC34", "gbC341", "gbC3415"],
        ["gbC", "gbC34", "gbC341", "gbC3419"],
        ["gbC", "gbC34", "gbC342", "gbC3421"],
        ["gbC", "gbC34", "gbC342", "gbC3422"],
        ["gbC", "gbC34", "gbC342", "gbC3423"],
        ["gbC", "gbC34", "gbC342", "gbC3424"],
        ["gbC", "gbC34", "gbC342", "gbC3425"],
        ["gbC", "gbC34", "gbC342", "gbC3429"],
        ["gbC", "gbC34", "gbC343", "gbC3431"],
        ["gbC", "gbC34", "gbC343", "gbC3432"],
        ["gbC", "gbC34", "gbC343", "gbC3433"],
        ["gbC", "gbC34", "gbC343", "gbC3434"],
        ["gbC", "gbC34", "gbC343", "gbC3435"],
        ["gbC", "gbC34", "gbC343", "gbC3436"],
        ["gbC", "gbC34", "gbC343", "gbC3437"],
        ["gbC", "gbC34", "gbC343", "gbC3439"],
        ["gbC", "gbC34", "gbC344", "gbC3442"],
        ["gbC", "gbC34", "gbC344", "gbC3441"],
        ["gbC", "gbC34", "gbC344", "gbC3443"],
        ["gbC", "gbC34", "gbC344", "gbC3444"],
        ["gbC", "gbC34", "gbC344", "gbC3445"],
        ["gbC", "gbC34", "gbC344", "gbC3446"],
        ["gbC", "gbC34", "gbC345", "gbC3451"],
        ["gbC", "gbC34", "gbC345", "gbC3452"],
        ["gbC", "gbC34", "gbC345", "gbC3453"],
        ["gbC", "gbC34", "gbC345", "gbC3459"],
        ["gbC", "gbC34", "gbC346", "gbC3461"],
        ["gbC", "gbC34", "gbC346", "gbC3462"],
        ["gbC", "gbC34", "gbC346", "gbC3463"],
        ["gbC", "gbC34", "gbC346", "gbC3464"],
        ["gbC", "gbC34", "gbC346", "gbC3465"],
        ["gbC", "gbC34", "gbC346", "gbC3466"],
        ["gbC", "gbC34", "gbC346", "gbC3467"],
        ["gbC", "gbC34", "gbC347", "gbC3471"],
        ["gbC", "gbC34", "gbC347", "gbC3472"],
        ["gbC", "gbC34", "gbC347", "gbC3473"],
        ["gbC", "gbC34", "gbC347", "gbC3474"],
        ["gbC", "gbC34", "gbC347", "gbC3475"],
        ["gbC", "gbC34", "gbC347", "gbC3479"],
        ["gbC", "gbC34", "gbC348", "gbC3481"],
        ["gbC", "gbC34", "gbC348", "gbC3482"],
        ["gbC", "gbC34", "gbC348", "gbC3483"],
        ["gbC", "gbC34", "gbC348", "gbC3484"],
        ["gbC", "gbC34", "gbC348", "gbC3489"],
        ["gbC", "gbC34", "gbC349", "gbC3491"],
        ["gbC", "gbC34", "gbC349", "gbC3492"],
        ["gbC", "gbC34", "gbC349", "gbC3493"],
        ["gbC", "gbC34", "gbC349", "gbC3499"],
        ["gbC", "gbC35", "gbC351", "gbC3511"],
        ["gbC", "gbC35", "gbC351", "gbC3512"],
        ["gbC", "gbC35", "gbC351", "gbC3513"],
        ["gbC", "gbC35", "gbC351", "gbC3514"],
        ["gbC", "gbC35", "gbC351", "gbC3515"],
        ["gbC", "gbC35", "gbC351", "gbC3516"],
        ["gbC", "gbC35", "gbC351", "gbC3517"],
        ["gbC", "gbC35", "gbC352", "gbC3521"],
        ["gbC", "gbC35", "gbC352", "gbC3522"],
        ["gbC", "gbC35", "gbC352", "gbC3523"],
        ["gbC", "gbC35", "gbC352", "gbC3524"],
        ["gbC", "gbC35", "gbC352", "gbC3525"],
        ["gbC", "gbC35", "gbC352", "gbC3529"],
        ["gbC", "gbC35", "gbC353", "gbC3531"],
        ["gbC", "gbC35", "gbC353", "gbC3532"],
        ["gbC", "gbC35", "gbC353", "gbC3533"],
        ["gbC", "gbC35", "gbC353", "gbC3534"],
        ["gbC", "gbC35", "gbC354", "gbC3541"],
        ["gbC", "gbC35", "gbC354", "gbC3542"],
        ["gbC", "gbC35", "gbC354", "gbC3543"],
        ["gbC", "gbC35", "gbC354", "gbC3544"],
        ["gbC", "gbC35", "gbC354", "gbC3545"],
        ["gbC", "gbC35", "gbC354", "gbC3546"],
        ["gbC", "gbC35", "gbC354", "gbC3549"],
        ["gbC", "gbC35", "gbC355", "gbC3551"],
        ["gbC", "gbC35", "gbC355", "gbC3552"],
        ["gbC", "gbC35", "gbC355", "gbC3553"],
        ["gbC", "gbC35", "gbC355", "gbC3554"],
        ["gbC", "gbC35", "gbC356", "gbC3561"],
        ["gbC", "gbC35", "gbC356", "gbC3562"],
        ["gbC", "gbC35", "gbC356", "gbC3563"],
        ["gbC", "gbC35", "gbC356", "gbC3569"],
        ["gbC", "gbC35", "gbC357", "gbC3571"],
        ["gbC", "gbC35", "gbC357", "gbC3572"],
        ["gbC", "gbC35", "gbC357", "gbC3573"],
        ["gbC", "gbC35", "gbC357", "gbC3574"],
        ["gbC", "gbC35", "gbC357", "gbC3575"],
        ["gbC", "gbC35", "gbC357", "gbC3576"],
        ["gbC", "gbC35", "gbC357", "gbC3577"],
        ["gbC", "gbC35", "gbC357", "gbC3579"],
        ["gbC", "gbC35", "gbC358", "gbC3581"],
        ["gbC", "gbC35", "gbC358", "gbC3582"],
        ["gbC", "gbC35", "gbC358", "gbC3583"],
        ["gbC", "gbC35", "gbC358", "gbC3584"],
        ["gbC", "gbC35", "gbC358", "gbC3585"],
        ["gbC", "gbC35", "gbC358", "gbC3586"],
        ["gbC", "gbC35", "gbC358", "gbC3587"],
        ["gbC", "gbC35", "gbC358", "gbC3589"],
        ["gbC", "gbC35", "gbC359", "gbC3591"],
        ["gbC", "gbC35", "gbC359", "gbC3592"],
        ["gbC", "gbC35", "gbC359", "gbC3593"],
        ["gbC", "gbC35", "gbC359", "gbC3594"],
        ["gbC", "gbC35", "gbC359", "gbC3595"],
        ["gbC", "gbC35", "gbC359", "gbC3596"],
        ["gbC", "gbC35", "gbC359", "gbC3597"],
        ["gbC", "gbC35", "gbC359", "gbC3599"],
        ["gbC", "gbC36", "gbC361", "gbC3611"],
        ["gbC", "gbC36", "gbC361", "gbC3612"],
        ["gbC", "gbC36", "gbC362", "gbC3620"],
        ["gbC", "gbC36", "gbC363", "gbC3630"],
        ["gbC", "gbC36", "gbC364", "gbC3640"],
        ["gbC", "gbC36", "gbC365", "gbC3650"],
        ["gbC", "gbC36", "gbC366", "gbC3660"],
        ["gbC", "gbC36", "gbC367", "gbC3670"],
        ["gbC", "gbC37", "gbC371", "gbC3711"],
        ["gbC", "gbC37", "gbC371", "gbC3712"],
        ["gbC", "gbC37", "gbC371", "gbC3713"],
        ["gbC", "gbC37", "gbC371", "gbC3714"],
        ["gbC", "gbC37", "gbC371", "gbC3715"],
        ["gbC", "gbC37", "gbC371", "gbC3716"],
        ["gbC", "gbC37", "gbC371", "gbC3719"],
        ["gbC", "gbC37", "gbC372", "gbC3720"],
        ["gbC", "gbC37", "gbC373", "gbC3731"],
        ["gbC", "gbC37", "gbC373", "gbC3732"],
        ["gbC", "gbC37", "gbC373", "gbC3733"],
        ["gbC", "gbC37", "gbC373", "gbC3734"],
        ["gbC", "gbC37", "gbC373", "gbC3735"],
        ["gbC", "gbC37", "gbC373", "gbC3736"],
        ["gbC", "gbC37", "gbC373", "gbC3737"],
        ["gbC", "gbC37", "gbC373", "gbC3739"],
        ["gbC", "gbC37", "gbC374", "gbC3741"],
        ["gbC", "gbC37", "gbC374", "gbC3742"],
        ["gbC", "gbC37", "gbC374", "gbC3743"],
        ["gbC", "gbC37", "gbC374", "gbC3744"],
        ["gbC", "gbC37", "gbC374", "gbC3749"],
        ["gbC", "gbC37", "gbC375", "gbC3751"],
        ["gbC", "gbC37", "gbC375", "gbC3752"],
        ["gbC", "gbC37", "gbC376", "gbC3761"],
        ["gbC", "gbC37", "gbC376", "gbC3762"],
        ["gbC", "gbC37", "gbC377", "gbC3770"],
        ["gbC", "gbC37", "gbC378", "gbC3780"],
        ["gbC", "gbC37", "gbC379", "gbC3791"],
        ["gbC", "gbC37", "gbC379", "gbC3792"],
        ["gbC", "gbC37", "gbC379", "gbC3799"],
        ["gbC", "gbC38", "gbC381", "gbC3811"],
        ["gbC", "gbC38", "gbC381", "gbC3812"],
        ["gbC", "gbC38", "gbC381", "gbC3813"],
        ["gbC", "gbC38", "gbC381", "gbC3819"],
        ["gbC", "gbC38", "gbC382", "gbC3821"],
        ["gbC", "gbC38", "gbC382", "gbC3822"],
        ["gbC", "gbC38", "gbC382", "gbC3823"],
        ["gbC", "gbC38", "gbC382", "gbC3824"],
        ["gbC", "gbC38", "gbC382", "gbC3825"],
        ["gbC", "gbC38", "gbC382", "gbC3829"],
        ["gbC", "gbC38", "gbC383", "gbC3831"],
        ["gbC", "gbC38", "gbC383", "gbC3832"],
        ["gbC", "gbC38", "gbC383", "gbC3833"],
        ["gbC", "gbC38", "gbC383", "gbC3834"],
        ["gbC", "gbC38", "gbC383", "gbC3839"],
        ["gbC", "gbC38", "gbC384", "gbC3841"],
        ["gbC", "gbC38", "gbC384", "gbC3842"],
        ["gbC", "gbC38", "gbC384", "gbC3843"],
        ["gbC", "gbC38", "gbC384", "gbC3844"],
        ["gbC", "gbC38", "gbC384", "gbC3849"],
        ["gbC", "gbC38", "gbC385", "gbC3851"],
        ["gbC", "gbC38", "gbC385", "gbC3852"],
        ["gbC", "gbC38", "gbC385", "gbC3853"],
        ["gbC", "gbC38", "gbC385", "gbC3854"],
        ["gbC", "gbC38", "gbC385", "gbC3855"],
        ["gbC", "gbC38", "gbC385", "gbC3856"],
        ["gbC", "gbC38", "gbC385", "gbC3857"],
        ["gbC", "gbC38", "gbC385", "gbC3859"],
        ["gbC", "gbC38", "gbC386", "gbC3861"],
        ["gbC", "gbC38", "gbC386", "gbC3862"],
        ["gbC", "gbC38", "gbC386", "gbC3869"],
        ["gbC", "gbC38", "gbC387", "gbC3871"],
        ["gbC", "gbC38", "gbC387", "gbC3872"],
        ["gbC", "gbC38", "gbC387", "gbC3873"],
        ["gbC", "gbC38", "gbC387", "gbC3874"],
        ["gbC", "gbC38", "gbC387", "gbC3879"],
        ["gbC", "gbC38", "gbC389", "gbC3891"],
        ["gbC", "gbC38", "gbC389", "gbC3899"],
        ["gbC", "gbC39", "gbC391", "gbC3911"],
        ["gbC", "gbC39", "gbC391", "gbC3912"],
        ["gbC", "gbC39", "gbC391", "gbC3913"],
        ["gbC", "gbC39", "gbC391", "gbC3914"],
        ["gbC", "gbC39", "gbC391", "gbC3915"],
        ["gbC", "gbC39", "gbC391", "gbC3919"],
        ["gbC", "gbC39", "gbC392", "gbC3921"],
        ["gbC", "gbC39", "gbC392", "gbC3922"],
        ["gbC", "gbC39", "gbC393", "gbC3931"],
        ["gbC", "gbC39", "gbC393", "gbC3932"],
        ["gbC", "gbC39", "gbC393", "gbC3933"],
        ["gbC", "gbC39", "gbC393", "gbC3934"],
        ["gbC", "gbC39", "gbC393", "gbC3939"],
        ["gbC", "gbC39", "gbC394", "gbC3940"],
        ["gbC", "gbC39", "gbC395", "gbC3951"],
        ["gbC", "gbC39", "gbC395", "gbC3952"],
        ["gbC", "gbC39", "gbC395", "gbC3953"],
        ["gbC", "gbC39", "gbC396", "gbC3961"],
        ["gbC", "gbC39", "gbC396", "gbC3962"],
        ["gbC", "gbC39", "gbC396", "gbC3963"],
        ["gbC", "gbC39", "gbC396", "gbC3964"],
        ["gbC", "gbC39", "gbC396", "gbC3969"],
        ["gbC", "gbC39", "gbC397", "gbC3971"],
        ["gbC", "gbC39", "gbC397", "gbC3972"],
        ["gbC", "gbC39", "gbC397", "gbC3973"],
        ["gbC", "gbC39", "gbC397", "gbC3974"],
        ["gbC", "gbC39", "gbC397", "gbC3975"],
        ["gbC", "gbC39", "gbC397", "gbC3976"],
        ["gbC", "gbC39", "gbC397", "gbC3979"],
        ["gbC", "gbC39", "gbC398", "gbC3981"],
        ["gbC", "gbC39", "gbC398", "gbC3982"],
        ["gbC", "gbC39", "gbC398", "gbC3983"],
        ["gbC", "gbC39", "gbC398", "gbC3984"],
        ["gbC", "gbC39", "gbC398", "gbC3985"],
        ["gbC", "gbC39", "gbC398", "gbC3989"],
        ["gbC", "gbC39", "gbC399", "gbC3990"],
        ["gbC", "gbC40", "gbC401", "gbC4011"],
        ["gbC", "gbC40", "gbC401", "gbC4012"],
        ["gbC", "gbC40", "gbC401", "gbC4013"],
        ["gbC", "gbC40", "gbC401", "gbC4014"],
        ["gbC", "gbC40", "gbC401", "gbC4015"],
        ["gbC", "gbC40", "gbC401", "gbC4016"],
        ["gbC", "gbC40", "gbC401", "gbC4019"],
        ["gbC", "gbC40", "gbC402", "gbC4021"],
        ["gbC", "gbC40", "gbC402", "gbC4022"],
        ["gbC", "gbC40", "gbC402", "gbC4023"],
        ["gbC", "gbC40", "gbC402", "gbC4024"],
        ["gbC", "gbC40", "gbC402", "gbC4025"],
        ["gbC", "gbC40", "gbC402", "gbC4026"],
        ["gbC", "gbC40", "gbC402", "gbC4027"],
        ["gbC", "gbC40", "gbC402", "gbC4028"],
        ["gbC", "gbC40", "gbC402", "gbC4029"],
        ["gbC", "gbC40", "gbC403", "gbC4030"],
        ["gbC", "gbC40", "gbC404", "gbC4040"],
        ["gbC", "gbC40", "gbC405", "gbC4050"],
        ["gbC", "gbC40", "gbC409", "gbC4090"],
        ["gbC", "gbC41", "gbC411", "gbC4111"],
        ["gbC", "gbC41", "gbC411", "gbC4119"],
        ["gbC", "gbC41", "gbC412", "gbC4120"],
        ["gbC", "gbC41", "gbC419", "gbC4190"],
        ["gbC", "gbC42", "gbC421", "gbC4210"],
        ["gbC", "gbC42", "gbC422", "gbC4220"],
        ["gbC", "gbC43", "gbC431", "gbC4310"],
        ["gbC", "gbC43", "gbC432", "gbC4320"],
        ["gbC", "gbC43", "gbC433", "gbC4330"],
        ["gbC", "gbC43", "gbC434", "gbC4341"],
        ["gbC", "gbC43", "gbC434", "gbC4342"],
        ["gbC", "gbC43", "gbC434", "gbC4343"],
        ["gbC", "gbC43", "gbC434", "gbC4349"],
        ["gbC", "gbC43", "gbC435", "gbC4350"],
        ["gbC", "gbC43", "gbC436", "gbC4360"],
        ["gbC", "gbC43", "gbC439", "gbC4390"],
        ["gbD", "gbD44", "gbD441", "gbD4411"],
        ["gbD", "gbD44", "gbD441", "gbD4412"],
        ["gbD", "gbD44", "gbD441", "gbD4413"],
        ["gbD", "gbD44", "gbD441", "gbD4414"],
        ["gbD", "gbD44", "gbD441", "gbD4415"],
        ["gbD", "gbD44", "gbD441", "gbD4416"],
        ["gbD", "gbD44", "gbD441", "gbD4417"],
        ["gbD", "gbD44", "gbD441", "gbD4419"],
        ["gbD", "gbD44", "gbD442", "gbD4420"],
        ["gbD", "gbD44", "gbD443", "gbD4430"],
        ["gbD", "gbD45", "gbD451", "gbD4511"],
        ["gbD", "gbD45", "gbD451", "gbD4512"],
        ["gbD", "gbD45", "gbD451", "gbD4513"],
        ["gbD", "gbD45", "gbD452", "gbD4520"],
        ["gbD", "gbD46", "gbD461", "gbD4610"],
        ["gbD", "gbD46", "gbD462", "gbD4620"],
        ["gbD", "gbD46", "gbD463", "gbD4630"],
        ["gbD", "gbD46", "gbD469", "gbD4690"],
        ["gbE", "gbE47", "gbE471", "gbE4710"],
        ["gbE", "gbE47", "gbE472", "gbE4720"],
        ["gbE", "gbE47", "gbE479", "gbE4790"],
        ["gbE", "gbE48", "gbE481", "gbE4811"],
        ["gbE", "gbE48", "gbE481", "gbE4812"],
        ["gbE", "gbE48", "gbE481", "gbE4813"],
        ["gbE", "gbE48", "gbE481", "gbE4814"],
        ["gbE", "gbE48", "gbE481", "gbE4819"],
        ["gbE", "gbE48", "gbE482", "gbE4821"],
        ["gbE", "gbE48", "gbE482", "gbE4822"],
        ["gbE", "gbE48", "gbE482", "gbE4823"],
        ["gbE", "gbE48", "gbE483", "gbE4831"],
        ["gbE", "gbE48", "gbE483", "gbE4832"],
        ["gbE", "gbE48", "gbE483", "gbE4833"],
        ["gbE", "gbE48", "gbE483", "gbE4834"],
        ["gbE", "gbE48", "gbE483", "gbE4839"],
        ["gbE", "gbE48", "gbE484", "gbE4840"],
        ["gbE", "gbE48", "gbE485", "gbE4851"],
        ["gbE", "gbE48", "gbE485", "gbE4852"],
        ["gbE", "gbE48", "gbE485", "gbE4853"],
        ["gbE", "gbE48", "gbE486", "gbE4861"],
        ["gbE", "gbE48", "gbE486", "gbE4862"],
        ["gbE", "gbE48", "gbE486", "gbE4863"],
        ["gbE", "gbE48", "gbE487", "gbE4871"],
        ["gbE", "gbE48", "gbE487", "gbE4872"],
        ["gbE", "gbE48", "gbE487", "gbE4873"],
        ["gbE", "gbE48", "gbE487", "gbE4874"],
        ["gbE", "gbE48", "gbE487", "gbE4875"],
        ["gbE", "gbE48", "gbE487", "gbE4879"],
        ["gbE", "gbE48", "gbE489", "gbE4891"],
        ["gbE", "gbE48", "gbE489", "gbE4892"],
        ["gbE", "gbE48", "gbE489", "gbE4893"],
        ["gbE", "gbE48", "gbE489", "gbE4899"],
        ["gbE", "gbE49", "gbE491", "gbE4910"],
        ["gbE", "gbE49", "gbE492", "gbE4920"],
        ["gbE", "gbE49", "gbE499", "gbE4991"],
        ["gbE", "gbE49", "gbE499", "gbE4999"],
        ["gbE", "gbE50", "gbE501", "gbE5011"],
        ["gbE", "gbE50", "gbE501", "gbE5012"],
        ["gbE", "gbE50", "gbE501", "gbE5013"],
        ["gbE", "gbE50", "gbE502", "gbE5021"],
        ["gbE", "gbE50", "gbE502", "gbE5022"],
        ["gbE", "gbE50", "gbE503", "gbE5030"],
        ["gbE", "gbE50", "gbE509", "gbE5090"],
        ["gbF", "gbF51", "gbF511", "gbF5111"],
        ["gbF", "gbF51", "gbF511", "gbF5112"],
        ["gbF", "gbF51", "gbF511", "gbF5113"],
        ["gbF", "gbF51", "gbF511", "gbF5114"],
        ["gbF", "gbF51", "gbF511", "gbF5115"],
        ["gbF", "gbF51", "gbF511", "gbF5116"],
        ["gbF", "gbF51", "gbF511", "gbF5117"],
        ["gbF", "gbF51", "gbF511", "gbF5119"],
        ["gbF", "gbF51", "gbF512", "gbF5121"],
        ["gbF", "gbF51", "gbF512", "gbF5122"],
        ["gbF", "gbF51", "gbF512", "gbF5123"],
        ["gbF", "gbF51", "gbF512", "gbF5124"],
        ["gbF", "gbF51", "gbF512", "gbF5125"],
        ["gbF", "gbF51", "gbF512", "gbF5126"],
        ["gbF", "gbF51", "gbF512", "gbF5127"],
        ["gbF", "gbF51", "gbF512", "gbF5128"],
        ["gbF", "gbF51", "gbF512", "gbF5129"],
        ["gbF", "gbF51", "gbF513", "gbF5131"],
        ["gbF", "gbF51", "gbF513", "gbF5132"],
        ["gbF", "gbF51", "gbF513", "gbF5133"],
        ["gbF", "gbF51", "gbF513", "gbF5134"],
        ["gbF", "gbF51", "gbF513", "gbF5135"],
        ["gbF", "gbF51", "gbF513", "gbF5136"],
        ["gbF", "gbF51", "gbF513", "gbF5137"],
        ["gbF", "gbF51", "gbF513", "gbF5138"],
        ["gbF", "gbF51", "gbF513", "gbF5139"],
        ["gbF", "gbF51", "gbF514", "gbF5141"],
        ["gbF", "gbF51", "gbF514", "gbF5142"],
        ["gbF", "gbF51", "gbF514", "gbF5143"],
        ["gbF", "gbF51", "gbF514", "gbF5144"],
        ["gbF", "gbF51", "gbF514", "gbF5145"],
        ["gbF", "gbF51", "gbF514", "gbF5146"],
        ["gbF", "gbF51", "gbF514", "gbF5147"],
        ["gbF", "gbF51", "gbF514", "gbF5149"],
        ["gbF", "gbF51", "gbF515", "gbF5151"],
        ["gbF", "gbF51", "gbF515", "gbF5152"],
        ["gbF", "gbF51", "gbF515", "gbF5153"],
        ["gbF", "gbF51", "gbF515", "gbF5154"],
        ["gbF", "gbF51", "gbF516", "gbF5161"],
        ["gbF", "gbF51", "gbF516", "gbF5162"],
        ["gbF", "gbF51", "gbF516", "gbF5163"],
        ["gbF", "gbF51", "gbF516", "gbF5164"],
        ["gbF", "gbF51", "gbF516", "gbF5165"],
        ["gbF", "gbF51", "gbF516", "gbF5166"],
        ["gbF", "gbF51", "gbF516", "gbF5167"],
        ["gbF", "gbF51", "gbF516", "gbF5168"],
        ["gbF", "gbF51", "gbF516", "gbF5169"],
        ["gbF", "gbF51", "gbF517", "gbF5171"],
        ["gbF", "gbF51", "gbF517", "gbF5172"],
        ["gbF", "gbF51", "gbF517", "gbF5173"],
        ["gbF", "gbF51", "gbF517", "gbF5174"],
        ["gbF", "gbF51", "gbF517", "gbF5175"],
        ["gbF", "gbF51", "gbF517", "gbF5176"],
        ["gbF", "gbF51", "gbF517", "gbF5177"],
        ["gbF", "gbF51", "gbF517", "gbF5178"],
        ["gbF", "gbF51", "gbF517", "gbF5179"],
        ["gbF", "gbF51", "gbF518", "gbF5181"],
        ["gbF", "gbF51", "gbF518", "gbF5182"],
        ["gbF", "gbF51", "gbF518", "gbF5183"],
        ["gbF", "gbF51", "gbF518", "gbF5184"],
        ["gbF", "gbF51", "gbF518", "gbF5189"],
        ["gbF", "gbF51", "gbF519", "gbF5191"],
        ["gbF", "gbF51", "gbF519", "gbF5192"],
        ["gbF", "gbF51", "gbF519", "gbF5193"],
        ["gbF", "gbF51", "gbF519", "gbF5199"],
        ["gbF", "gbF52", "gbF521", "gbF5211"],
        ["gbF", "gbF52", "gbF521", "gbF5212"],
        ["gbF", "gbF52", "gbF521", "gbF5213"],
        ["gbF", "gbF52", "gbF521", "gbF5219"],
        ["gbF", "gbF52", "gbF522", "gbF5221"],
        ["gbF", "gbF52", "gbF522", "gbF5222"],
        ["gbF", "gbF52", "gbF522", "gbF5223"],
        ["gbF", "gbF52", "gbF522", "gbF5224"],
        ["gbF", "gbF52", "gbF522", "gbF5225"],
        ["gbF", "gbF52", "gbF522", "gbF5226"],
        ["gbF", "gbF52", "gbF522", "gbF5227"],
        ["gbF", "gbF52", "gbF522", "gbF5229"],
        ["gbF", "gbF52", "gbF523", "gbF5231"],
        ["gbF", "gbF52", "gbF523", "gbF5232"],
        ["gbF", "gbF52", "gbF523", "gbF5233"],
        ["gbF", "gbF52", "gbF523", "gbF5234"],
        ["gbF", "gbF52", "gbF523", "gbF5235"],
        ["gbF", "gbF52", "gbF523", "gbF5236"],
        ["gbF", "gbF52", "gbF523", "gbF5237"],
        ["gbF", "gbF52", "gbF523", "gbF5238"],
        ["gbF", "gbF52", "gbF523", "gbF5239"],
        ["gbF", "gbF52", "gbF524", "gbF5241"],
        ["gbF", "gbF52", "gbF524", "gbF5242"],
        ["gbF", "gbF52", "gbF524", "gbF5243"],
        ["gbF", "gbF52", "gbF524", "gbF5244"],
        ["gbF", "gbF52", "gbF524", "gbF5245"],
        ["gbF", "gbF52", "gbF524", "gbF5246"],
        ["gbF", "gbF52", "gbF524", "gbF5247"],
        ["gbF", "gbF52", "gbF524", "gbF5248"],
        ["gbF", "gbF52", "gbF524", "gbF5249"],
        ["gbF", "gbF52", "gbF525", "gbF5251"],
        ["gbF", "gbF52", "gbF525", "gbF5252"],
        ["gbF", "gbF52", "gbF525", "gbF5253"],
        ["gbF", "gbF52", "gbF525", "gbF5254"],
        ["gbF", "gbF52", "gbF525", "gbF5255"],
        ["gbF", "gbF52", "gbF526", "gbF5261"],
        ["gbF", "gbF52", "gbF526", "gbF5262"],
        ["gbF", "gbF52", "gbF526", "gbF5263"],
        ["gbF", "gbF52", "gbF526", "gbF5264"],
        ["gbF", "gbF52", "gbF526", "gbF5265"],
        ["gbF", "gbF52", "gbF526", "gbF5266"],
        ["gbF", "gbF52", "gbF526", "gbF5267"],
        ["gbF", "gbF52", "gbF527", "gbF5271"],
        ["gbF", "gbF52", "gbF527", "gbF5272"],
        ["gbF", "gbF52", "gbF527", "gbF5273"],
        ["gbF", "gbF52", "gbF527", "gbF5274"],
        ["gbF", "gbF52", "gbF527", "gbF5279"],
        ["gbF", "gbF52", "gbF528", "gbF5281"],
        ["gbF", "gbF52", "gbF528", "gbF5282"],
        ["gbF", "gbF52", "gbF528", "gbF5283"],
        ["gbF", "gbF52", "gbF528", "gbF5284"],
        ["gbF", "gbF52", "gbF528", "gbF5285"],
        ["gbF", "gbF52", "gbF528", "gbF5286"],
        ["gbF", "gbF52", "gbF528", "gbF5287"],
        ["gbF", "gbF52", "gbF528", "gbF5289"],
        ["gbF", "gbF52", "gbF529", "gbF5291"],
        ["gbF", "gbF52", "gbF529", "gbF5292"],
        ["gbF", "gbF52", "gbF529", "gbF5293"],
        ["gbF", "gbF52", "gbF529", "gbF5294"],
        ["gbF", "gbF52", "gbF529", "gbF5295"],
        ["gbF", "gbF52", "gbF529", "gbF5296"],
        ["gbF", "gbF52", "gbF529", "gbF5297"],
        ["gbF", "gbF52", "gbF529", "gbF5299"],
        ["gbG", "gbG53", "gbG531", "gbG5311"],
        ["gbG", "gbG53", "gbG531", "gbG5312"],
        ["gbG", "gbG53", "gbG531", "gbG5313"],
        ["gbG", "gbG53", "gbG532", "gbG5320"],
        ["gbG", "gbG53", "gbG533", "gbG5331"],
        ["gbG", "gbG53", "gbG533", "gbG5332"],
        ["gbG", "gbG53", "gbG533", "gbG5333"],
        ["gbG", "gbG53", "gbG533", "gbG5339"],
        ["gbG", "gbG54", "gbG541", "gbG5411"],
        ["gbG", "gbG54", "gbG541", "gbG5412"],
        ["gbG", "gbG54", "gbG541", "gbG5413"],
        ["gbG", "gbG54", "gbG541", "gbG5414"],
        ["gbG", "gbG54", "gbG541", "gbG5419"],
        ["gbG", "gbG54", "gbG542", "gbG5421"],
        ["gbG", "gbG54", "gbG542", "gbG5422"],
        ["gbG", "gbG54", "gbG542", "gbG5429"],
        ["gbG", "gbG54", "gbG543", "gbG5431"],
        ["gbG", "gbG54", "gbG543", "gbG5432"],
        ["gbG", "gbG54", "gbG543", "gbG5433"],
        ["gbG", "gbG54", "gbG543", "gbG5434"],
        ["gbG", "gbG54", "gbG543", "gbG5435"],
        ["gbG", "gbG54", "gbG543", "gbG5436"],
        ["gbG", "gbG54", "gbG543", "gbG5437"],
        ["gbG", "gbG54", "gbG543", "gbG5438"],
        ["gbG", "gbG54", "gbG543", "gbG5439"],
        ["gbG", "gbG54", "gbG544", "gbG5441"],
        ["gbG", "gbG54", "gbG544", "gbG5442"],
        ["gbG", "gbG54", "gbG544", "gbG5443"],
        ["gbG", "gbG54", "gbG544", "gbG5449"],
        ["gbG", "gbG55", "gbG551", "gbG5511"],
        ["gbG", "gbG55", "gbG551", "gbG5512"],
        ["gbG", "gbG55", "gbG551", "gbG5513"],
        ["gbG", "gbG55", "gbG552", "gbG5521"],
        ["gbG", "gbG55", "gbG552", "gbG5522"],
        ["gbG", "gbG55", "gbG552", "gbG5523"],
        ["gbG", "gbG55", "gbG553", "gbG5531"],
        ["gbG", "gbG55", "gbG553", "gbG5532"],
        ["gbG", "gbG55", "gbG553", "gbG5539"],
        ["gbG", "gbG56", "gbG561", "gbG5611"],
        ["gbG", "gbG56", "gbG561", "gbG5612"],
        ["gbG", "gbG56", "gbG562", "gbG5621"],
        ["gbG", "gbG56", "gbG562", "gbG5622"],
        ["gbG", "gbG56", "gbG562", "gbG5623"],
        ["gbG", "gbG56", "gbG562", "gbG5629"],
        ["gbG", "gbG56", "gbG563", "gbG5631"],
        ["gbG", "gbG56", "gbG563", "gbG5632"],
        ["gbG", "gbG56", "gbG563", "gbG5639"],
        ["gbG", "gbG57", "gbG571", "gbG5710"],
        ["gbG", "gbG57", "gbG572", "gbG5720"],
        ["gbG", "gbG58", "gbG581", "gbG5810"],
        ["gbG", "gbG58", "gbG582", "gbG5821"],
        ["gbG", "gbG58", "gbG582", "gbG5822"],
        ["gbG", "gbG58", "gbG582", "gbG5829"],
        ["gbG", "gbG59", "gbG591", "gbG5910"],
        ["gbG", "gbG59", "gbG592", "gbG5920"],
        ["gbG", "gbG59", "gbG593", "gbG5930"],
        ["gbG", "gbG59", "gbG594", "gbG5941"],
        ["gbG", "gbG59", "gbG594", "gbG5942"],
        ["gbG", "gbG59", "gbG594", "gbG5949"],
        ["gbG", "gbG59", "gbG595", "gbG5951"],
        ["gbG", "gbG59", "gbG595", "gbG5952"],
        ["gbG", "gbG59", "gbG595", "gbG5959"],
        ["gbG", "gbG59", "gbG596", "gbG5960"],
        ["gbG", "gbG59", "gbG599", "gbG5990"],
        ["gbG", "gbG60", "gbG601", "gbG6010"],
        ["gbG", "gbG60", "gbG602", "gbG6020"],
        ["gbG", "gbG60", "gbG609", "gbG6090"],
        ["gbH", "gbH61", "gbH611", "gbH6110"],
        ["gbH", "gbH61", "gbH612", "gbH6121"],
        ["gbH", "gbH61", "gbH612", "gbH6129"],
        ["gbH", "gbH61", "gbH613", "gbH6130"],
        ["gbH", "gbH61", "gbH614", "gbH6140"],
        ["gbH", "gbH61", "gbH619", "gbH6190"],
        ["gbH", "gbH62", "gbH621", "gbH6210"],
        ["gbH", "gbH62", "gbH622", "gbH6220"],
        ["gbH", "gbH62", "gbH623", "gbH6231"],
        ["gbH", "gbH62", "gbH623", "gbH6232"],
        ["gbH", "gbH62", "gbH623", "gbH6233"],
        ["gbH", "gbH62", "gbH623", "gbH6239"],
        ["gbH", "gbH62", "gbH624", "gbH6241"],
        ["gbH", "gbH62", "gbH624", "gbH6242"],
        ["gbH", "gbH62", "gbH629", "gbH6291"],
        ["gbH", "gbH62", "gbH629", "gbH6299"],
        ["gbI", "gbI63", "gbI631", "gbI6311"],
        ["gbI", "gbI63", "gbI631", "gbI6312"],
        ["gbI", "gbI63", "gbI631", "gbI6319"],
        ["gbI", "gbI63", "gbI632", "gbI6321"],
        ["gbI", "gbI63", "gbI632", "gbI6322"],
        ["gbI", "gbI63", "gbI633", "gbI6331"],
        ["gbI", "gbI63", "gbI633", "gbI6339"],
        ["gbI", "gbI64", "gbI641", "gbI6410"],
        ["gbI", "gbI64", "gbI642", "gbI6421"],
        ["gbI", "gbI64", "gbI642", "gbI6422"],
        ["gbI", "gbI64", "gbI642", "gbI6429"],
        ["gbI", "gbI64", "gbI643", "gbI6431"],
        ["gbI", "gbI64", "gbI643", "gbI6432"],
        ["gbI", "gbI64", "gbI643", "gbI6433"],
        ["gbI", "gbI64", "gbI643", "gbI6434"],
        ["gbI", "gbI64", "gbI643", "gbI6439"],
        ["gbI", "gbI64", "gbI644", "gbI6440"],
        ["gbI", "gbI64", "gbI645", "gbI6450"],
        ["gbI", "gbI64", "gbI649", "gbI6490"],
        ["gbI", "gbI65", "gbI651", "gbI6511"],
        ["gbI", "gbI65", "gbI651", "gbI6512"],
        ["gbI", "gbI65", "gbI651", "gbI6513"],
        ["gbI", "gbI65", "gbI651", "gbI6519"],
        ["gbI", "gbI65", "gbI652", "gbI6520"],
        ["gbI", "gbI65", "gbI653", "gbI6531"],
        ["gbI", "gbI65", "gbI653", "gbI6532"],
        ["gbI", "gbI65", "gbI654", "gbI6540"],
        ["gbI", "gbI65", "gbI655", "gbI6550"],
        ["gbI", "gbI65", "gbI656", "gbI6560"],
        ["gbI", "gbI65", "gbI657", "gbI6571"],
        ["gbI", "gbI65", "gbI657", "gbI6572"],
        ["gbI", "gbI65", "gbI657", "gbI6579"],
        ["gbI", "gbI65", "gbI659", "gbI6591"],
        ["gbI", "gbI65", "gbI659", "gbI6599"],
        ["gbJ", "gbJ66", "gbJ661", "gbJ6610"],
        ["gbJ", "gbJ66", "gbJ662", "gbJ6621"],
        ["gbJ", "gbJ66", "gbJ662", "gbJ6622"],
        ["gbJ", "gbJ66", "gbJ662", "gbJ6623"],
        ["gbJ", "gbJ66", "gbJ662", "gbJ6624"],
        ["gbJ", "gbJ66", "gbJ662", "gbJ6629"],
        ["gbJ", "gbJ66", "gbJ663", "gbJ6631"],
        ["gbJ", "gbJ66", "gbJ663", "gbJ6632"],
        ["gbJ", "gbJ66", "gbJ663", "gbJ6633"],
        ["gbJ", "gbJ66", "gbJ663", "gbJ6634"],
        ["gbJ", "gbJ66", "gbJ663", "gbJ6635"],
        ["gbJ", "gbJ66", "gbJ663", "gbJ6636"],
        ["gbJ", "gbJ66", "gbJ663", "gbJ6637"],
        ["gbJ", "gbJ66", "gbJ663", "gbJ6639"],
        ["gbJ", "gbJ66", "gbJ664", "gbJ6640"],
        ["gbJ", "gbJ66", "gbJ665", "gbJ6650"],
        ["gbJ", "gbJ67", "gbJ671", "gbJ6711"],
        ["gbJ", "gbJ67", "gbJ671", "gbJ6712"],
        ["gbJ", "gbJ67", "gbJ672", "gbJ6720"],
        ["gbJ", "gbJ67", "gbJ673", "gbJ6731"],
        ["gbJ", "gbJ67", "gbJ673", "gbJ6732"],
        ["gbJ", "gbJ67", "gbJ673", "gbJ6739"],
        ["gbJ", "gbJ67", "gbJ674", "gbJ6741"],
        ["gbJ", "gbJ67", "gbJ674", "gbJ6749"],
        ["gbJ", "gbJ67", "gbJ675", "gbJ6750"],
        ["gbJ", "gbJ67", "gbJ676", "gbJ6760"],
        ["gbJ", "gbJ67", "gbJ679", "gbJ6790"],
        ["gbJ", "gbJ68", "gbJ681", "gbJ6811"],
        ["gbJ", "gbJ68", "gbJ681", "gbJ6812"],
        ["gbJ", "gbJ68", "gbJ681", "gbJ6813"],
        ["gbJ", "gbJ68", "gbJ681", "gbJ6814"],
        ["gbJ", "gbJ68", "gbJ682", "gbJ6820"],
        ["gbJ", "gbJ68", "gbJ683", "gbJ6830"],
        ["gbJ", "gbJ68", "gbJ684", "gbJ6840"],
        ["gbJ", "gbJ68", "gbJ685", "gbJ6851"],
        ["gbJ", "gbJ68", "gbJ685", "gbJ6852"],
        ["gbJ", "gbJ68", "gbJ685", "gbJ6853"],
        ["gbJ", "gbJ68", "gbJ686", "gbJ6860"],
        ["gbJ", "gbJ68", "gbJ687", "gbJ6870"],
        ["gbJ", "gbJ68", "gbJ689", "gbJ6890"],
        ["gbJ", "gbJ69", "gbJ691", "gbJ6911"],
        ["gbJ", "gbJ69", "gbJ691", "gbJ6919"],
        ["gbJ", "gbJ69", "gbJ692", "gbJ6920"],
        ["gbJ", "gbJ69", "gbJ693", "gbJ6930"],
        ["gbJ", "gbJ69", "gbJ694", "gbJ6940"],
        ["gbJ", "gbJ69", "gbJ695", "gbJ6950"],
        ["gbJ", "gbJ69", "gbJ699", "gbJ6991"],
        ["gbJ", "gbJ69", "gbJ699", "gbJ6999"],
        ["gbK", "gbK70", "gbK701", "gbK7010"],
        ["gbK", "gbK70", "gbK702", "gbK7020"],
        ["gbK", "gbK70", "gbK703", "gbK7030"],
        ["gbK", "gbK70", "gbK704", "gbK7040"],
        ["gbK", "gbK70", "gbK709", "gbK7090"],
        ["gbL", "gbL71", "gbL711", "gbL7111"],
        ["gbL", "gbL71", "gbL711", "gbL7112"],
        ["gbL", "gbL71", "gbL711", "gbL7113"],
        ["gbL", "gbL71", "gbL711", "gbL7114"],
        ["gbL", "gbL71", "gbL711", "gbL7115"],
        ["gbL", "gbL71", "gbL711", "gbL7119"],
        ["gbL", "gbL71", "gbL712", "gbL7121"],
        ["gbL", "gbL71", "gbL712", "gbL7122"],
        ["gbL", "gbL71", "gbL712", "gbL7123"],
        ["gbL", "gbL71", "gbL712", "gbL7124"],
        ["gbL", "gbL71", "gbL712", "gbL7125"],
        ["gbL", "gbL71", "gbL712", "gbL7129"],
        ["gbL", "gbL71", "gbL713", "gbL7130"],
        ["gbL", "gbL72", "gbL721", "gbL7211"],
        ["gbL", "gbL72", "gbL721", "gbL7212"],
        ["gbL", "gbL72", "gbL721", "gbL7213"],
        ["gbL", "gbL72", "gbL721", "gbL7214"],
        ["gbL", "gbL72", "gbL721", "gbL7215"],
        ["gbL", "gbL72", "gbL721", "gbL7219"],
        ["gbL", "gbL72", "gbL722", "gbL7221"],
        ["gbL", "gbL72", "gbL722", "gbL7222"],
        ["gbL", "gbL72", "gbL722", "gbL7223"],
        ["gbL", "gbL72", "gbL722", "gbL7224"],
        ["gbL", "gbL72", "gbL722", "gbL7229"],
        ["gbL", "gbL72", "gbL723", "gbL7231"],
        ["gbL", "gbL72", "gbL723", "gbL7232"],
        ["gbL", "gbL72", "gbL723", "gbL7239"],
        ["gbL", "gbL72", "gbL724", "gbL7241"],
        ["gbL", "gbL72", "gbL724", "gbL7242"],
        ["gbL", "gbL72", "gbL724", "gbL7243"],
        ["gbL", "gbL72", "gbL724", "gbL7244"],
        ["gbL", "gbL72", "gbL724", "gbL7245"],
        ["gbL", "gbL72", "gbL724", "gbL7246"],
        ["gbL", "gbL72", "gbL724", "gbL7249"],
        ["gbL", "gbL72", "gbL725", "gbL7251"],
        ["gbL", "gbL72", "gbL725", "gbL7259"],
        ["gbL", "gbL72", "gbL726", "gbL7261"],
        ["gbL", "gbL72", "gbL726", "gbL7262"],
        ["gbL", "gbL72", "gbL726", "gbL7263"],
        ["gbL", "gbL72", "gbL726", "gbL7264"],
        ["gbL", "gbL72", "gbL726", "gbL7269"],
        ["gbL", "gbL72", "gbL727", "gbL7271"],
        ["gbL", "gbL72", "gbL727", "gbL7272"],
        ["gbL", "gbL72", "gbL727", "gbL7279"],
        ["gbL", "gbL72", "gbL728", "gbL7281"],
        ["gbL", "gbL72", "gbL728", "gbL7282"],
        ["gbL", "gbL72", "gbL728", "gbL7283"],
        ["gbL", "gbL72", "gbL728", "gbL7284"],
        ["gbL", "gbL72", "gbL728", "gbL7289"],
        ["gbL", "gbL72", "gbL729", "gbL7291"],
        ["gbL", "gbL72", "gbL729", "gbL7292"],
        ["gbL", "gbL72", "gbL729", "gbL7293"],
        ["gbL", "gbL72", "gbL729", "gbL7294"],
        ["gbL", "gbL72", "gbL729", "gbL7295"],
        ["gbL", "gbL72", "gbL729", "gbL7296"],
        ["gbL", "gbL72", "gbL729", "gbL7297"],
        ["gbL", "gbL72", "gbL729", "gbL7298"],
        ["gbL", "gbL72", "gbL729", "gbL7299"],
        ["gbM", "gbM73", "gbM731", "gbM7310"],
        ["gbM", "gbM73", "gbM732", "gbM7320"],
        ["gbM", "gbM73", "gbM733", "gbM7330"],
        ["gbM", "gbM73", "gbM734", "gbM7340"],
        ["gbM", "gbM73", "gbM735", "gbM7350"],
        ["gbM", "gbM74", "gbM741", "gbM7410"],
        ["gbM", "gbM74", "gbM742", "gbM7420"],
        ["gbM", "gbM74", "gbM743", "gbM7431"],
        ["gbM", "gbM74", "gbM743", "gbM7432"],
        ["gbM", "gbM74", "gbM743", "gbM7439"],
        ["gbM", "gbM74", "gbM744", "gbM7441"],
        ["gbM", "gbM74", "gbM744", "gbM7449"],
        ["gbM", "gbM74", "gbM745", "gbM7451"],
        ["gbM", "gbM74", "gbM745", "gbM7452"],
        ["gbM", "gbM74", "gbM745", "gbM7453"],
        ["gbM", "gbM74", "gbM745", "gbM7454"],
        ["gbM", "gbM74", "gbM745", "gbM7455"],
        ["gbM", "gbM74", "gbM745", "gbM7459"],
        ["gbM", "gbM74", "gbM746", "gbM7461"],
        ["gbM", "gbM74", "gbM746", "gbM7462"],
        ["gbM", "gbM74", "gbM746", "gbM7463"],
        ["gbM", "gbM74", "gbM747", "gbM7471"],
        ["gbM", "gbM74", "gbM747", "gbM7472"],
        ["gbM", "gbM74", "gbM747", "gbM7473"],
        ["gbM", "gbM74", "gbM747", "gbM7474"],
        ["gbM", "gbM74", "gbM747", "gbM7475"],
        ["gbM", "gbM74", "gbM748", "gbM7481"],
        ["gbM", "gbM74", "gbM748", "gbM7482"],
        ["gbM", "gbM74", "gbM748", "gbM7483"],
        ["gbM", "gbM74", "gbM748", "gbM7484"],
        ["gbM", "gbM74", "gbM748", "gbM7485"],
        ["gbM", "gbM74", "gbM748", "gbM7486"],
        ["gbM", "gbM74", "gbM749", "gbM7491"],
        ["gbM", "gbM74", "gbM749", "gbM7492"],
        ["gbM", "gbM74", "gbM749", "gbM7493"],
        ["gbM", "gbM74", "gbM749", "gbM7499"],
        ["gbM", "gbM75", "gbM751", "gbM7511"],
        ["gbM", "gbM75", "gbM751", "gbM7512"],
        ["gbM", "gbM75", "gbM751", "gbM7513"],
        ["gbM", "gbM75", "gbM751", "gbM7514"],
        ["gbM", "gbM75", "gbM751", "gbM7515"],
        ["gbM", "gbM75", "gbM751", "gbM7516"],
        ["gbM", "gbM75", "gbM751", "gbM7517"],
        ["gbM", "gbM75", "gbM751", "gbM7519"],
        ["gbM", "gbM75", "gbM752", "gbM7520"],
        ["gbM", "gbM75", "gbM753", "gbM7530"],
        ["gbM", "gbM75", "gbM754", "gbM7540"],
        ["gbM", "gbM75", "gbM759", "gbM7590"],
        ["gbN", "gbN76", "gbN761", "gbN7610"],
        ["gbN", "gbN76", "gbN762", "gbN7620"],
        ["gbN", "gbN76", "gbN763", "gbN7630"],
        ["gbN", "gbN76", "gbN764", "gbN7640"],
        ["gbN", "gbN76", "gbN769", "gbN7690"],
        ["gbN", "gbN77", "gbN771", "gbN7711"],
        ["gbN", "gbN77", "gbN771", "gbN7712"],
        ["gbN", "gbN77", "gbN771", "gbN7713"],
        ["gbN", "gbN77", "gbN771", "gbN7714"],
        ["gbN", "gbN77", "gbN771", "gbN7715"],
        ["gbN", "gbN77", "gbN771", "gbN7716"],
        ["gbN", "gbN77", "gbN771", "gbN7719"],
        ["gbN", "gbN77", "gbN772", "gbN7721"],
        ["gbN", "gbN77", "gbN772", "gbN7722"],
        ["gbN", "gbN77", "gbN772", "gbN7723"],
        ["gbN", "gbN77", "gbN772", "gbN7724"],
        ["gbN", "gbN77", "gbN772", "gbN7725"],
        ["gbN", "gbN77", "gbN772", "gbN7726"],
        ["gbN", "gbN77", "gbN772", "gbN7727"],
        ["gbN", "gbN77", "gbN772", "gbN7729"],
        ["gbN", "gbN78", "gbN781", "gbN7810"],
        ["gbN", "gbN78", "gbN782", "gbN7820"],
        ["gbN", "gbN78", "gbN783", "gbN7830"],
        ["gbN", "gbN78", "gbN784", "gbN7840"],
        ["gbN", "gbN78", "gbN785", "gbN7850"],
        ["gbN", "gbN78", "gbN786", "gbN7861"],
        ["gbN", "gbN78", "gbN786", "gbN7862"],
        ["gbN", "gbN78", "gbN786", "gbN7869"],
        ["gbN", "gbN79", "gbN791", "gbN7910"],
        ["gbN", "gbN79", "gbN792", "gbN7920"],
        ["gbN", "gbN79", "gbN793", "gbN7930"],
        ["gbN", "gbN79", "gbN794", "gbN7940"],
        ["gbN", "gbN79", "gbN799", "gbN7990"],
        ["gbO", "gbO80", "gbO801", "gbO8010"],
        ["gbO", "gbO80", "gbO802", "gbO8020"],
        ["gbO", "gbO80", "gbO803", "gbO8030"],
        ["gbO", "gbO80", "gbO804", "gbO8040"],
        ["gbO", "gbO80", "gbO805", "gbO8051"],
        ["gbO", "gbO80", "gbO805", "gbO8052"],
        ["gbO", "gbO80", "gbO805", "gbO8053"],
        ["gbO", "gbO80", "gbO806", "gbO8060"],
        ["gbO", "gbO80", "gbO807", "gbO8070"],
        ["gbO", "gbO80", "gbO808", "gbO8080"],
        ["gbO", "gbO80", "gbO809", "gbO8090"],
        ["gbO", "gbO81", "gbO811", "gbO8111"],
        ["gbO", "gbO81", "gbO811", "gbO8112"],
        ["gbO", "gbO81", "gbO811", "gbO8113"],
        ["gbO", "gbO81", "gbO811", "gbO8114"],
        ["gbO", "gbO81", "gbO812", "gbO8121"],
        ["gbO", "gbO81", "gbO812", "gbO8122"],
        ["gbO", "gbO81", "gbO812", "gbO8129"],
        ["gbO", "gbO81", "gbO813", "gbO8131"],
        ["gbO", "gbO81", "gbO813", "gbO8132"],
        ["gbO", "gbO81", "gbO819", "gbO8191"],
        ["gbO", "gbO81", "gbO819", "gbO8192"],
        ["gbO", "gbO81", "gbO819", "gbO8193"],
        ["gbO", "gbO81", "gbO819", "gbO8199"],
        ["gbO", "gbO82", "gbO821", "gbO8211"],
        ["gbO", "gbO82", "gbO821", "gbO8219"],
        ["gbO", "gbO82", "gbO822", "gbO8221"],
        ["gbO", "gbO82", "gbO822", "gbO8222"],
        ["gbO", "gbO82", "gbO822", "gbO8223"],
        ["gbO", "gbO82", "gbO822", "gbO8224"],
        ["gbO", "gbO82", "gbO822", "gbO8229"],
        ["gbO", "gbO82", "gbO829", "gbO8290"],
        ["gbP", "gbP83", "gbP831", "gbP8310"],
        ["gbP", "gbP83", "gbP832", "gbP8321"],
        ["gbP", "gbP83", "gbP832", "gbP8322"],
        ["gbP", "gbP83", "gbP833", "gbP8331"],
        ["gbP", "gbP83", "gbP833", "gbP8332"],
        ["gbP", "gbP83", "gbP833", "gbP8333"],
        ["gbP", "gbP83", "gbP833", "gbP8334"],
        ["gbP", "gbP83", "gbP833", "gbP8335"],
        ["gbP", "gbP83", "gbP833", "gbP8336"],
        ["gbP", "gbP83", "gbP834", "gbP8341"],
        ["gbP", "gbP83", "gbP834", "gbP8342"],
        ["gbP", "gbP83", "gbP835", "gbP8350"],
        ["gbP", "gbP83", "gbP839", "gbP8391"],
        ["gbP", "gbP83", "gbP839", "gbP8392"],
        ["gbP", "gbP83", "gbP839", "gbP8393"],
        ["gbP", "gbP83", "gbP839", "gbP8394"],
        ["gbP", "gbP83", "gbP839", "gbP8399"],
        ["gbQ", "gbQ84", "gbQ841", "gbQ8411"],
        ["gbQ", "gbQ84", "gbQ841", "gbQ8412"],
        ["gbQ", "gbQ84", "gbQ841", "gbQ8413"],
        ["gbQ", "gbQ84", "gbQ841", "gbQ8414"],
        ["gbQ", "gbQ84", "gbQ841", "gbQ8415"],
        ["gbQ", "gbQ84", "gbQ841", "gbQ8416"],
        ["gbQ", "gbQ84", "gbQ842", "gbQ8421"],
        ["gbQ", "gbQ84", "gbQ842", "gbQ8422"],
        ["gbQ", "gbQ84", "gbQ842", "gbQ8423"],
        ["gbQ", "gbQ84", "gbQ842", "gbQ8424"],
        ["gbQ", "gbQ84", "gbQ842", "gbQ8425"],
        ["gbQ", "gbQ84", "gbQ843", "gbQ8431"],
        ["gbQ", "gbQ84", "gbQ843", "gbQ8432"],
        ["gbQ", "gbQ84", "gbQ843", "gbQ8433"],
        ["gbQ", "gbQ84", "gbQ843", "gbQ8434"],
        ["gbQ", "gbQ84", "gbQ843", "gbQ8435"],
        ["gbQ", "gbQ84", "gbQ843", "gbQ8436"],
        ["gbQ", "gbQ84", "gbQ849", "gbQ8491"],
        ["gbQ", "gbQ84", "gbQ849", "gbQ8492"],
        ["gbQ", "gbQ84", "gbQ849", "gbQ8499"],
        ["gbQ", "gbQ85", "gbQ851", "gbQ8511"],
        ["gbQ", "gbQ85", "gbQ851", "gbQ8512"],
        ["gbQ", "gbQ85", "gbQ851", "gbQ8513"],
        ["gbQ", "gbQ85", "gbQ851", "gbQ8514"],
        ["gbQ", "gbQ85", "gbQ851", "gbQ8515"],
        ["gbQ", "gbQ85", "gbQ851", "gbQ8516"],
        ["gbQ", "gbQ85", "gbQ851", "gbQ8519"],
        ["gbQ", "gbQ85", "gbQ852", "gbQ8521"],
        ["gbQ", "gbQ85", "gbQ852", "gbQ8522"],
        ["gbQ", "gbQ85", "gbQ852", "gbQ8529"],
        ["gbR", "gbR86", "gbR861", "gbR8610"],
        ["gbR", "gbR86", "gbR862", "gbR8621"],
        ["gbR", "gbR86", "gbR862", "gbR8622"],
        ["gbR", "gbR86", "gbR862", "gbR8623"],
        ["gbR", "gbR86", "gbR862", "gbR8624"],
        ["gbR", "gbR86", "gbR862", "gbR8625"],
        ["gbR", "gbR86", "gbR862", "gbR8626"],
        ["gbR", "gbR86", "gbR862", "gbR8629"],
        ["gbR", "gbR87", "gbR871", "gbR8710"],
        ["gbR", "gbR87", "gbR872", "gbR8720"],
        ["gbR", "gbR87", "gbR873", "gbR8730"],
        ["gbR", "gbR87", "gbR874", "gbR8740"],
        ["gbR", "gbR87", "gbR875", "gbR8750"],
        ["gbR", "gbR87", "gbR876", "gbR8760"],
        ["gbR", "gbR87", "gbR877", "gbR8770"],
        ["gbR", "gbR88", "gbR881", "gbR8810"],
        ["gbR", "gbR88", "gbR882", "gbR8820"],
        ["gbR", "gbR88", "gbR883", "gbR8831"],
        ["gbR", "gbR88", "gbR883", "gbR8832"],
        ["gbR", "gbR88", "gbR884", "gbR8840"],
        ["gbR", "gbR88", "gbR885", "gbR8850"],
        ["gbR", "gbR88", "gbR886", "gbR8860"],
        ["gbR", "gbR88", "gbR887", "gbR8870"],
        ["gbR", "gbR88", "gbR889", "gbR8890"],
        ["gbR", "gbR89", "gbR891", "gbR8911"],
        ["gbR", "gbR89", "gbR891", "gbR8912"],
        ["gbR", "gbR89", "gbR891", "gbR8919"],
        ["gbR", "gbR89", "gbR892", "gbR8921"],
        ["gbR", "gbR89", "gbR892", "gbR8929"],
        ["gbR", "gbR89", "gbR893", "gbR8930"],
        ["gbR", "gbR89", "gbR899", "gbR8991"],
        ["gbR", "gbR89", "gbR899", "gbR8992"],
        ["gbR", "gbR89", "gbR899", "gbR8999"],
        ["gbR", "gbR90", "gbR901", "gbR9011"],
        ["gbR", "gbR90", "gbR901", "gbR9012"],
        ["gbR", "gbR90", "gbR901", "gbR9013"],
        ["gbR", "gbR90", "gbR901", "gbR9019"],
        ["gbR", "gbR90", "gbR902", "gbR9020"],
        ["gbR", "gbR90", "gbR903", "gbR9030"],
        ["gbR", "gbR90", "gbR904", "gbR9041"],
        ["gbR", "gbR90", "gbR904", "gbR9042"],
        ["gbR", "gbR90", "gbR904", "gbR9049"],
        ["gbR", "gbR90", "gbR905", "gbR9051"],
        ["gbR", "gbR90", "gbR905", "gbR9052"],
        ["gbR", "gbR90", "gbR905", "gbR9053"],
        ["gbR", "gbR90", "gbR905", "gbR9054"],
        ["gbR", "gbR90", "gbR905", "gbR9059"],
        ["gbR", "gbR90", "gbR909", "gbR9090"],
      ],
      //   固定
      optionsBtn: [
        ["gbA", "gbA01", "gbA011", "gbA0111"],
        ["gbA", "gbA01", "gbA011", "gbA0112"],
        ["gbA", "gbA01", "gbA011", "gbA0113"],
        ["gbA", "gbA01", "gbA011", "gbA0119"],
        ["gbA", "gbA01", "gbA012", "gbA0121"],
        ["gbA", "gbA01", "gbA012", "gbA0122"],
        ["gbA", "gbA01", "gbA012", "gbA0123"],
        ["gbA", "gbA01", "gbA013", "gbA0131"],
        ["gbA", "gbA01", "gbA013", "gbA0132"],
        ["gbA", "gbA01", "gbA013", "gbA0133"],
        ["gbA", "gbA01", "gbA013", "gbA0134"],
        ["gbA", "gbA01", "gbA014", "gbA0141"],
        ["gbA", "gbA01", "gbA014", "gbA0142"],
        ["gbA", "gbA01", "gbA014", "gbA0143"],
        ["gbA", "gbA01", "gbA014", "gbA0149"],
        ["gbA", "gbA01", "gbA015", "gbA0151"],
        ["gbA", "gbA01", "gbA015", "gbA0152"],
        ["gbA", "gbA01", "gbA015", "gbA0153"],
        ["gbA", "gbA01", "gbA015", "gbA0154"],
        ["gbA", "gbA01", "gbA015", "gbA0159"],
        ["gbA", "gbA01", "gbA016", "gbA0161"],
        ["gbA", "gbA01", "gbA016", "gbA0162"],
        ["gbA", "gbA01", "gbA016", "gbA0163"],
        ["gbA", "gbA01", "gbA016", "gbA0164"],
        ["gbA", "gbA01", "gbA016", "gbA0169"],
        ["gbA", "gbA01", "gbA017", "gbA0171"],
        ["gbA", "gbA01", "gbA017", "gbA0179"],
        ["gbA", "gbA01", "gbA018", "gbA0181"],
        ["gbA", "gbA01", "gbA018", "gbA0182"],
        ["gbA", "gbA01", "gbA019", "gbA0190"],
        ["gbA", "gbA02", "gbA021", "gbA0211"],
        ["gbA", "gbA02", "gbA021", "gbA0212"],
        ["gbA", "gbA02", "gbA022", "gbA0220"],
        ["gbA", "gbA02", "gbA023", "gbA0231"],
        ["gbA", "gbA02", "gbA023", "gbA0232"],
        ["gbA", "gbA02", "gbA024", "gbA0241"],
        ["gbA", "gbA02", "gbA024", "gbA0242"],
        ["gbA", "gbA02", "gbA025", "gbA0251"],
        ["gbA", "gbA02", "gbA025", "gbA0252"],
        ["gbA", "gbA03", "gbA031", "gbA0311"],
        ["gbA", "gbA03", "gbA031", "gbA0312"],
        ["gbA", "gbA03", "gbA031", "gbA0313"],
        ["gbA", "gbA03", "gbA031", "gbA0314"],
        ["gbA", "gbA03", "gbA031", "gbA0315"],
        ["gbA", "gbA03", "gbA031", "gbA0319"],
        ["gbA", "gbA03", "gbA032", "gbA0321"],
        ["gbA", "gbA03", "gbA032", "gbA0322"],
        ["gbA", "gbA03", "gbA032", "gbA0323"],
        ["gbA", "gbA03", "gbA032", "gbA0329"],
        ["gbA", "gbA03", "gbA033", "gbA0330"],
        ["gbA", "gbA03", "gbA039", "gbA0391"],
        ["gbA", "gbA03", "gbA039", "gbA0392"],
        ["gbA", "gbA03", "gbA039", "gbA0399"],
        ["gbA", "gbA04", "gbA041", "gbA0411"],
        ["gbA", "gbA04", "gbA041", "gbA0412"],
        ["gbA", "gbA04", "gbA042", "gbA0421"],
        ["gbA", "gbA04", "gbA042", "gbA0422"],
        ["gbA", "gbA05", "gbA051", "gbA0511"],
        ["gbA", "gbA05", "gbA051", "gbA0512"],
        ["gbA", "gbA05", "gbA051", "gbA0513"],
        ["gbA", "gbA05", "gbA051", "gbA0514"],
        ["gbA", "gbA05", "gbA051", "gbA0515"],
        ["gbA", "gbA05", "gbA051", "gbA0519"],
        ["gbA", "gbA05", "gbA052", "gbA0521"],
        ["gbA", "gbA05", "gbA052", "gbA0522"],
        ["gbA", "gbA05", "gbA052", "gbA0523"],
        ["gbA", "gbA05", "gbA052", "gbA0529"],
        ["gbA", "gbA05", "gbA053", "gbA0531"],
        ["gbA", "gbA05", "gbA053", "gbA0532"],
        ["gbA", "gbA05", "gbA053", "gbA0539"],
        ["gbA", "gbA05", "gbA054", "gbA0541"],
        ["gbA", "gbA05", "gbA054", "gbA0549"],
        ["gbB", "gbB06", "gbB061", "gbB0610"],
        ["gbB", "gbB06", "gbB062", "gbB0620"],
        ["gbB", "gbB06", "gbB069", "gbB0690"],
        ["gbB", "gbB07", "gbB071", "gbB0711"],
        ["gbB", "gbB07", "gbB071", "gbB0712"],
        ["gbB", "gbB07", "gbB072", "gbB0721"],
        ["gbB", "gbB07", "gbB072", "gbB0722"],
        ["gbB", "gbB08", "gbB081", "gbB0810"],
        ["gbB", "gbB08", "gbB082", "gbB0820"],
        ["gbB", "gbB08", "gbB089", "gbB0890"],
        ["gbB", "gbB09", "gbB091", "gbB0911"],
        ["gbB", "gbB09", "gbB091", "gbB0912"],
        ["gbB", "gbB09", "gbB091", "gbB0913"],
        ["gbB", "gbB09", "gbB091", "gbB0914"],
        ["gbB", "gbB09", "gbB091", "gbB0915"],
        ["gbB", "gbB09", "gbB091", "gbB0916"],
        ["gbB", "gbB09", "gbB091", "gbB0917"],
        ["gbB", "gbB09", "gbB091", "gbB0919"],
        ["gbB", "gbB09", "gbB092", "gbB0921"],
        ["gbB", "gbB09", "gbB092", "gbB0922"],
        ["gbB", "gbB09", "gbB092", "gbB0929"],
        ["gbB", "gbB09", "gbB093", "gbB0931"],
        ["gbB", "gbB09", "gbB093", "gbB0932"],
        ["gbB", "gbB09", "gbB093", "gbB0933"],
        ["gbB", "gbB09", "gbB093", "gbB0939"],
        ["gbB", "gbB10", "gbB101", "gbB1011"],
        ["gbB", "gbB10", "gbB101", "gbB1012"],
        ["gbB", "gbB10", "gbB101", "gbB1013"],
        ["gbB", "gbB10", "gbB101", "gbB1019"],
        ["gbB", "gbB10", "gbB102", "gbB1020"],
        ["gbB", "gbB10", "gbB103", "gbB1030"],
        ["gbB", "gbB10", "gbB109", "gbB1091"],
        ["gbB", "gbB10", "gbB109", "gbB1092"],
        ["gbB", "gbB10", "gbB109", "gbB1093"],
        ["gbB", "gbB10", "gbB109", "gbB1099"],
        ["gbB", "gbB11", "gbB111", "gbB1110"],
        ["gbB", "gbB11", "gbB112", "gbB1120"],
        ["gbB", "gbB11", "gbB119", "gbB1190"],
        ["gbB", "gbB12", "gbB120", "gbB1200"],
        ["gbC", "gbC13", "gbC131", "gbC1311"],
        ["gbC", "gbC13", "gbC131", "gbC1312"],
        ["gbC", "gbC13", "gbC131", "gbC1313"],
        ["gbC", "gbC13", "gbC131", "gbC1314"],
        ["gbC", "gbC13", "gbC131", "gbC1319"],
        ["gbC", "gbC13", "gbC132", "gbC1321"],
        ["gbC", "gbC13", "gbC132", "gbC1329"],
        ["gbC", "gbC13", "gbC133", "gbC1331"],
        ["gbC", "gbC13", "gbC133", "gbC1332"],
        ["gbC", "gbC13", "gbC134", "gbC1340"],
        ["gbC", "gbC13", "gbC135", "gbC1351"],
        ["gbC", "gbC13", "gbC135", "gbC1352"],
        ["gbC", "gbC13", "gbC135", "gbC1353"],
        ["gbC", "gbC13", "gbC136", "gbC1361"],
        ["gbC", "gbC13", "gbC136", "gbC1362"],
        ["gbC", "gbC13", "gbC136", "gbC1363"],
        ["gbC", "gbC13", "gbC136", "gbC1369"],
        ["gbC", "gbC13", "gbC137", "gbC1371"],
        ["gbC", "gbC13", "gbC137", "gbC1372"],
        ["gbC", "gbC13", "gbC137", "gbC1373"],
        ["gbC", "gbC13", "gbC139", "gbC1391"],
        ["gbC", "gbC13", "gbC139", "gbC1392"],
        ["gbC", "gbC13", "gbC139", "gbC1393"],
        ["gbC", "gbC13", "gbC139", "gbC1399"],
        ["gbC", "gbC14", "gbC141", "gbC1411"],
        ["gbC", "gbC14", "gbC141", "gbC1419"],
        ["gbC", "gbC14", "gbC142", "gbC1421"],
        ["gbC", "gbC14", "gbC142", "gbC1422"],
        ["gbC", "gbC14", "gbC143", "gbC1431"],
        ["gbC", "gbC14", "gbC143", "gbC1432"],
        ["gbC", "gbC14", "gbC143", "gbC1433"],
        ["gbC", "gbC14", "gbC143", "gbC1439"],
        ["gbC", "gbC14", "gbC144", "gbC1441"],
        ["gbC", "gbC14", "gbC144", "gbC1442"],
        ["gbC", "gbC14", "gbC144", "gbC1449"],
        ["gbC", "gbC14", "gbC145", "gbC1451"],
        ["gbC", "gbC14", "gbC145", "gbC1452"],
        ["gbC", "gbC14", "gbC145", "gbC1453"],
        ["gbC", "gbC14", "gbC145", "gbC1459"],
        ["gbC", "gbC14", "gbC146", "gbC1461"],
        ["gbC", "gbC14", "gbC146", "gbC1462"],
        ["gbC", "gbC14", "gbC146", "gbC1469"],
        ["gbC", "gbC14", "gbC149", "gbC1491"],
        ["gbC", "gbC14", "gbC149", "gbC1492"],
        ["gbC", "gbC14", "gbC149", "gbC1493"],
        ["gbC", "gbC14", "gbC149", "gbC1494"],
        ["gbC", "gbC14", "gbC149", "gbC1495"],
        ["gbC", "gbC14", "gbC149", "gbC1499"],
        ["gbC", "gbC15", "gbC151", "gbC1511"],
        ["gbC", "gbC15", "gbC151", "gbC1512"],
        ["gbC", "gbC15", "gbC151", "gbC1513"],
        ["gbC", "gbC15", "gbC151", "gbC1514"],
        ["gbC", "gbC15", "gbC151", "gbC1515"],
        ["gbC", "gbC15", "gbC151", "gbC1519"],
        ["gbC", "gbC15", "gbC152", "gbC1521"],
        ["gbC", "gbC15", "gbC152", "gbC1522"],
        ["gbC", "gbC15", "gbC152", "gbC1523"],
        ["gbC", "gbC15", "gbC152", "gbC1524"],
        ["gbC", "gbC15", "gbC152", "gbC1525"],
        ["gbC", "gbC15", "gbC152", "gbC1529"],
        ["gbC", "gbC15", "gbC153", "gbC1530"],
        ["gbC", "gbC16", "gbC161", "gbC1610"],
        ["gbC", "gbC16", "gbC162", "gbC1620"],
        ["gbC", "gbC16", "gbC169", "gbC1690"],
        ["gbC", "gbC17", "gbC171", "gbC1711"],
        ["gbC", "gbC17", "gbC171", "gbC1712"],
        ["gbC", "gbC17", "gbC171", "gbC1713"],
        ["gbC", "gbC17", "gbC172", "gbC1721"],
        ["gbC", "gbC17", "gbC172", "gbC1722"],
        ["gbC", "gbC17", "gbC172", "gbC1723"],
        ["gbC", "gbC17", "gbC173", "gbC1731"],
        ["gbC", "gbC17", "gbC173", "gbC1732"],
        ["gbC", "gbC17", "gbC173", "gbC1733"],
        ["gbC", "gbC17", "gbC174", "gbC1741"],
        ["gbC", "gbC17", "gbC174", "gbC1742"],
        ["gbC", "gbC17", "gbC174", "gbC1743"],
        ["gbC", "gbC17", "gbC175", "gbC1751"],
        ["gbC", "gbC17", "gbC175", "gbC1752"],
        ["gbC", "gbC17", "gbC176", "gbC1761"],
        ["gbC", "gbC17", "gbC176", "gbC1762"],
        ["gbC", "gbC17", "gbC176", "gbC1763"],
        ["gbC", "gbC17", "gbC177", "gbC1771"],
        ["gbC", "gbC17", "gbC177", "gbC1772"],
        ["gbC", "gbC17", "gbC177", "gbC1773"],
        ["gbC", "gbC17", "gbC177", "gbC1779"],
        ["gbC", "gbC17", "gbC178", "gbC1781"],
        ["gbC", "gbC17", "gbC178", "gbC1782"],
        ["gbC", "gbC17", "gbC178", "gbC1783"],
        ["gbC", "gbC17", "gbC178", "gbC1784"],
        ["gbC", "gbC17", "gbC178", "gbC1789"],
        ["gbC", "gbC18", "gbC181", "gbC1811"],
        ["gbC", "gbC18", "gbC181", "gbC1819"],
        ["gbC", "gbC18", "gbC182", "gbC1821"],
        ["gbC", "gbC18", "gbC182", "gbC1829"],
        ["gbC", "gbC18", "gbC183", "gbC1830"],
        ["gbC", "gbC19", "gbC191", "gbC1910"],
        ["gbC", "gbC19", "gbC192", "gbC1921"],
        ["gbC", "gbC19", "gbC192", "gbC1922"],
        ["gbC", "gbC19", "gbC192", "gbC1923"],
        ["gbC", "gbC19", "gbC192", "gbC1929"],
        ["gbC", "gbC19", "gbC193", "gbC1931"],
        ["gbC", "gbC19", "gbC193", "gbC1932"],
        ["gbC", "gbC19", "gbC193", "gbC1939"],
        ["gbC", "gbC19", "gbC194", "gbC1941"],
        ["gbC", "gbC19", "gbC194", "gbC1942"],
        ["gbC", "gbC19", "gbC195", "gbC1951"],
        ["gbC", "gbC19", "gbC195", "gbC1952"],
        ["gbC", "gbC19", "gbC195", "gbC1953"],
        ["gbC", "gbC19", "gbC195", "gbC1954"],
        ["gbC", "gbC19", "gbC195", "gbC1959"],
        ["gbC", "gbC20", "gbC201", "gbC2011"],
        ["gbC", "gbC20", "gbC201", "gbC2012"],
        ["gbC", "gbC20", "gbC201", "gbC2013"],
        ["gbC", "gbC20", "gbC201", "gbC2019"],
        ["gbC", "gbC20", "gbC202", "gbC2021"],
        ["gbC", "gbC20", "gbC202", "gbC2022"],
        ["gbC", "gbC20", "gbC202", "gbC2023"],
        ["gbC", "gbC20", "gbC202", "gbC2029"],
        ["gbC", "gbC20", "gbC203", "gbC2031"],
        ["gbC", "gbC20", "gbC203", "gbC2032"],
        ["gbC", "gbC20", "gbC203", "gbC2033"],
        ["gbC", "gbC20", "gbC203", "gbC2034"],
        ["gbC", "gbC20", "gbC203", "gbC2035"],
        ["gbC", "gbC20", "gbC203", "gbC2039"],
        ["gbC", "gbC20", "gbC204", "gbC2041"],
        ["gbC", "gbC20", "gbC204", "gbC2042"],
        ["gbC", "gbC20", "gbC204", "gbC2043"],
        ["gbC", "gbC20", "gbC204", "gbC2049"],
        ["gbC", "gbC21", "gbC211", "gbC2110"],
        ["gbC", "gbC21", "gbC212", "gbC2120"],
        ["gbC", "gbC21", "gbC213", "gbC2130"],
        ["gbC", "gbC21", "gbC214", "gbC2140"],
        ["gbC", "gbC21", "gbC219", "gbC2190"],
        ["gbC", "gbC22", "gbC221", "gbC2211"],
        ["gbC", "gbC22", "gbC221", "gbC2212"],
        ["gbC", "gbC22", "gbC222", "gbC2221"],
        ["gbC", "gbC22", "gbC222", "gbC2222"],
        ["gbC", "gbC22", "gbC222", "gbC2223"],
        ["gbC", "gbC22", "gbC223", "gbC2231"],
        ["gbC", "gbC22", "gbC223", "gbC2239"],
        ["gbC", "gbC23", "gbC231", "gbC2311"],
        ["gbC", "gbC23", "gbC231", "gbC2312"],
        ["gbC", "gbC23", "gbC231", "gbC2319"],
        ["gbC", "gbC23", "gbC232", "gbC2320"],
        ["gbC", "gbC23", "gbC233", "gbC2330"],
        ["gbC", "gbC24", "gbC241", "gbC2411"],
        ["gbC", "gbC24", "gbC241", "gbC2412"],
        ["gbC", "gbC24", "gbC241", "gbC2413"],
        ["gbC", "gbC24", "gbC241", "gbC2414"],
        ["gbC", "gbC24", "gbC241", "gbC2419"],
        ["gbC", "gbC24", "gbC242", "gbC2421"],
        ["gbC", "gbC24", "gbC242", "gbC2422"],
        ["gbC", "gbC24", "gbC242", "gbC2423"],
        ["gbC", "gbC24", "gbC242", "gbC2429"],
        ["gbC", "gbC24", "gbC243", "gbC2431"],
        ["gbC", "gbC24", "gbC243", "gbC2432"],
        ["gbC", "gbC24", "gbC243", "gbC2433"],
        ["gbC", "gbC24", "gbC243", "gbC2434"],
        ["gbC", "gbC24", "gbC243", "gbC2435"],
        ["gbC", "gbC24", "gbC243", "gbC2436"],
        ["gbC", "gbC24", "gbC243", "gbC2437"],
        ["gbC", "gbC24", "gbC243", "gbC2438"],
        ["gbC", "gbC24", "gbC243", "gbC2439"],
        ["gbC", "gbC24", "gbC244", "gbC2441"],
        ["gbC", "gbC24", "gbC244", "gbC2442"],
        ["gbC", "gbC24", "gbC244", "gbC2443"],
        ["gbC", "gbC24", "gbC244", "gbC2444"],
        ["gbC", "gbC24", "gbC244", "gbC2449"],
        ["gbC", "gbC24", "gbC245", "gbC2451"],
        ["gbC", "gbC24", "gbC245", "gbC2452"],
        ["gbC", "gbC24", "gbC245", "gbC2453"],
        ["gbC", "gbC24", "gbC245", "gbC2454"],
        ["gbC", "gbC24", "gbC245", "gbC2455"],
        ["gbC", "gbC24", "gbC245", "gbC2456"],
        ["gbC", "gbC24", "gbC245", "gbC2459"],
        ["gbC", "gbC24", "gbC246", "gbC2461"],
        ["gbC", "gbC24", "gbC246", "gbC2462"],
        ["gbC", "gbC24", "gbC246", "gbC2469"],
        ["gbC", "gbC25", "gbC251", "gbC2511"],
        ["gbC", "gbC25", "gbC251", "gbC2519"],
        ["gbC", "gbC25", "gbC252", "gbC2521"],
        ["gbC", "gbC25", "gbC252", "gbC2522"],
        ["gbC", "gbC25", "gbC252", "gbC2523"],
        ["gbC", "gbC25", "gbC252", "gbC2524"],
        ["gbC", "gbC25", "gbC252", "gbC2529"],
        ["gbC", "gbC25", "gbC253", "gbC2530"],
        ["gbC", "gbC25", "gbC254", "gbC2541"],
        ["gbC", "gbC25", "gbC254", "gbC2542"],
        ["gbC", "gbC26", "gbC261", "gbC2611"],
        ["gbC", "gbC26", "gbC261", "gbC2612"],
        ["gbC", "gbC26", "gbC261", "gbC2613"],
        ["gbC", "gbC26", "gbC261", "gbC2614"],
        ["gbC", "gbC26", "gbC261", "gbC2619"],
        ["gbC", "gbC26", "gbC262", "gbC2621"],
        ["gbC", "gbC26", "gbC262", "gbC2622"],
        ["gbC", "gbC26", "gbC262", "gbC2623"],
        ["gbC", "gbC26", "gbC262", "gbC2624"],
        ["gbC", "gbC26", "gbC262", "gbC2625"],
        ["gbC", "gbC26", "gbC262", "gbC2629"],
        ["gbC", "gbC26", "gbC263", "gbC2631"],
        ["gbC", "gbC26", "gbC263", "gbC2632"],
        ["gbC", "gbC26", "gbC264", "gbC2641"],
        ["gbC", "gbC26", "gbC264", "gbC2642"],
        ["gbC", "gbC26", "gbC264", "gbC2643"],
        ["gbC", "gbC26", "gbC264", "gbC2644"],
        ["gbC", "gbC26", "gbC264", "gbC2645"],
        ["gbC", "gbC26", "gbC264", "gbC2646"],
        ["gbC", "gbC26", "gbC265", "gbC2651"],
        ["gbC", "gbC26", "gbC265", "gbC2652"],
        ["gbC", "gbC26", "gbC265", "gbC2653"],
        ["gbC", "gbC26", "gbC265", "gbC2659"],
        ["gbC", "gbC26", "gbC266", "gbC2661"],
        ["gbC", "gbC26", "gbC266", "gbC2662"],
        ["gbC", "gbC26", "gbC266", "gbC2663"],
        ["gbC", "gbC26", "gbC266", "gbC2664"],
        ["gbC", "gbC26", "gbC266", "gbC2665"],
        ["gbC", "gbC26", "gbC266", "gbC2666"],
        ["gbC", "gbC26", "gbC266", "gbC2667"],
        ["gbC", "gbC26", "gbC266", "gbC2669"],
        ["gbC", "gbC26", "gbC267", "gbC2671"],
        ["gbC", "gbC26", "gbC267", "gbC2672"],
        ["gbC", "gbC26", "gbC268", "gbC2681"],
        ["gbC", "gbC26", "gbC268", "gbC2682"],
        ["gbC", "gbC26", "gbC268", "gbC2683"],
        ["gbC", "gbC26", "gbC268", "gbC2684"],
        ["gbC", "gbC26", "gbC268", "gbC2689"],
        ["gbC", "gbC27", "gbC271", "gbC2710"],
        ["gbC", "gbC27", "gbC272", "gbC2720"],
        ["gbC", "gbC27", "gbC273", "gbC2730"],
        ["gbC", "gbC27", "gbC274", "gbC2740"],
        ["gbC", "gbC27", "gbC275", "gbC2750"],
        ["gbC", "gbC27", "gbC276", "gbC2761"],
        ["gbC", "gbC27", "gbC276", "gbC2762"],
        ["gbC", "gbC27", "gbC277", "gbC2770"],
        ["gbC", "gbC27", "gbC278", "gbC2780"],
        ["gbC", "gbC28", "gbC281", "gbC2811"],
        ["gbC", "gbC28", "gbC281", "gbC2812"],
        ["gbC", "gbC28", "gbC282", "gbC2821"],
        ["gbC", "gbC28", "gbC282", "gbC2822"],
        ["gbC", "gbC28", "gbC282", "gbC2823"],
        ["gbC", "gbC28", "gbC282", "gbC2824"],
        ["gbC", "gbC28", "gbC282", "gbC2825"],
        ["gbC", "gbC28", "gbC282", "gbC2826"],
        ["gbC", "gbC28", "gbC282", "gbC2829"],
        ["gbC", "gbC28", "gbC283", "gbC2831"],
        ["gbC", "gbC28", "gbC283", "gbC2832"],
        ["gbC", "gbC29", "gbC291", "gbC2911"],
        ["gbC", "gbC29", "gbC291", "gbC2912"],
        ["gbC", "gbC29", "gbC291", "gbC2913"],
        ["gbC", "gbC29", "gbC291", "gbC2914"],
        ["gbC", "gbC29", "gbC291", "gbC2915"],
        ["gbC", "gbC29", "gbC291", "gbC2916"],
        ["gbC", "gbC29", "gbC291", "gbC2919"],
        ["gbC", "gbC29", "gbC292", "gbC2921"],
        ["gbC", "gbC29", "gbC292", "gbC2922"],
        ["gbC", "gbC29", "gbC292", "gbC2923"],
        ["gbC", "gbC29", "gbC292", "gbC2924"],
        ["gbC", "gbC29", "gbC292", "gbC2925"],
        ["gbC", "gbC29", "gbC292", "gbC2926"],
        ["gbC", "gbC29", "gbC292", "gbC2927"],
        ["gbC", "gbC29", "gbC292", "gbC2928"],
        ["gbC", "gbC29", "gbC292", "gbC2929"],
        ["gbC", "gbC30", "gbC301", "gbC3011"],
        ["gbC", "gbC30", "gbC301", "gbC3012"],
        ["gbC", "gbC30", "gbC302", "gbC3021"],
        ["gbC", "gbC30", "gbC302", "gbC3022"],
        ["gbC", "gbC30", "gbC302", "gbC3023"],
        ["gbC", "gbC30", "gbC302", "gbC3024"],
        ["gbC", "gbC30", "gbC302", "gbC3029"],
        ["gbC", "gbC30", "gbC303", "gbC3031"],
        ["gbC", "gbC30", "gbC303", "gbC3032"],
        ["gbC", "gbC30", "gbC303", "gbC3033"],
        ["gbC", "gbC30", "gbC303", "gbC3034"],
        ["gbC", "gbC30", "gbC303", "gbC3039"],
        ["gbC", "gbC30", "gbC304", "gbC3041"],
        ["gbC", "gbC30", "gbC304", "gbC3042"],
        ["gbC", "gbC30", "gbC304", "gbC3049"],
        ["gbC", "gbC30", "gbC305", "gbC3051"],
        ["gbC", "gbC30", "gbC305", "gbC3052"],
        ["gbC", "gbC30", "gbC305", "gbC3053"],
        ["gbC", "gbC30", "gbC305", "gbC3054"],
        ["gbC", "gbC30", "gbC305", "gbC3055"],
        ["gbC", "gbC30", "gbC305", "gbC3056"],
        ["gbC", "gbC30", "gbC305", "gbC3057"],
        ["gbC", "gbC30", "gbC305", "gbC3059"],
        ["gbC", "gbC30", "gbC306", "gbC3061"],
        ["gbC", "gbC30", "gbC306", "gbC3062"],
        ["gbC", "gbC30", "gbC307", "gbC3071"],
        ["gbC", "gbC30", "gbC307", "gbC3072"],
        ["gbC", "gbC30", "gbC307", "gbC3073"],
        ["gbC", "gbC30", "gbC307", "gbC3074"],
        ["gbC", "gbC30", "gbC307", "gbC3075"],
        ["gbC", "gbC30", "gbC307", "gbC3076"],
        ["gbC", "gbC30", "gbC307", "gbC3079"],
        ["gbC", "gbC30", "gbC308", "gbC3081"],
        ["gbC", "gbC30", "gbC308", "gbC3082"],
        ["gbC", "gbC30", "gbC308", "gbC3089"],
        ["gbC", "gbC30", "gbC309", "gbC3091"],
        ["gbC", "gbC30", "gbC309", "gbC3099"],
        ["gbC", "gbC31", "gbC311", "gbC3110"],
        ["gbC", "gbC31", "gbC312", "gbC3120"],
        ["gbC", "gbC31", "gbC313", "gbC3130"],
        ["gbC", "gbC31", "gbC314", "gbC3140"],
        ["gbC", "gbC32", "gbC321", "gbC3211"],
        ["gbC", "gbC32", "gbC321", "gbC3212"],
        ["gbC", "gbC32", "gbC321", "gbC3213"],
        ["gbC", "gbC32", "gbC321", "gbC3214"],
        ["gbC", "gbC32", "gbC321", "gbC3215"],
        ["gbC", "gbC32", "gbC321", "gbC3216"],
        ["gbC", "gbC32", "gbC321", "gbC3217"],
        ["gbC", "gbC32", "gbC321", "gbC3218"],
        ["gbC", "gbC32", "gbC321", "gbC3219"],
        ["gbC", "gbC32", "gbC322", "gbC3221"],
        ["gbC", "gbC32", "gbC322", "gbC3222"],
        ["gbC", "gbC32", "gbC322", "gbC3229"],
        ["gbC", "gbC32", "gbC323", "gbC3231"],
        ["gbC", "gbC32", "gbC323", "gbC3232"],
        ["gbC", "gbC32", "gbC323", "gbC3239"],
        ["gbC", "gbC32", "gbC324", "gbC3240"],
        ["gbC", "gbC32", "gbC325", "gbC3251"],
        ["gbC", "gbC32", "gbC325", "gbC3252"],
        ["gbC", "gbC32", "gbC325", "gbC3253"],
        ["gbC", "gbC32", "gbC325", "gbC3254"],
        ["gbC", "gbC32", "gbC325", "gbC3259"],
        ["gbC", "gbC33", "gbC331", "gbC3311"],
        ["gbC", "gbC33", "gbC331", "gbC3312"],
        ["gbC", "gbC33", "gbC332", "gbC3321"],
        ["gbC", "gbC33", "gbC332", "gbC3322"],
        ["gbC", "gbC33", "gbC332", "gbC3323"],
        ["gbC", "gbC33", "gbC332", "gbC3324"],
        ["gbC", "gbC33", "gbC332", "gbC3329"],
        ["gbC", "gbC33", "gbC333", "gbC3331"],
        ["gbC", "gbC33", "gbC333", "gbC3332"],
        ["gbC", "gbC33", "gbC333", "gbC3333"],
        ["gbC", "gbC33", "gbC334", "gbC3340"],
        ["gbC", "gbC33", "gbC335", "gbC3351"],
        ["gbC", "gbC33", "gbC335", "gbC3352"],
        ["gbC", "gbC33", "gbC335", "gbC3353"],
        ["gbC", "gbC33", "gbC335", "gbC3359"],
        ["gbC", "gbC33", "gbC336", "gbC3360"],
        ["gbC", "gbC33", "gbC337", "gbC3371"],
        ["gbC", "gbC33", "gbC337", "gbC3372"],
        ["gbC", "gbC33", "gbC337", "gbC3373"],
        ["gbC", "gbC33", "gbC337", "gbC3379"],
        ["gbC", "gbC33", "gbC338", "gbC3381"],
        ["gbC", "gbC33", "gbC338", "gbC3382"],
        ["gbC", "gbC33", "gbC338", "gbC3383"],
        ["gbC", "gbC33", "gbC338", "gbC3389"],
        ["gbC", "gbC33", "gbC339", "gbC3391"],
        ["gbC", "gbC33", "gbC339", "gbC3392"],
        ["gbC", "gbC33", "gbC339", "gbC3393"],
        ["gbC", "gbC33", "gbC339", "gbC3394"],
        ["gbC", "gbC33", "gbC339", "gbC3399"],
        ["gbC", "gbC34", "gbC341", "gbC3411"],
        ["gbC", "gbC34", "gbC341", "gbC3412"],
        ["gbC", "gbC34", "gbC341", "gbC3413"],
        ["gbC", "gbC34", "gbC341", "gbC3414"],
        ["gbC", "gbC34", "gbC341", "gbC3415"],
        ["gbC", "gbC34", "gbC341", "gbC3419"],
        ["gbC", "gbC34", "gbC342", "gbC3421"],
        ["gbC", "gbC34", "gbC342", "gbC3422"],
        ["gbC", "gbC34", "gbC342", "gbC3423"],
        ["gbC", "gbC34", "gbC342", "gbC3424"],
        ["gbC", "gbC34", "gbC342", "gbC3425"],
        ["gbC", "gbC34", "gbC342", "gbC3429"],
        ["gbC", "gbC34", "gbC343", "gbC3431"],
        ["gbC", "gbC34", "gbC343", "gbC3432"],
        ["gbC", "gbC34", "gbC343", "gbC3433"],
        ["gbC", "gbC34", "gbC343", "gbC3434"],
        ["gbC", "gbC34", "gbC343", "gbC3435"],
        ["gbC", "gbC34", "gbC343", "gbC3436"],
        ["gbC", "gbC34", "gbC343", "gbC3437"],
        ["gbC", "gbC34", "gbC343", "gbC3439"],
        ["gbC", "gbC34", "gbC344", "gbC3442"],
        ["gbC", "gbC34", "gbC344", "gbC3441"],
        ["gbC", "gbC34", "gbC344", "gbC3443"],
        ["gbC", "gbC34", "gbC344", "gbC3444"],
        ["gbC", "gbC34", "gbC344", "gbC3445"],
        ["gbC", "gbC34", "gbC344", "gbC3446"],
        ["gbC", "gbC34", "gbC345", "gbC3451"],
        ["gbC", "gbC34", "gbC345", "gbC3452"],
        ["gbC", "gbC34", "gbC345", "gbC3453"],
        ["gbC", "gbC34", "gbC345", "gbC3459"],
        ["gbC", "gbC34", "gbC346", "gbC3461"],
        ["gbC", "gbC34", "gbC346", "gbC3462"],
        ["gbC", "gbC34", "gbC346", "gbC3463"],
        ["gbC", "gbC34", "gbC346", "gbC3464"],
        ["gbC", "gbC34", "gbC346", "gbC3465"],
        ["gbC", "gbC34", "gbC346", "gbC3466"],
        ["gbC", "gbC34", "gbC346", "gbC3467"],
        ["gbC", "gbC34", "gbC347", "gbC3471"],
        ["gbC", "gbC34", "gbC347", "gbC3472"],
        ["gbC", "gbC34", "gbC347", "gbC3473"],
        ["gbC", "gbC34", "gbC347", "gbC3474"],
        ["gbC", "gbC34", "gbC347", "gbC3475"],
        ["gbC", "gbC34", "gbC347", "gbC3479"],
        ["gbC", "gbC34", "gbC348", "gbC3481"],
        ["gbC", "gbC34", "gbC348", "gbC3482"],
        ["gbC", "gbC34", "gbC348", "gbC3483"],
        ["gbC", "gbC34", "gbC348", "gbC3484"],
        ["gbC", "gbC34", "gbC348", "gbC3489"],
        ["gbC", "gbC34", "gbC349", "gbC3491"],
        ["gbC", "gbC34", "gbC349", "gbC3492"],
        ["gbC", "gbC34", "gbC349", "gbC3493"],
        ["gbC", "gbC34", "gbC349", "gbC3499"],
        ["gbC", "gbC35", "gbC351", "gbC3511"],
        ["gbC", "gbC35", "gbC351", "gbC3512"],
        ["gbC", "gbC35", "gbC351", "gbC3513"],
        ["gbC", "gbC35", "gbC351", "gbC3514"],
        ["gbC", "gbC35", "gbC351", "gbC3515"],
        ["gbC", "gbC35", "gbC351", "gbC3516"],
        ["gbC", "gbC35", "gbC351", "gbC3517"],
        ["gbC", "gbC35", "gbC352", "gbC3521"],
        ["gbC", "gbC35", "gbC352", "gbC3522"],
        ["gbC", "gbC35", "gbC352", "gbC3523"],
        ["gbC", "gbC35", "gbC352", "gbC3524"],
        ["gbC", "gbC35", "gbC352", "gbC3525"],
        ["gbC", "gbC35", "gbC352", "gbC3529"],
        ["gbC", "gbC35", "gbC353", "gbC3531"],
        ["gbC", "gbC35", "gbC353", "gbC3532"],
        ["gbC", "gbC35", "gbC353", "gbC3533"],
        ["gbC", "gbC35", "gbC353", "gbC3534"],
        ["gbC", "gbC35", "gbC354", "gbC3541"],
        ["gbC", "gbC35", "gbC354", "gbC3542"],
        ["gbC", "gbC35", "gbC354", "gbC3543"],
        ["gbC", "gbC35", "gbC354", "gbC3544"],
        ["gbC", "gbC35", "gbC354", "gbC3545"],
        ["gbC", "gbC35", "gbC354", "gbC3546"],
        ["gbC", "gbC35", "gbC354", "gbC3549"],
        ["gbC", "gbC35", "gbC355", "gbC3551"],
        ["gbC", "gbC35", "gbC355", "gbC3552"],
        ["gbC", "gbC35", "gbC355", "gbC3553"],
        ["gbC", "gbC35", "gbC355", "gbC3554"],
        ["gbC", "gbC35", "gbC356", "gbC3561"],
        ["gbC", "gbC35", "gbC356", "gbC3562"],
        ["gbC", "gbC35", "gbC356", "gbC3563"],
        ["gbC", "gbC35", "gbC356", "gbC3569"],
        ["gbC", "gbC35", "gbC357", "gbC3571"],
        ["gbC", "gbC35", "gbC357", "gbC3572"],
        ["gbC", "gbC35", "gbC357", "gbC3573"],
        ["gbC", "gbC35", "gbC357", "gbC3574"],
        ["gbC", "gbC35", "gbC357", "gbC3575"],
        ["gbC", "gbC35", "gbC357", "gbC3576"],
        ["gbC", "gbC35", "gbC357", "gbC3577"],
        ["gbC", "gbC35", "gbC357", "gbC3579"],
        ["gbC", "gbC35", "gbC358", "gbC3581"],
        ["gbC", "gbC35", "gbC358", "gbC3582"],
        ["gbC", "gbC35", "gbC358", "gbC3583"],
        ["gbC", "gbC35", "gbC358", "gbC3584"],
        ["gbC", "gbC35", "gbC358", "gbC3585"],
        ["gbC", "gbC35", "gbC358", "gbC3586"],
        ["gbC", "gbC35", "gbC358", "gbC3587"],
        ["gbC", "gbC35", "gbC358", "gbC3589"],
        ["gbC", "gbC35", "gbC359", "gbC3591"],
        ["gbC", "gbC35", "gbC359", "gbC3592"],
        ["gbC", "gbC35", "gbC359", "gbC3593"],
        ["gbC", "gbC35", "gbC359", "gbC3594"],
        ["gbC", "gbC35", "gbC359", "gbC3595"],
        ["gbC", "gbC35", "gbC359", "gbC3596"],
        ["gbC", "gbC35", "gbC359", "gbC3597"],
        ["gbC", "gbC35", "gbC359", "gbC3599"],
        ["gbC", "gbC36", "gbC361", "gbC3611"],
        ["gbC", "gbC36", "gbC361", "gbC3612"],
        ["gbC", "gbC36", "gbC362", "gbC3620"],
        ["gbC", "gbC36", "gbC363", "gbC3630"],
        ["gbC", "gbC36", "gbC364", "gbC3640"],
        ["gbC", "gbC36", "gbC365", "gbC3650"],
        ["gbC", "gbC36", "gbC366", "gbC3660"],
        ["gbC", "gbC36", "gbC367", "gbC3670"],
        ["gbC", "gbC37", "gbC371", "gbC3711"],
        ["gbC", "gbC37", "gbC371", "gbC3712"],
        ["gbC", "gbC37", "gbC371", "gbC3713"],
        ["gbC", "gbC37", "gbC371", "gbC3714"],
        ["gbC", "gbC37", "gbC371", "gbC3715"],
        ["gbC", "gbC37", "gbC371", "gbC3716"],
        ["gbC", "gbC37", "gbC371", "gbC3719"],
        ["gbC", "gbC37", "gbC372", "gbC3720"],
        ["gbC", "gbC37", "gbC373", "gbC3731"],
        ["gbC", "gbC37", "gbC373", "gbC3732"],
        ["gbC", "gbC37", "gbC373", "gbC3733"],
        ["gbC", "gbC37", "gbC373", "gbC3734"],
        ["gbC", "gbC37", "gbC373", "gbC3735"],
        ["gbC", "gbC37", "gbC373", "gbC3736"],
        ["gbC", "gbC37", "gbC373", "gbC3737"],
        ["gbC", "gbC37", "gbC373", "gbC3739"],
        ["gbC", "gbC37", "gbC374", "gbC3741"],
        ["gbC", "gbC37", "gbC374", "gbC3742"],
        ["gbC", "gbC37", "gbC374", "gbC3743"],
        ["gbC", "gbC37", "gbC374", "gbC3744"],
        ["gbC", "gbC37", "gbC374", "gbC3749"],
        ["gbC", "gbC37", "gbC375", "gbC3751"],
        ["gbC", "gbC37", "gbC375", "gbC3752"],
        ["gbC", "gbC37", "gbC376", "gbC3761"],
        ["gbC", "gbC37", "gbC376", "gbC3762"],
        ["gbC", "gbC37", "gbC377", "gbC3770"],
        ["gbC", "gbC37", "gbC378", "gbC3780"],
        ["gbC", "gbC37", "gbC379", "gbC3791"],
        ["gbC", "gbC37", "gbC379", "gbC3792"],
        ["gbC", "gbC37", "gbC379", "gbC3799"],
        ["gbC", "gbC38", "gbC381", "gbC3811"],
        ["gbC", "gbC38", "gbC381", "gbC3812"],
        ["gbC", "gbC38", "gbC381", "gbC3813"],
        ["gbC", "gbC38", "gbC381", "gbC3819"],
        ["gbC", "gbC38", "gbC382", "gbC3821"],
        ["gbC", "gbC38", "gbC382", "gbC3822"],
        ["gbC", "gbC38", "gbC382", "gbC3823"],
        ["gbC", "gbC38", "gbC382", "gbC3824"],
        ["gbC", "gbC38", "gbC382", "gbC3825"],
        ["gbC", "gbC38", "gbC382", "gbC3829"],
        ["gbC", "gbC38", "gbC383", "gbC3831"],
        ["gbC", "gbC38", "gbC383", "gbC3832"],
        ["gbC", "gbC38", "gbC383", "gbC3833"],
        ["gbC", "gbC38", "gbC383", "gbC3834"],
        ["gbC", "gbC38", "gbC383", "gbC3839"],
        ["gbC", "gbC38", "gbC384", "gbC3841"],
        ["gbC", "gbC38", "gbC384", "gbC3842"],
        ["gbC", "gbC38", "gbC384", "gbC3843"],
        ["gbC", "gbC38", "gbC384", "gbC3844"],
        ["gbC", "gbC38", "gbC384", "gbC3849"],
        ["gbC", "gbC38", "gbC385", "gbC3851"],
        ["gbC", "gbC38", "gbC385", "gbC3852"],
        ["gbC", "gbC38", "gbC385", "gbC3853"],
        ["gbC", "gbC38", "gbC385", "gbC3854"],
        ["gbC", "gbC38", "gbC385", "gbC3855"],
        ["gbC", "gbC38", "gbC385", "gbC3856"],
        ["gbC", "gbC38", "gbC385", "gbC3857"],
        ["gbC", "gbC38", "gbC385", "gbC3859"],
        ["gbC", "gbC38", "gbC386", "gbC3861"],
        ["gbC", "gbC38", "gbC386", "gbC3862"],
        ["gbC", "gbC38", "gbC386", "gbC3869"],
        ["gbC", "gbC38", "gbC387", "gbC3871"],
        ["gbC", "gbC38", "gbC387", "gbC3872"],
        ["gbC", "gbC38", "gbC387", "gbC3873"],
        ["gbC", "gbC38", "gbC387", "gbC3874"],
        ["gbC", "gbC38", "gbC387", "gbC3879"],
        ["gbC", "gbC38", "gbC389", "gbC3891"],
        ["gbC", "gbC38", "gbC389", "gbC3899"],
        ["gbC", "gbC39", "gbC391", "gbC3911"],
        ["gbC", "gbC39", "gbC391", "gbC3912"],
        ["gbC", "gbC39", "gbC391", "gbC3913"],
        ["gbC", "gbC39", "gbC391", "gbC3914"],
        ["gbC", "gbC39", "gbC391", "gbC3915"],
        ["gbC", "gbC39", "gbC391", "gbC3919"],
        ["gbC", "gbC39", "gbC392", "gbC3921"],
        ["gbC", "gbC39", "gbC392", "gbC3922"],
        ["gbC", "gbC39", "gbC393", "gbC3931"],
        ["gbC", "gbC39", "gbC393", "gbC3932"],
        ["gbC", "gbC39", "gbC393", "gbC3933"],
        ["gbC", "gbC39", "gbC393", "gbC3934"],
        ["gbC", "gbC39", "gbC393", "gbC3939"],
        ["gbC", "gbC39", "gbC394", "gbC3940"],
        ["gbC", "gbC39", "gbC395", "gbC3951"],
        ["gbC", "gbC39", "gbC395", "gbC3952"],
        ["gbC", "gbC39", "gbC395", "gbC3953"],
        ["gbC", "gbC39", "gbC396", "gbC3961"],
        ["gbC", "gbC39", "gbC396", "gbC3962"],
        ["gbC", "gbC39", "gbC396", "gbC3963"],
        ["gbC", "gbC39", "gbC396", "gbC3964"],
        ["gbC", "gbC39", "gbC396", "gbC3969"],
        ["gbC", "gbC39", "gbC397", "gbC3971"],
        ["gbC", "gbC39", "gbC397", "gbC3972"],
        ["gbC", "gbC39", "gbC397", "gbC3973"],
        ["gbC", "gbC39", "gbC397", "gbC3974"],
        ["gbC", "gbC39", "gbC397", "gbC3975"],
        ["gbC", "gbC39", "gbC397", "gbC3976"],
        ["gbC", "gbC39", "gbC397", "gbC3979"],
        ["gbC", "gbC39", "gbC398", "gbC3981"],
        ["gbC", "gbC39", "gbC398", "gbC3982"],
        ["gbC", "gbC39", "gbC398", "gbC3983"],
        ["gbC", "gbC39", "gbC398", "gbC3984"],
        ["gbC", "gbC39", "gbC398", "gbC3985"],
        ["gbC", "gbC39", "gbC398", "gbC3989"],
        ["gbC", "gbC39", "gbC399", "gbC3990"],
        ["gbC", "gbC40", "gbC401", "gbC4011"],
        ["gbC", "gbC40", "gbC401", "gbC4012"],
        ["gbC", "gbC40", "gbC401", "gbC4013"],
        ["gbC", "gbC40", "gbC401", "gbC4014"],
        ["gbC", "gbC40", "gbC401", "gbC4015"],
        ["gbC", "gbC40", "gbC401", "gbC4016"],
        ["gbC", "gbC40", "gbC401", "gbC4019"],
        ["gbC", "gbC40", "gbC402", "gbC4021"],
        ["gbC", "gbC40", "gbC402", "gbC4022"],
        ["gbC", "gbC40", "gbC402", "gbC4023"],
        ["gbC", "gbC40", "gbC402", "gbC4024"],
        ["gbC", "gbC40", "gbC402", "gbC4025"],
        ["gbC", "gbC40", "gbC402", "gbC4026"],
        ["gbC", "gbC40", "gbC402", "gbC4027"],
        ["gbC", "gbC40", "gbC402", "gbC4028"],
        ["gbC", "gbC40", "gbC402", "gbC4029"],
        ["gbC", "gbC40", "gbC403", "gbC4030"],
        ["gbC", "gbC40", "gbC404", "gbC4040"],
        ["gbC", "gbC40", "gbC405", "gbC4050"],
        ["gbC", "gbC40", "gbC409", "gbC4090"],
        ["gbC", "gbC41", "gbC411", "gbC4111"],
        ["gbC", "gbC41", "gbC411", "gbC4119"],
        ["gbC", "gbC41", "gbC412", "gbC4120"],
        ["gbC", "gbC41", "gbC419", "gbC4190"],
        ["gbC", "gbC42", "gbC421", "gbC4210"],
        ["gbC", "gbC42", "gbC422", "gbC4220"],
        ["gbC", "gbC43", "gbC431", "gbC4310"],
        ["gbC", "gbC43", "gbC432", "gbC4320"],
        ["gbC", "gbC43", "gbC433", "gbC4330"],
        ["gbC", "gbC43", "gbC434", "gbC4341"],
        ["gbC", "gbC43", "gbC434", "gbC4342"],
        ["gbC", "gbC43", "gbC434", "gbC4343"],
        ["gbC", "gbC43", "gbC434", "gbC4349"],
        ["gbC", "gbC43", "gbC435", "gbC4350"],
        ["gbC", "gbC43", "gbC436", "gbC4360"],
        ["gbC", "gbC43", "gbC439", "gbC4390"],
        ["gbD", "gbD44", "gbD441", "gbD4411"],
        ["gbD", "gbD44", "gbD441", "gbD4412"],
        ["gbD", "gbD44", "gbD441", "gbD4413"],
        ["gbD", "gbD44", "gbD441", "gbD4414"],
        ["gbD", "gbD44", "gbD441", "gbD4415"],
        ["gbD", "gbD44", "gbD441", "gbD4416"],
        ["gbD", "gbD44", "gbD441", "gbD4417"],
        ["gbD", "gbD44", "gbD441", "gbD4419"],
        ["gbD", "gbD44", "gbD442", "gbD4420"],
        ["gbD", "gbD44", "gbD443", "gbD4430"],
        ["gbD", "gbD45", "gbD451", "gbD4511"],
        ["gbD", "gbD45", "gbD451", "gbD4512"],
        ["gbD", "gbD45", "gbD451", "gbD4513"],
        ["gbD", "gbD45", "gbD452", "gbD4520"],
        ["gbD", "gbD46", "gbD461", "gbD4610"],
        ["gbD", "gbD46", "gbD462", "gbD4620"],
        ["gbD", "gbD46", "gbD463", "gbD4630"],
        ["gbD", "gbD46", "gbD469", "gbD4690"],
        ["gbE", "gbE47", "gbE471", "gbE4710"],
        ["gbE", "gbE47", "gbE472", "gbE4720"],
        ["gbE", "gbE47", "gbE479", "gbE4790"],
        ["gbE", "gbE48", "gbE481", "gbE4811"],
        ["gbE", "gbE48", "gbE481", "gbE4812"],
        ["gbE", "gbE48", "gbE481", "gbE4813"],
        ["gbE", "gbE48", "gbE481", "gbE4814"],
        ["gbE", "gbE48", "gbE481", "gbE4819"],
        ["gbE", "gbE48", "gbE482", "gbE4821"],
        ["gbE", "gbE48", "gbE482", "gbE4822"],
        ["gbE", "gbE48", "gbE482", "gbE4823"],
        ["gbE", "gbE48", "gbE483", "gbE4831"],
        ["gbE", "gbE48", "gbE483", "gbE4832"],
        ["gbE", "gbE48", "gbE483", "gbE4833"],
        ["gbE", "gbE48", "gbE483", "gbE4834"],
        ["gbE", "gbE48", "gbE483", "gbE4839"],
        ["gbE", "gbE48", "gbE484", "gbE4840"],
        ["gbE", "gbE48", "gbE485", "gbE4851"],
        ["gbE", "gbE48", "gbE485", "gbE4852"],
        ["gbE", "gbE48", "gbE485", "gbE4853"],
        ["gbE", "gbE48", "gbE486", "gbE4861"],
        ["gbE", "gbE48", "gbE486", "gbE4862"],
        ["gbE", "gbE48", "gbE486", "gbE4863"],
        ["gbE", "gbE48", "gbE487", "gbE4871"],
        ["gbE", "gbE48", "gbE487", "gbE4872"],
        ["gbE", "gbE48", "gbE487", "gbE4873"],
        ["gbE", "gbE48", "gbE487", "gbE4874"],
        ["gbE", "gbE48", "gbE487", "gbE4875"],
        ["gbE", "gbE48", "gbE487", "gbE4879"],
        ["gbE", "gbE48", "gbE489", "gbE4891"],
        ["gbE", "gbE48", "gbE489", "gbE4892"],
        ["gbE", "gbE48", "gbE489", "gbE4893"],
        ["gbE", "gbE48", "gbE489", "gbE4899"],
        ["gbE", "gbE49", "gbE491", "gbE4910"],
        ["gbE", "gbE49", "gbE492", "gbE4920"],
        ["gbE", "gbE49", "gbE499", "gbE4991"],
        ["gbE", "gbE49", "gbE499", "gbE4999"],
        ["gbE", "gbE50", "gbE501", "gbE5011"],
        ["gbE", "gbE50", "gbE501", "gbE5012"],
        ["gbE", "gbE50", "gbE501", "gbE5013"],
        ["gbE", "gbE50", "gbE502", "gbE5021"],
        ["gbE", "gbE50", "gbE502", "gbE5022"],
        ["gbE", "gbE50", "gbE503", "gbE5030"],
        ["gbE", "gbE50", "gbE509", "gbE5090"],
        ["gbF", "gbF51", "gbF511", "gbF5111"],
        ["gbF", "gbF51", "gbF511", "gbF5112"],
        ["gbF", "gbF51", "gbF511", "gbF5113"],
        ["gbF", "gbF51", "gbF511", "gbF5114"],
        ["gbF", "gbF51", "gbF511", "gbF5115"],
        ["gbF", "gbF51", "gbF511", "gbF5116"],
        ["gbF", "gbF51", "gbF511", "gbF5117"],
        ["gbF", "gbF51", "gbF511", "gbF5119"],
        ["gbF", "gbF51", "gbF512", "gbF5121"],
        ["gbF", "gbF51", "gbF512", "gbF5122"],
        ["gbF", "gbF51", "gbF512", "gbF5123"],
        ["gbF", "gbF51", "gbF512", "gbF5124"],
        ["gbF", "gbF51", "gbF512", "gbF5125"],
        ["gbF", "gbF51", "gbF512", "gbF5126"],
        ["gbF", "gbF51", "gbF512", "gbF5127"],
        ["gbF", "gbF51", "gbF512", "gbF5128"],
        ["gbF", "gbF51", "gbF512", "gbF5129"],
        ["gbF", "gbF51", "gbF513", "gbF5131"],
        ["gbF", "gbF51", "gbF513", "gbF5132"],
        ["gbF", "gbF51", "gbF513", "gbF5133"],
        ["gbF", "gbF51", "gbF513", "gbF5134"],
        ["gbF", "gbF51", "gbF513", "gbF5135"],
        ["gbF", "gbF51", "gbF513", "gbF5136"],
        ["gbF", "gbF51", "gbF513", "gbF5137"],
        ["gbF", "gbF51", "gbF513", "gbF5138"],
        ["gbF", "gbF51", "gbF513", "gbF5139"],
        ["gbF", "gbF51", "gbF514", "gbF5141"],
        ["gbF", "gbF51", "gbF514", "gbF5142"],
        ["gbF", "gbF51", "gbF514", "gbF5143"],
        ["gbF", "gbF51", "gbF514", "gbF5144"],
        ["gbF", "gbF51", "gbF514", "gbF5145"],
        ["gbF", "gbF51", "gbF514", "gbF5146"],
        ["gbF", "gbF51", "gbF514", "gbF5147"],
        ["gbF", "gbF51", "gbF514", "gbF5149"],
        ["gbF", "gbF51", "gbF515", "gbF5151"],
        ["gbF", "gbF51", "gbF515", "gbF5152"],
        ["gbF", "gbF51", "gbF515", "gbF5153"],
        ["gbF", "gbF51", "gbF515", "gbF5154"],
        ["gbF", "gbF51", "gbF516", "gbF5161"],
        ["gbF", "gbF51", "gbF516", "gbF5162"],
        ["gbF", "gbF51", "gbF516", "gbF5163"],
        ["gbF", "gbF51", "gbF516", "gbF5164"],
        ["gbF", "gbF51", "gbF516", "gbF5165"],
        ["gbF", "gbF51", "gbF516", "gbF5166"],
        ["gbF", "gbF51", "gbF516", "gbF5167"],
        ["gbF", "gbF51", "gbF516", "gbF5168"],
        ["gbF", "gbF51", "gbF516", "gbF5169"],
        ["gbF", "gbF51", "gbF517", "gbF5171"],
        ["gbF", "gbF51", "gbF517", "gbF5172"],
        ["gbF", "gbF51", "gbF517", "gbF5173"],
        ["gbF", "gbF51", "gbF517", "gbF5174"],
        ["gbF", "gbF51", "gbF517", "gbF5175"],
        ["gbF", "gbF51", "gbF517", "gbF5176"],
        ["gbF", "gbF51", "gbF517", "gbF5177"],
        ["gbF", "gbF51", "gbF517", "gbF5178"],
        ["gbF", "gbF51", "gbF517", "gbF5179"],
        ["gbF", "gbF51", "gbF518", "gbF5181"],
        ["gbF", "gbF51", "gbF518", "gbF5182"],
        ["gbF", "gbF51", "gbF518", "gbF5183"],
        ["gbF", "gbF51", "gbF518", "gbF5184"],
        ["gbF", "gbF51", "gbF518", "gbF5189"],
        ["gbF", "gbF51", "gbF519", "gbF5191"],
        ["gbF", "gbF51", "gbF519", "gbF5192"],
        ["gbF", "gbF51", "gbF519", "gbF5193"],
        ["gbF", "gbF51", "gbF519", "gbF5199"],
        ["gbF", "gbF52", "gbF521", "gbF5211"],
        ["gbF", "gbF52", "gbF521", "gbF5212"],
        ["gbF", "gbF52", "gbF521", "gbF5213"],
        ["gbF", "gbF52", "gbF521", "gbF5219"],
        ["gbF", "gbF52", "gbF522", "gbF5221"],
        ["gbF", "gbF52", "gbF522", "gbF5222"],
        ["gbF", "gbF52", "gbF522", "gbF5223"],
        ["gbF", "gbF52", "gbF522", "gbF5224"],
        ["gbF", "gbF52", "gbF522", "gbF5225"],
        ["gbF", "gbF52", "gbF522", "gbF5226"],
        ["gbF", "gbF52", "gbF522", "gbF5227"],
        ["gbF", "gbF52", "gbF522", "gbF5229"],
        ["gbF", "gbF52", "gbF523", "gbF5231"],
        ["gbF", "gbF52", "gbF523", "gbF5232"],
        ["gbF", "gbF52", "gbF523", "gbF5233"],
        ["gbF", "gbF52", "gbF523", "gbF5234"],
        ["gbF", "gbF52", "gbF523", "gbF5235"],
        ["gbF", "gbF52", "gbF523", "gbF5236"],
        ["gbF", "gbF52", "gbF523", "gbF5237"],
        ["gbF", "gbF52", "gbF523", "gbF5238"],
        ["gbF", "gbF52", "gbF523", "gbF5239"],
        ["gbF", "gbF52", "gbF524", "gbF5241"],
        ["gbF", "gbF52", "gbF524", "gbF5242"],
        ["gbF", "gbF52", "gbF524", "gbF5243"],
        ["gbF", "gbF52", "gbF524", "gbF5244"],
        ["gbF", "gbF52", "gbF524", "gbF5245"],
        ["gbF", "gbF52", "gbF524", "gbF5246"],
        ["gbF", "gbF52", "gbF524", "gbF5247"],
        ["gbF", "gbF52", "gbF524", "gbF5248"],
        ["gbF", "gbF52", "gbF524", "gbF5249"],
        ["gbF", "gbF52", "gbF525", "gbF5251"],
        ["gbF", "gbF52", "gbF525", "gbF5252"],
        ["gbF", "gbF52", "gbF525", "gbF5253"],
        ["gbF", "gbF52", "gbF525", "gbF5254"],
        ["gbF", "gbF52", "gbF525", "gbF5255"],
        ["gbF", "gbF52", "gbF526", "gbF5261"],
        ["gbF", "gbF52", "gbF526", "gbF5262"],
        ["gbF", "gbF52", "gbF526", "gbF5263"],
        ["gbF", "gbF52", "gbF526", "gbF5264"],
        ["gbF", "gbF52", "gbF526", "gbF5265"],
        ["gbF", "gbF52", "gbF526", "gbF5266"],
        ["gbF", "gbF52", "gbF526", "gbF5267"],
        ["gbF", "gbF52", "gbF527", "gbF5271"],
        ["gbF", "gbF52", "gbF527", "gbF5272"],
        ["gbF", "gbF52", "gbF527", "gbF5273"],
        ["gbF", "gbF52", "gbF527", "gbF5274"],
        ["gbF", "gbF52", "gbF527", "gbF5279"],
        ["gbF", "gbF52", "gbF528", "gbF5281"],
        ["gbF", "gbF52", "gbF528", "gbF5282"],
        ["gbF", "gbF52", "gbF528", "gbF5283"],
        ["gbF", "gbF52", "gbF528", "gbF5284"],
        ["gbF", "gbF52", "gbF528", "gbF5285"],
        ["gbF", "gbF52", "gbF528", "gbF5286"],
        ["gbF", "gbF52", "gbF528", "gbF5287"],
        ["gbF", "gbF52", "gbF528", "gbF5289"],
        ["gbF", "gbF52", "gbF529", "gbF5291"],
        ["gbF", "gbF52", "gbF529", "gbF5292"],
        ["gbF", "gbF52", "gbF529", "gbF5293"],
        ["gbF", "gbF52", "gbF529", "gbF5294"],
        ["gbF", "gbF52", "gbF529", "gbF5295"],
        ["gbF", "gbF52", "gbF529", "gbF5296"],
        ["gbF", "gbF52", "gbF529", "gbF5297"],
        ["gbF", "gbF52", "gbF529", "gbF5299"],
        ["gbG", "gbG53", "gbG531", "gbG5311"],
        ["gbG", "gbG53", "gbG531", "gbG5312"],
        ["gbG", "gbG53", "gbG531", "gbG5313"],
        ["gbG", "gbG53", "gbG532", "gbG5320"],
        ["gbG", "gbG53", "gbG533", "gbG5331"],
        ["gbG", "gbG53", "gbG533", "gbG5332"],
        ["gbG", "gbG53", "gbG533", "gbG5333"],
        ["gbG", "gbG53", "gbG533", "gbG5339"],
        ["gbG", "gbG54", "gbG541", "gbG5411"],
        ["gbG", "gbG54", "gbG541", "gbG5412"],
        ["gbG", "gbG54", "gbG541", "gbG5413"],
        ["gbG", "gbG54", "gbG541", "gbG5414"],
        ["gbG", "gbG54", "gbG541", "gbG5419"],
        ["gbG", "gbG54", "gbG542", "gbG5421"],
        ["gbG", "gbG54", "gbG542", "gbG5422"],
        ["gbG", "gbG54", "gbG542", "gbG5429"],
        ["gbG", "gbG54", "gbG543", "gbG5431"],
        ["gbG", "gbG54", "gbG543", "gbG5432"],
        ["gbG", "gbG54", "gbG543", "gbG5433"],
        ["gbG", "gbG54", "gbG543", "gbG5434"],
        ["gbG", "gbG54", "gbG543", "gbG5435"],
        ["gbG", "gbG54", "gbG543", "gbG5436"],
        ["gbG", "gbG54", "gbG543", "gbG5437"],
        ["gbG", "gbG54", "gbG543", "gbG5438"],
        ["gbG", "gbG54", "gbG543", "gbG5439"],
        ["gbG", "gbG54", "gbG544", "gbG5441"],
        ["gbG", "gbG54", "gbG544", "gbG5442"],
        ["gbG", "gbG54", "gbG544", "gbG5443"],
        ["gbG", "gbG54", "gbG544", "gbG5449"],
        ["gbG", "gbG55", "gbG551", "gbG5511"],
        ["gbG", "gbG55", "gbG551", "gbG5512"],
        ["gbG", "gbG55", "gbG551", "gbG5513"],
        ["gbG", "gbG55", "gbG552", "gbG5521"],
        ["gbG", "gbG55", "gbG552", "gbG5522"],
        ["gbG", "gbG55", "gbG552", "gbG5523"],
        ["gbG", "gbG55", "gbG553", "gbG5531"],
        ["gbG", "gbG55", "gbG553", "gbG5532"],
        ["gbG", "gbG55", "gbG553", "gbG5539"],
        ["gbG", "gbG56", "gbG561", "gbG5611"],
        ["gbG", "gbG56", "gbG561", "gbG5612"],
        ["gbG", "gbG56", "gbG562", "gbG5621"],
        ["gbG", "gbG56", "gbG562", "gbG5622"],
        ["gbG", "gbG56", "gbG562", "gbG5623"],
        ["gbG", "gbG56", "gbG562", "gbG5629"],
        ["gbG", "gbG56", "gbG563", "gbG5631"],
        ["gbG", "gbG56", "gbG563", "gbG5632"],
        ["gbG", "gbG56", "gbG563", "gbG5639"],
        ["gbG", "gbG57", "gbG571", "gbG5710"],
        ["gbG", "gbG57", "gbG572", "gbG5720"],
        ["gbG", "gbG58", "gbG581", "gbG5810"],
        ["gbG", "gbG58", "gbG582", "gbG5821"],
        ["gbG", "gbG58", "gbG582", "gbG5822"],
        ["gbG", "gbG58", "gbG582", "gbG5829"],
        ["gbG", "gbG59", "gbG591", "gbG5910"],
        ["gbG", "gbG59", "gbG592", "gbG5920"],
        ["gbG", "gbG59", "gbG593", "gbG5930"],
        ["gbG", "gbG59", "gbG594", "gbG5941"],
        ["gbG", "gbG59", "gbG594", "gbG5942"],
        ["gbG", "gbG59", "gbG594", "gbG5949"],
        ["gbG", "gbG59", "gbG595", "gbG5951"],
        ["gbG", "gbG59", "gbG595", "gbG5952"],
        ["gbG", "gbG59", "gbG595", "gbG5959"],
        ["gbG", "gbG59", "gbG596", "gbG5960"],
        ["gbG", "gbG59", "gbG599", "gbG5990"],
        ["gbG", "gbG60", "gbG601", "gbG6010"],
        ["gbG", "gbG60", "gbG602", "gbG6020"],
        ["gbG", "gbG60", "gbG609", "gbG6090"],
        ["gbH", "gbH61", "gbH611", "gbH6110"],
        ["gbH", "gbH61", "gbH612", "gbH6121"],
        ["gbH", "gbH61", "gbH612", "gbH6129"],
        ["gbH", "gbH61", "gbH613", "gbH6130"],
        ["gbH", "gbH61", "gbH614", "gbH6140"],
        ["gbH", "gbH61", "gbH619", "gbH6190"],
        ["gbH", "gbH62", "gbH621", "gbH6210"],
        ["gbH", "gbH62", "gbH622", "gbH6220"],
        ["gbH", "gbH62", "gbH623", "gbH6231"],
        ["gbH", "gbH62", "gbH623", "gbH6232"],
        ["gbH", "gbH62", "gbH623", "gbH6233"],
        ["gbH", "gbH62", "gbH623", "gbH6239"],
        ["gbH", "gbH62", "gbH624", "gbH6241"],
        ["gbH", "gbH62", "gbH624", "gbH6242"],
        ["gbH", "gbH62", "gbH629", "gbH6291"],
        ["gbH", "gbH62", "gbH629", "gbH6299"],
        ["gbI", "gbI63", "gbI631", "gbI6311"],
        ["gbI", "gbI63", "gbI631", "gbI6312"],
        ["gbI", "gbI63", "gbI631", "gbI6319"],
        ["gbI", "gbI63", "gbI632", "gbI6321"],
        ["gbI", "gbI63", "gbI632", "gbI6322"],
        ["gbI", "gbI63", "gbI633", "gbI6331"],
        ["gbI", "gbI63", "gbI633", "gbI6339"],
        ["gbI", "gbI64", "gbI641", "gbI6410"],
        ["gbI", "gbI64", "gbI642", "gbI6421"],
        ["gbI", "gbI64", "gbI642", "gbI6422"],
        ["gbI", "gbI64", "gbI642", "gbI6429"],
        ["gbI", "gbI64", "gbI643", "gbI6431"],
        ["gbI", "gbI64", "gbI643", "gbI6432"],
        ["gbI", "gbI64", "gbI643", "gbI6433"],
        ["gbI", "gbI64", "gbI643", "gbI6434"],
        ["gbI", "gbI64", "gbI643", "gbI6439"],
        ["gbI", "gbI64", "gbI644", "gbI6440"],
        ["gbI", "gbI64", "gbI645", "gbI6450"],
        ["gbI", "gbI64", "gbI649", "gbI6490"],
        ["gbI", "gbI65", "gbI651", "gbI6511"],
        ["gbI", "gbI65", "gbI651", "gbI6512"],
        ["gbI", "gbI65", "gbI651", "gbI6513"],
        ["gbI", "gbI65", "gbI651", "gbI6519"],
        ["gbI", "gbI65", "gbI652", "gbI6520"],
        ["gbI", "gbI65", "gbI653", "gbI6531"],
        ["gbI", "gbI65", "gbI653", "gbI6532"],
        ["gbI", "gbI65", "gbI654", "gbI6540"],
        ["gbI", "gbI65", "gbI655", "gbI6550"],
        ["gbI", "gbI65", "gbI656", "gbI6560"],
        ["gbI", "gbI65", "gbI657", "gbI6571"],
        ["gbI", "gbI65", "gbI657", "gbI6572"],
        ["gbI", "gbI65", "gbI657", "gbI6579"],
        ["gbI", "gbI65", "gbI659", "gbI6591"],
        ["gbI", "gbI65", "gbI659", "gbI6599"],
        ["gbJ", "gbJ66", "gbJ661", "gbJ6610"],
        ["gbJ", "gbJ66", "gbJ662", "gbJ6621"],
        ["gbJ", "gbJ66", "gbJ662", "gbJ6622"],
        ["gbJ", "gbJ66", "gbJ662", "gbJ6623"],
        ["gbJ", "gbJ66", "gbJ662", "gbJ6624"],
        ["gbJ", "gbJ66", "gbJ662", "gbJ6629"],
        ["gbJ", "gbJ66", "gbJ663", "gbJ6631"],
        ["gbJ", "gbJ66", "gbJ663", "gbJ6632"],
        ["gbJ", "gbJ66", "gbJ663", "gbJ6633"],
        ["gbJ", "gbJ66", "gbJ663", "gbJ6634"],
        ["gbJ", "gbJ66", "gbJ663", "gbJ6635"],
        ["gbJ", "gbJ66", "gbJ663", "gbJ6636"],
        ["gbJ", "gbJ66", "gbJ663", "gbJ6637"],
        ["gbJ", "gbJ66", "gbJ663", "gbJ6639"],
        ["gbJ", "gbJ66", "gbJ664", "gbJ6640"],
        ["gbJ", "gbJ66", "gbJ665", "gbJ6650"],
        ["gbJ", "gbJ67", "gbJ671", "gbJ6711"],
        ["gbJ", "gbJ67", "gbJ671", "gbJ6712"],
        ["gbJ", "gbJ67", "gbJ672", "gbJ6720"],
        ["gbJ", "gbJ67", "gbJ673", "gbJ6731"],
        ["gbJ", "gbJ67", "gbJ673", "gbJ6732"],
        ["gbJ", "gbJ67", "gbJ673", "gbJ6739"],
        ["gbJ", "gbJ67", "gbJ674", "gbJ6741"],
        ["gbJ", "gbJ67", "gbJ674", "gbJ6749"],
        ["gbJ", "gbJ67", "gbJ675", "gbJ6750"],
        ["gbJ", "gbJ67", "gbJ676", "gbJ6760"],
        ["gbJ", "gbJ67", "gbJ679", "gbJ6790"],
        ["gbJ", "gbJ68", "gbJ681", "gbJ6811"],
        ["gbJ", "gbJ68", "gbJ681", "gbJ6812"],
        ["gbJ", "gbJ68", "gbJ681", "gbJ6813"],
        ["gbJ", "gbJ68", "gbJ681", "gbJ6814"],
        ["gbJ", "gbJ68", "gbJ682", "gbJ6820"],
        ["gbJ", "gbJ68", "gbJ683", "gbJ6830"],
        ["gbJ", "gbJ68", "gbJ684", "gbJ6840"],
        ["gbJ", "gbJ68", "gbJ685", "gbJ6851"],
        ["gbJ", "gbJ68", "gbJ685", "gbJ6852"],
        ["gbJ", "gbJ68", "gbJ685", "gbJ6853"],
        ["gbJ", "gbJ68", "gbJ686", "gbJ6860"],
        ["gbJ", "gbJ68", "gbJ687", "gbJ6870"],
        ["gbJ", "gbJ68", "gbJ689", "gbJ6890"],
        ["gbJ", "gbJ69", "gbJ691", "gbJ6911"],
        ["gbJ", "gbJ69", "gbJ691", "gbJ6919"],
        ["gbJ", "gbJ69", "gbJ692", "gbJ6920"],
        ["gbJ", "gbJ69", "gbJ693", "gbJ6930"],
        ["gbJ", "gbJ69", "gbJ694", "gbJ6940"],
        ["gbJ", "gbJ69", "gbJ695", "gbJ6950"],
        ["gbJ", "gbJ69", "gbJ699", "gbJ6991"],
        ["gbJ", "gbJ69", "gbJ699", "gbJ6999"],
        ["gbK", "gbK70", "gbK701", "gbK7010"],
        ["gbK", "gbK70", "gbK702", "gbK7020"],
        ["gbK", "gbK70", "gbK703", "gbK7030"],
        ["gbK", "gbK70", "gbK704", "gbK7040"],
        ["gbK", "gbK70", "gbK709", "gbK7090"],
        ["gbL", "gbL71", "gbL711", "gbL7111"],
        ["gbL", "gbL71", "gbL711", "gbL7112"],
        ["gbL", "gbL71", "gbL711", "gbL7113"],
        ["gbL", "gbL71", "gbL711", "gbL7114"],
        ["gbL", "gbL71", "gbL711", "gbL7115"],
        ["gbL", "gbL71", "gbL711", "gbL7119"],
        ["gbL", "gbL71", "gbL712", "gbL7121"],
        ["gbL", "gbL71", "gbL712", "gbL7122"],
        ["gbL", "gbL71", "gbL712", "gbL7123"],
        ["gbL", "gbL71", "gbL712", "gbL7124"],
        ["gbL", "gbL71", "gbL712", "gbL7125"],
        ["gbL", "gbL71", "gbL712", "gbL7129"],
        ["gbL", "gbL71", "gbL713", "gbL7130"],
        ["gbL", "gbL72", "gbL721", "gbL7211"],
        ["gbL", "gbL72", "gbL721", "gbL7212"],
        ["gbL", "gbL72", "gbL721", "gbL7213"],
        ["gbL", "gbL72", "gbL721", "gbL7214"],
        ["gbL", "gbL72", "gbL721", "gbL7215"],
        ["gbL", "gbL72", "gbL721", "gbL7219"],
        ["gbL", "gbL72", "gbL722", "gbL7221"],
        ["gbL", "gbL72", "gbL722", "gbL7222"],
        ["gbL", "gbL72", "gbL722", "gbL7223"],
        ["gbL", "gbL72", "gbL722", "gbL7224"],
        ["gbL", "gbL72", "gbL722", "gbL7229"],
        ["gbL", "gbL72", "gbL723", "gbL7231"],
        ["gbL", "gbL72", "gbL723", "gbL7232"],
        ["gbL", "gbL72", "gbL723", "gbL7239"],
        ["gbL", "gbL72", "gbL724", "gbL7241"],
        ["gbL", "gbL72", "gbL724", "gbL7242"],
        ["gbL", "gbL72", "gbL724", "gbL7243"],
        ["gbL", "gbL72", "gbL724", "gbL7244"],
        ["gbL", "gbL72", "gbL724", "gbL7245"],
        ["gbL", "gbL72", "gbL724", "gbL7246"],
        ["gbL", "gbL72", "gbL724", "gbL7249"],
        ["gbL", "gbL72", "gbL725", "gbL7251"],
        ["gbL", "gbL72", "gbL725", "gbL7259"],
        ["gbL", "gbL72", "gbL726", "gbL7261"],
        ["gbL", "gbL72", "gbL726", "gbL7262"],
        ["gbL", "gbL72", "gbL726", "gbL7263"],
        ["gbL", "gbL72", "gbL726", "gbL7264"],
        ["gbL", "gbL72", "gbL726", "gbL7269"],
        ["gbL", "gbL72", "gbL727", "gbL7271"],
        ["gbL", "gbL72", "gbL727", "gbL7272"],
        ["gbL", "gbL72", "gbL727", "gbL7279"],
        ["gbL", "gbL72", "gbL728", "gbL7281"],
        ["gbL", "gbL72", "gbL728", "gbL7282"],
        ["gbL", "gbL72", "gbL728", "gbL7283"],
        ["gbL", "gbL72", "gbL728", "gbL7284"],
        ["gbL", "gbL72", "gbL728", "gbL7289"],
        ["gbL", "gbL72", "gbL729", "gbL7291"],
        ["gbL", "gbL72", "gbL729", "gbL7292"],
        ["gbL", "gbL72", "gbL729", "gbL7293"],
        ["gbL", "gbL72", "gbL729", "gbL7294"],
        ["gbL", "gbL72", "gbL729", "gbL7295"],
        ["gbL", "gbL72", "gbL729", "gbL7296"],
        ["gbL", "gbL72", "gbL729", "gbL7297"],
        ["gbL", "gbL72", "gbL729", "gbL7298"],
        ["gbL", "gbL72", "gbL729", "gbL7299"],
        ["gbM", "gbM73", "gbM731", "gbM7310"],
        ["gbM", "gbM73", "gbM732", "gbM7320"],
        ["gbM", "gbM73", "gbM733", "gbM7330"],
        ["gbM", "gbM73", "gbM734", "gbM7340"],
        ["gbM", "gbM73", "gbM735", "gbM7350"],
        ["gbM", "gbM74", "gbM741", "gbM7410"],
        ["gbM", "gbM74", "gbM742", "gbM7420"],
        ["gbM", "gbM74", "gbM743", "gbM7431"],
        ["gbM", "gbM74", "gbM743", "gbM7432"],
        ["gbM", "gbM74", "gbM743", "gbM7439"],
        ["gbM", "gbM74", "gbM744", "gbM7441"],
        ["gbM", "gbM74", "gbM744", "gbM7449"],
        ["gbM", "gbM74", "gbM745", "gbM7451"],
        ["gbM", "gbM74", "gbM745", "gbM7452"],
        ["gbM", "gbM74", "gbM745", "gbM7453"],
        ["gbM", "gbM74", "gbM745", "gbM7454"],
        ["gbM", "gbM74", "gbM745", "gbM7455"],
        ["gbM", "gbM74", "gbM745", "gbM7459"],
        ["gbM", "gbM74", "gbM746", "gbM7461"],
        ["gbM", "gbM74", "gbM746", "gbM7462"],
        ["gbM", "gbM74", "gbM746", "gbM7463"],
        ["gbM", "gbM74", "gbM747", "gbM7471"],
        ["gbM", "gbM74", "gbM747", "gbM7472"],
        ["gbM", "gbM74", "gbM747", "gbM7473"],
        ["gbM", "gbM74", "gbM747", "gbM7474"],
        ["gbM", "gbM74", "gbM747", "gbM7475"],
        ["gbM", "gbM74", "gbM748", "gbM7481"],
        ["gbM", "gbM74", "gbM748", "gbM7482"],
        ["gbM", "gbM74", "gbM748", "gbM7483"],
        ["gbM", "gbM74", "gbM748", "gbM7484"],
        ["gbM", "gbM74", "gbM748", "gbM7485"],
        ["gbM", "gbM74", "gbM748", "gbM7486"],
        ["gbM", "gbM74", "gbM749", "gbM7491"],
        ["gbM", "gbM74", "gbM749", "gbM7492"],
        ["gbM", "gbM74", "gbM749", "gbM7493"],
        ["gbM", "gbM74", "gbM749", "gbM7499"],
        ["gbM", "gbM75", "gbM751", "gbM7511"],
        ["gbM", "gbM75", "gbM751", "gbM7512"],
        ["gbM", "gbM75", "gbM751", "gbM7513"],
        ["gbM", "gbM75", "gbM751", "gbM7514"],
        ["gbM", "gbM75", "gbM751", "gbM7515"],
        ["gbM", "gbM75", "gbM751", "gbM7516"],
        ["gbM", "gbM75", "gbM751", "gbM7517"],
        ["gbM", "gbM75", "gbM751", "gbM7519"],
        ["gbM", "gbM75", "gbM752", "gbM7520"],
        ["gbM", "gbM75", "gbM753", "gbM7530"],
        ["gbM", "gbM75", "gbM754", "gbM7540"],
        ["gbM", "gbM75", "gbM759", "gbM7590"],
        ["gbN", "gbN76", "gbN761", "gbN7610"],
        ["gbN", "gbN76", "gbN762", "gbN7620"],
        ["gbN", "gbN76", "gbN763", "gbN7630"],
        ["gbN", "gbN76", "gbN764", "gbN7640"],
        ["gbN", "gbN76", "gbN769", "gbN7690"],
        ["gbN", "gbN77", "gbN771", "gbN7711"],
        ["gbN", "gbN77", "gbN771", "gbN7712"],
        ["gbN", "gbN77", "gbN771", "gbN7713"],
        ["gbN", "gbN77", "gbN771", "gbN7714"],
        ["gbN", "gbN77", "gbN771", "gbN7715"],
        ["gbN", "gbN77", "gbN771", "gbN7716"],
        ["gbN", "gbN77", "gbN771", "gbN7719"],
        ["gbN", "gbN77", "gbN772", "gbN7721"],
        ["gbN", "gbN77", "gbN772", "gbN7722"],
        ["gbN", "gbN77", "gbN772", "gbN7723"],
        ["gbN", "gbN77", "gbN772", "gbN7724"],
        ["gbN", "gbN77", "gbN772", "gbN7725"],
        ["gbN", "gbN77", "gbN772", "gbN7726"],
        ["gbN", "gbN77", "gbN772", "gbN7727"],
        ["gbN", "gbN77", "gbN772", "gbN7729"],
        ["gbN", "gbN78", "gbN781", "gbN7810"],
        ["gbN", "gbN78", "gbN782", "gbN7820"],
        ["gbN", "gbN78", "gbN783", "gbN7830"],
        ["gbN", "gbN78", "gbN784", "gbN7840"],
        ["gbN", "gbN78", "gbN785", "gbN7850"],
        ["gbN", "gbN78", "gbN786", "gbN7861"],
        ["gbN", "gbN78", "gbN786", "gbN7862"],
        ["gbN", "gbN78", "gbN786", "gbN7869"],
        ["gbN", "gbN79", "gbN791", "gbN7910"],
        ["gbN", "gbN79", "gbN792", "gbN7920"],
        ["gbN", "gbN79", "gbN793", "gbN7930"],
        ["gbN", "gbN79", "gbN794", "gbN7940"],
        ["gbN", "gbN79", "gbN799", "gbN7990"],
        ["gbO", "gbO80", "gbO801", "gbO8010"],
        ["gbO", "gbO80", "gbO802", "gbO8020"],
        ["gbO", "gbO80", "gbO803", "gbO8030"],
        ["gbO", "gbO80", "gbO804", "gbO8040"],
        ["gbO", "gbO80", "gbO805", "gbO8051"],
        ["gbO", "gbO80", "gbO805", "gbO8052"],
        ["gbO", "gbO80", "gbO805", "gbO8053"],
        ["gbO", "gbO80", "gbO806", "gbO8060"],
        ["gbO", "gbO80", "gbO807", "gbO8070"],
        ["gbO", "gbO80", "gbO808", "gbO8080"],
        ["gbO", "gbO80", "gbO809", "gbO8090"],
        ["gbO", "gbO81", "gbO811", "gbO8111"],
        ["gbO", "gbO81", "gbO811", "gbO8112"],
        ["gbO", "gbO81", "gbO811", "gbO8113"],
        ["gbO", "gbO81", "gbO811", "gbO8114"],
        ["gbO", "gbO81", "gbO812", "gbO8121"],
        ["gbO", "gbO81", "gbO812", "gbO8122"],
        ["gbO", "gbO81", "gbO812", "gbO8129"],
        ["gbO", "gbO81", "gbO813", "gbO8131"],
        ["gbO", "gbO81", "gbO813", "gbO8132"],
        ["gbO", "gbO81", "gbO819", "gbO8191"],
        ["gbO", "gbO81", "gbO819", "gbO8192"],
        ["gbO", "gbO81", "gbO819", "gbO8193"],
        ["gbO", "gbO81", "gbO819", "gbO8199"],
        ["gbO", "gbO82", "gbO821", "gbO8211"],
        ["gbO", "gbO82", "gbO821", "gbO8219"],
        ["gbO", "gbO82", "gbO822", "gbO8221"],
        ["gbO", "gbO82", "gbO822", "gbO8222"],
        ["gbO", "gbO82", "gbO822", "gbO8223"],
        ["gbO", "gbO82", "gbO822", "gbO8224"],
        ["gbO", "gbO82", "gbO822", "gbO8229"],
        ["gbO", "gbO82", "gbO829", "gbO8290"],
        ["gbP", "gbP83", "gbP831", "gbP8310"],
        ["gbP", "gbP83", "gbP832", "gbP8321"],
        ["gbP", "gbP83", "gbP832", "gbP8322"],
        ["gbP", "gbP83", "gbP833", "gbP8331"],
        ["gbP", "gbP83", "gbP833", "gbP8332"],
        ["gbP", "gbP83", "gbP833", "gbP8333"],
        ["gbP", "gbP83", "gbP833", "gbP8334"],
        ["gbP", "gbP83", "gbP833", "gbP8335"],
        ["gbP", "gbP83", "gbP833", "gbP8336"],
        ["gbP", "gbP83", "gbP834", "gbP8341"],
        ["gbP", "gbP83", "gbP834", "gbP8342"],
        ["gbP", "gbP83", "gbP835", "gbP8350"],
        ["gbP", "gbP83", "gbP839", "gbP8391"],
        ["gbP", "gbP83", "gbP839", "gbP8392"],
        ["gbP", "gbP83", "gbP839", "gbP8393"],
        ["gbP", "gbP83", "gbP839", "gbP8394"],
        ["gbP", "gbP83", "gbP839", "gbP8399"],
        ["gbQ", "gbQ84", "gbQ841", "gbQ8411"],
        ["gbQ", "gbQ84", "gbQ841", "gbQ8412"],
        ["gbQ", "gbQ84", "gbQ841", "gbQ8413"],
        ["gbQ", "gbQ84", "gbQ841", "gbQ8414"],
        ["gbQ", "gbQ84", "gbQ841", "gbQ8415"],
        ["gbQ", "gbQ84", "gbQ841", "gbQ8416"],
        ["gbQ", "gbQ84", "gbQ842", "gbQ8421"],
        ["gbQ", "gbQ84", "gbQ842", "gbQ8422"],
        ["gbQ", "gbQ84", "gbQ842", "gbQ8423"],
        ["gbQ", "gbQ84", "gbQ842", "gbQ8424"],
        ["gbQ", "gbQ84", "gbQ842", "gbQ8425"],
        ["gbQ", "gbQ84", "gbQ843", "gbQ8431"],
        ["gbQ", "gbQ84", "gbQ843", "gbQ8432"],
        ["gbQ", "gbQ84", "gbQ843", "gbQ8433"],
        ["gbQ", "gbQ84", "gbQ843", "gbQ8434"],
        ["gbQ", "gbQ84", "gbQ843", "gbQ8435"],
        ["gbQ", "gbQ84", "gbQ843", "gbQ8436"],
        ["gbQ", "gbQ84", "gbQ849", "gbQ8491"],
        ["gbQ", "gbQ84", "gbQ849", "gbQ8492"],
        ["gbQ", "gbQ84", "gbQ849", "gbQ8499"],
        ["gbQ", "gbQ85", "gbQ851", "gbQ8511"],
        ["gbQ", "gbQ85", "gbQ851", "gbQ8512"],
        ["gbQ", "gbQ85", "gbQ851", "gbQ8513"],
        ["gbQ", "gbQ85", "gbQ851", "gbQ8514"],
        ["gbQ", "gbQ85", "gbQ851", "gbQ8515"],
        ["gbQ", "gbQ85", "gbQ851", "gbQ8516"],
        ["gbQ", "gbQ85", "gbQ851", "gbQ8519"],
        ["gbQ", "gbQ85", "gbQ852", "gbQ8521"],
        ["gbQ", "gbQ85", "gbQ852", "gbQ8522"],
        ["gbQ", "gbQ85", "gbQ852", "gbQ8529"],
        ["gbR", "gbR86", "gbR861", "gbR8610"],
        ["gbR", "gbR86", "gbR862", "gbR8621"],
        ["gbR", "gbR86", "gbR862", "gbR8622"],
        ["gbR", "gbR86", "gbR862", "gbR8623"],
        ["gbR", "gbR86", "gbR862", "gbR8624"],
        ["gbR", "gbR86", "gbR862", "gbR8625"],
        ["gbR", "gbR86", "gbR862", "gbR8626"],
        ["gbR", "gbR86", "gbR862", "gbR8629"],
        ["gbR", "gbR87", "gbR871", "gbR8710"],
        ["gbR", "gbR87", "gbR872", "gbR8720"],
        ["gbR", "gbR87", "gbR873", "gbR8730"],
        ["gbR", "gbR87", "gbR874", "gbR8740"],
        ["gbR", "gbR87", "gbR875", "gbR8750"],
        ["gbR", "gbR87", "gbR876", "gbR8760"],
        ["gbR", "gbR87", "gbR877", "gbR8770"],
        ["gbR", "gbR88", "gbR881", "gbR8810"],
        ["gbR", "gbR88", "gbR882", "gbR8820"],
        ["gbR", "gbR88", "gbR883", "gbR8831"],
        ["gbR", "gbR88", "gbR883", "gbR8832"],
        ["gbR", "gbR88", "gbR884", "gbR8840"],
        ["gbR", "gbR88", "gbR885", "gbR8850"],
        ["gbR", "gbR88", "gbR886", "gbR8860"],
        ["gbR", "gbR88", "gbR887", "gbR8870"],
        ["gbR", "gbR88", "gbR889", "gbR8890"],
        ["gbR", "gbR89", "gbR891", "gbR8911"],
        ["gbR", "gbR89", "gbR891", "gbR8912"],
        ["gbR", "gbR89", "gbR891", "gbR8919"],
        ["gbR", "gbR89", "gbR892", "gbR8921"],
        ["gbR", "gbR89", "gbR892", "gbR8929"],
        ["gbR", "gbR89", "gbR893", "gbR8930"],
        ["gbR", "gbR89", "gbR899", "gbR8991"],
        ["gbR", "gbR89", "gbR899", "gbR8992"],
        ["gbR", "gbR89", "gbR899", "gbR8999"],
        ["gbR", "gbR90", "gbR901", "gbR9011"],
        ["gbR", "gbR90", "gbR901", "gbR9012"],
        ["gbR", "gbR90", "gbR901", "gbR9013"],
        ["gbR", "gbR90", "gbR901", "gbR9019"],
        ["gbR", "gbR90", "gbR902", "gbR9020"],
        ["gbR", "gbR90", "gbR903", "gbR9030"],
        ["gbR", "gbR90", "gbR904", "gbR9041"],
        ["gbR", "gbR90", "gbR904", "gbR9042"],
        ["gbR", "gbR90", "gbR904", "gbR9049"],
        ["gbR", "gbR90", "gbR905", "gbR9051"],
        ["gbR", "gbR90", "gbR905", "gbR9052"],
        ["gbR", "gbR90", "gbR905", "gbR9053"],
        ["gbR", "gbR90", "gbR905", "gbR9054"],
        ["gbR", "gbR90", "gbR905", "gbR9059"],
        ["gbR", "gbR90", "gbR909", "gbR9090"],
      ],
      props: { multiple: true, label: "title", value: "industry_code" },
      optionsGb: [],
      GbList: [],

      radio: "1", //范围
      description: "", //描述
      detail: "", //说明
      org_list: ["kemu", "资产负债表"], //类型
      kemu: [],
      zhibiao: [],
      options: [
        {
          value: "kemu",
          label: "财务科目类",
          children: [
            {
              value: "资产负债表",
              label: "资产负债表",
            },
            {
              value: "利润表",
              label: "利润表",
            },
            {
              value: "现金流量表",
              label: "现金流量表",
            },
          ],
        },
        {
          value: "zhibiao",
          label: "财务指标类",
          children: [
            {
              value: "占总收入比指标",
              label: "占总收入比指标",
            },
            {
              value: "占总资产比指标",
              label: "占总资产比指标",
            },
            {
              value: "综合指标",
              label: "综合指标",
            },
          ],
        },
      ],
      radioBtn: "", //按钮选择
      company_time: "本月",
      company_timeList: [
        {
          label: "本月",
          value: "0",
        },
        {
          label: "上月",
          value: "1",
        },
        {
          label: "本年",
          value: "2",
        },
        {
          label: "上年同期",
          value: "3",
        },
      ],
      company_Total: "年初数",
      company_TotalList: [
        {
          label: "年初数",
          value: "0",
        },
        {
          label: "期末数",
          value: "1",
        },
      ],
      show: true,
      arr: [], //展示窗的前提条件
      arrs: [],
      textarea: "", //展示窗
      textareas: "", //参数使用

      numText: "手工输入",
      dialog: false,
      h_List: [
        "资产负债率（%）",
        "速动比率（%）",
        "现金流动负债比率（%）",
        "带息负债比率（%）",
        "存货周转率（次）",
        "流动资产周转率（次）",
        "应收账款周转率（次）",
        "总资产周转率（次）",
        "净资产收益率（%）",
        "总资产报酬率（%）",
        "盈余现金保障倍数",
        "成本费用利润率（%）",
        "资本收益率（%）",
        "销售（营业）利润率（%）",
        "成本费用占主营业务收入比重（%）",
        "总资产增长率（%）",
        "销售（营业）增长率（%）",
        "销售（营）)利润增长率（%）",
        "资本积累率（%）",
        "已获利息倍数",
      ],
      h_value: "",
      num: "",
      title: "",

      // 特殊符号
      btn: [
        " + ",
        " - ",
        " * ",
        " / ",
        " % ",
        " != ",
        " ( ",
        " ) ",
        " = ",
        " < ",
        " > ",
        " <= ",
        " >= ",
        " √ ",
        " ^2 ",
        " ^3 ",
        " and ",
        " or ",
      ],
      AC: true,

      id: "",
      gb: false,
      gbText: "全部行业",
    };
  },
  created() {
    this.getGb();
    if (this.$route.query.type == 1) {
      let data = sesstion.getLocalData("ruleEdit");
      this.name = data.name;
      this.optionsGbList = data.industry_code;
      this.risk = data.level;
      this.radio = data.scope;
      this.description = data.description;
      this.detail = data.detail;
      let textarea = data.formula; //zahnshi展示用
      this.arr.push(this.excludeSpecial(textarea));
      this.textarea = this.excludeSpecial(textarea);
      this.textareas = data.formula;
      this.arrs.push(data.formula);
      this.id = data.id;
    }
  },
  mounted() {
    this.AC = sesstion.getLocalData("AC");
    this.getkm(this.org_list[1]);
  },
  watch: {
    optionsGbList() {
      if (this.optionsGbList.length == 0 || this.optionsGbList.length == 1346) {
        this.gbText = "全部行业";
      } else {
        this.gbText = "部分行业";
      }
    },
  },
  methods: {
    excludeSpecial(str) {
      //   console.log(str);
      if (str != null && str != "") {
        return str.replace(/(RPT.|QT.|STD.)/gi, "");
      }

      return "";
    },
    infoEsc() {
      if (this.AC) {
        this.$router.push({
          name: "RuleSystem",
          query: { type: 0 },
        });
      } else {
        this.$router.push({
          name: "RuleSystem",
          query: { type: 1 },
        });
      }
    },

    getGb() {
      GbIndustryLinkage().then(({ data }) => {
        delete data.msg[data.msg.length - 1];
        let arr = this.GbObj(data.msg);
        this.optionsGb = arr;
      });
    },
    GbObj(data) {
      // 循环每次的对象
      for (let key in data) {
        // delete data[key].children
        data[key].label = data[key].title;
        data[key].value = data[key].industry_code;
        this.GbObj(data[key].children);
      }
      return data;
    },

    clear() {
      this.optionsGbList = [];
    },

    companyChange() {
      let X = this.org_list[0];
      let Y = this.org_list[1];
      if (X == "kemu") {
        if (Y !== "资产负债表") {
          this.show = true;
          this.company_Total = "累计数";
          this.company_TotalList = [
            {
              label: "累计数",
              value: "0",
            },
          ];
        } else {
          this.show = true;
          this.company_Total = "年初数";
          this.company_TotalList = [
            {
              label: "年初数",
              value: "0",
            },
            {
              label: "期末数",
              value: "1",
            },
          ];
        }
        this.getkm(Y);
      } else {
        this.show = false;
        this.company_Total = "";
        this.getquota(Y);
      }
    },

    // 获取三表科目项
    getkm(value) {
      subject(value).then(({ data }) => {
        this.kemu = data;
      });
    },
    // 获取财务指标项
    getquota(value) {
      quota(value).then(({ data }) => {
        data.map((item) => {
          item.row_name = item.name;
        });
        this.zhibiao = data;
      });
    },

    btnClick(value) {
      this.radioBtn = value.row_name;
    },

    // 科目添加
    riskappend() {
      if (this.radioBtn == "") {
        return this.$message.error("请选择科目！");
      } else {
        if (this.show) {
          // 展示需要
          let tr = `${this.company_time}.${this.company_Total}.${this.radioBtn}`;
          this.arr.push(tr);
          // 传参需要
          let ts = `RPT.${this.company_time}.${this.company_Total}.${this.radioBtn}`;
          this.arrs.push(ts);
        } else {
          let tr = `${this.company_time}.${this.radioBtn}`;
          this.arr.push(tr);
          let ts = `QT.${this.company_time}.${this.radioBtn}`;
          this.arrs.push(ts);
        }
        this.textarea = this.arr.join("");
        this.textareas = this.arrs.join("");
      }
    },

    // 手工输入
    numPush() {
      if (this.num != "") {
        this.arr.push(this.num);
        this.arrs.push(this.num);
        this.textarea = this.arr.join("");
        this.textareas = this.arrs.join("");
      } else {
        return this.$message.error("手工输入不可为空！");
      }
    },

    // 行业值
    dialogClick(value) {
      this.title = value;
      this.dialog = true;
    },

    h_btnClick(value) {
      this.h_value = value;
    },
    // 行业值添加
    h_append() {
      if (this.h_value != "") {
        this.arr.push(`${this.company_time}.${this.h_value}.${this.title}`);
        this.textarea = this.arr.join("");
        this.arrs.push(
          `STD.${this.company_time}.${this.h_value}.${this.title}`
        );
        this.textareas = this.arrs.join("");
        this.dialog = false;
      } else {
        return this.$message.error("请选择科目后添加！");
      }
    },

    // 符号
    fuhao(value) {
      this.arr.push(value);
      this.arrs.push(value);
      this.textarea = this.arr.join("");
      this.textareas = this.arrs.join("");
    },
    // 清空
    empty() {
      this.textarea = "";
      this.textareas = "";
      this.arr = [];
      this.arrs = [];
    },
    saveClick() {
      if (this.name != "" && this.description != "") {
        if (this.AC) {
          let params = {
            style: "1",
            name: this.name,
            industry_code: "",
            is_disabled: false, //默认启用
            description: this.description, //描述
            detail: this.detail, //说明
            level: this.risk, //风险
            scope: this.radio,
            formula: this.textareas,
          };
          if (this.gbText == "全部行业") {
            params.industry_code = this.optionsBtn;
          } else {
            params.industry_code = this.optionsGbList;
          }
          if (this.$route.query.type == 0) {
            addRule(params).then((res) => {
              if (res.status == 201) {
                this.$message.success("添加成功！");
                setTimeout(() => {
                  if (this.AC) {
                    this.$router.push({
                      name: "RuleSystem",
                      query: { type: 0 },
                    });
                  } else {
                    this.$router.push({
                      name: "RuleSystem",
                      query: { type: 1 },
                    });
                  }
                }, 1000);
              }
            });
          } else {
            putRule(this.id, params).then((res) => {
              if (res.status == 200) {
                this.$message.success("修改成功！");
                setTimeout(() => {
                  if (this.AC) {
                    this.$router.push({
                      name: "RuleSystem",
                      query: { type: 0 },
                    });
                  } else {
                    this.$router.push({
                      name: "RuleSystem",
                      query: { type: 1 },
                    });
                  }
                }, 1000);
              }
            });
          }
        } else {
          let params = {
            style: "0",
            name: this.name,
            industry_code: "",
            is_disabled: false, //默认启用
            description: this.description, //描述
            // "detail": this.detail,//说明
            level: this.risk, //风险
            scope: this.radio,
            formula: this.textareas,
          };
          if (this.gbText == "全部行业") {
            params.industry_code = this.optionsBtn;
          } else {
            params.industry_code = this.optionsGbList;
          }
          if (this.$route.query.type == 0) {
            fxaddRule(params).then((res) => {
              if (res.status == 201) {
                this.$message.success("添加成功！");
                setTimeout(() => {
                  if (this.AC) {
                    this.$router.push({
                      name: "RuleSystem",
                      query: { type: 0 },
                    });
                  } else {
                    this.$router.push({
                      name: "RuleSystem",
                      query: { type: 1 },
                    });
                  }
                }, 1000);
              }
            });
          } else {
            fxputRule(this.id, params).then((res) => {
              if (res.status == 200) {
                this.$message.success("修改成功！");
                setTimeout(() => {
                  if (this.AC) {
                    this.$router.push({
                      name: "RuleSystem",
                      query: { type: 0 },
                    });
                  } else {
                    this.$router.push({
                      name: "RuleSystem",
                      query: { type: 1 },
                    });
                  }
                }, 1000);
              }
            });
          }
        }
      } else {
        return this.$message.error("请输入名称或描述！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
@media (min-width: 1200px) {
  .container {
    max-width: 1840px;
  }
}

#RuleSystem {
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 4px;
  width: 100%;

  .zdy {
    font-size: 20px;
    font-weight: 550;
    background: #fff;
    color: #333;
  }

  .RuleTop {
    padding: 15px 20px 20px 20px;
    background: #fff;
    // height: 270px;

    .RuleInput {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      width: 100%;

      .RuleInputLeft {
        width: 49%;
        // height: 260px;

        .inpL {
          width: 100%;
          display: flex;
          align-items: center;

          .label {
            width: 100px;
            text-align: right;
            font-size: 16px;
            color: #333;
            margin-right: 10px;
          }

          .inp {
            width: 60%;
          }

          .ca {
            position: relative;
            .cabtn {
              display: block;
              width: 100%;
              height: 34px;
              color: #333;
              border: 1px solid #d6dde4;
              border-radius: 4px;
              line-height: 32px;
              text-align: left !important;
              padding-left: 15px;
            }
            .cabtn:hover {
              border: 1px solid #007bff;
            }
            // .disBox {
            //   margin-top: 10px;
            //   background: #fff;
            //   // width: 520px;
            //   z-index: 999 !important;
            //   position: absolute;
            //   border-radius: 4px;
            //   box-shadow: 0px 0px 5px #d6dde4;
            // }
            // /deep/ .el-cascader-node {
            //   width: 300px !important;
            // }
          }
        }
      }

      .RuleInputRight {
        width: 49%;
        // height: 260px;

        .inpL {
          width: 100%;
          display: flex;

          .label {
            width: 100px;
            text-align: right;
            font-size: 16px;
            color: #333;
            margin-right: 10px;
          }

          .inp {
            width: 60%;
          }
        }
      }
    }
  }

  .RuleCont {
    margin-top: 20px;
    padding: 15px 20px 30px 20px;
    background: #fff;

    .inpL {
      width: 100%;
      display: flex;
      align-items: center;

      .label {
        text-align: right;
        font-size: 16px;
        color: #333;
        margin-right: 10px;
      }
    }

    .RuleContent {
      margin-top: 15px;
      display: flex;
      width: 100%;

      .RuleContentLeft {
        width: 45%;
        padding-left: 10px;

        .RuleContentBox {
          width: 100%;
          height: 365px;
          border: 3px solid #d6dde4;
          border-radius: 5px;
          padding: 10px 0px 5px 20px;
          overflow: auto;

          .a_btn_e {
            display: inline-block;
            width: 23%;
            height: 30px;
            line-height: 30px;
            border: 1px solid #3a85fd;
            border-radius: 4px;
            margin-right: 10px;
            text-align: center;
            margin-top: 5px;
            color: #3a85fd;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            background: #fff;
            padding-left: 5px;
            padding-right: 5px;
          }
        }

        .RuleContentBox::-webkit-scrollbar {
          // background-color: transparent;
          width: 5px;
          height: 5px;
        }

        .RuleContentBox::-webkit-scrollbar-thumb {
          background-color: #3a85fd;
          border-radius: 5px;
        }

        .RuleContentBox::-webkit-scrollbar-track {
          border-radius: 10px;
          background: #e6e9f0;
        }

        .el_in_btn {
          margin-top: 15px;
          display: flex;
          align-items: center;

          .le {
            font-size: 16px;
            color: #333;
            margin-right: 10px;
          }
        }
      }

      .RuleContentRight {
        width: 55%;
        padding: 0px 15px;
        display: flex;
        margin-left: 10px;

        /deep/.el-textarea__inner {
          background-color: #f0f0f0 !important;
        }

        /deep/.el-textarea.is-disabled .el-textarea__inner {
          color: #3a85fd;
        }

        /deep/.el-input--medium .el-input__inner {
          height: 38px !important;
        }

        /deep/.el-input-group__prepend {
          color: #3a85fd;
          width: 125px !important;
        }

        .a_Btn {
          display: inline-block;
          width: 90px;
          height: 32px;
          line-height: 32px;
          background: #3a85fd;
          color: #fff;
          text-align: center;
          margin-bottom: 10px;
          margin-right: 30px;
          border-radius: 4px;
        }
      }
    }
  }

  /deep/.el-dialog__footer {
    text-align: center !important;
  }

  .a_btn_d {
    display: inline-block;
    width: 200px;
    height: 30px;
    line-height: 30px;
    border: 1px solid #3a85fd;
    border-radius: 4px;
    margin-right: 10px;
    margin-left: 15px;
    text-align: center;
    margin-top: 10px;
    color: #3a85fd;
    overflow: hidden;
    text-overflow: ellipsis;
    background: #fff;
  }
}
</style>
